import axios from 'axios'
import {CommonUser, HeaderAxios, ResponseAxios} from "@/shared/api";

export const getAllUsers = () => {
	let responseObj = {} as ResponseAxios
	let headers = {} as HeaderAxios

	const token = localStorage.getItem('current_user')

	if (!token) {
		return (responseObj.error = 'Error')
	}

	headers['Authorization'] = `Token ${token}`

	return axios({
		method: 'GET',
		url: `${process.env.VUE_APP_TASK_RADAR}/users/`,
		headers,
		withCredentials: true
	})
		.then(resp => {
			try {
				if (resp.data) {
					return resp.data as CommonUser[]
				}
			} catch (e) {
				console.error('Пользователи не пришли')
			}
		})
		.catch(err => {
            console.error('Пользователи не пришли ', err)
			return 'Error'
		})
}
