import globalStore from "@/store";
import {STORAGE_KEY} from "@/utils/const";
import {LocalStorageService} from "@/utils/storage";


export async function callNotUpdateNotification(lastSyncName: keyof typeof STORAGE_KEY, id?: string, showNotification = true) {
    const timestamp = LocalStorageService.getTimestamp(lastSyncName, id)

    if (!timestamp) return

    switch (lastSyncName) {
        case "task":
            await globalStore.dispatch('changeLastUpdateTask', timestamp)
            break
        case "taskList":
            await globalStore.dispatch('changeLastUpdateTaskList', timestamp)
            break
        case "comments":
            await globalStore.dispatch('changeLastUpdateComments', timestamp)
            break
    }

    if (showNotification) {
        await globalStore.dispatch('changeTaskNotUpdated', true)
        // setTimeout(() => {
        //     globalStore.dispatch('changeTaskNotUpdated', false)
        // }, 3500)
    }
}


export async function callNotificationClearTimestamp() {
    await globalStore.dispatch('changeLastUpdateTask', null)
    await globalStore.dispatch('changeLastUpdateTaskList', null)
    await globalStore.dispatch('changeLastUpdateComments', null)

    await globalStore.dispatch('changeTaskNotUpdated', false)
}

export async function callNotificationClearTimestampOne(element: 'task' | 'chat') {
    if (element === 'task') {
        await globalStore.dispatch('changeLastUpdateTask', null)
    }

    if (element === 'chat') {
        await globalStore.dispatch('changeLastUpdateComments', null)
    }


    await globalStore.dispatch('changeTaskNotUpdated', false)
}