
import {defineComponent, PropType} from "vue";
import {CreateByHandObject} from "../../../model/interface";
import {haveCommonElements} from '@/components/tracker/model/helpers';
import {ObjectsNameType} from "../../../model/const";

export default defineComponent({
    name: "create-sensor-validate",
    setup() {
        return {
            haveCommonElements,
            ObjectsNameType
        }
    },
    props: {
        createByHandObject: Object as PropType<CreateByHandObject>,
        hash: String
    },
})
