
import TrackerWidget from './TrackerWidget.vue'
import {getRequest, patchRequest, postRequest, putTask} from '@/utils/http-request';
import {defineComponent, PropType} from "vue";
import {getDepartmentById} from "@/store/interaction/userInteraction";
import globalStore from "@/store";
import {TaskStatus} from "@/shared/api";

export type ChangeFieldValueType = {
    field_id: number
    row_id?: number
    value: FieldValue
    old_value?: FieldValue
}

export type FieldValue = number[] | string[] | string | number | boolean | undefined | null

export default defineComponent({
    name: 'WidgetManager',
    emits: ['taskInit', 'changeHeader'],
    components: {TrackerWidget},
    data() {
        return {
            getRequest, postRequest, patchRequest,
            myDepartments: [] as string[],
            loading: true,
            allUsers: globalStore.getters.getAllUsers,
            radarToken: 'Token ' + globalStore.getters.getCurrentUser,
            userId: globalStore.getters.getCurrentUserId,
        }
    },
    props: {
        getTask: {
            type: Function,
            required: true
        },
        activate: {
            type: Function,
            required: true
        },
        active: {
            type: Boolean,
            required: true
        },
        isWidgetButtonActive: {
            type: Boolean,
            required: true
        },
        isTaskClosed: {
            type: Boolean
        },
        taskStatusProps: {
            type: Object as PropType<TaskStatus>
        }
    },
    mounted() {
        const myDepartment = this.$store.getters.getUserData?.department_ids

        const myDepartmentName = [] as string[]

        if (myDepartment && myDepartment.length) {
            for (let i = 0; i < myDepartment.length; i++) {
                const department = getDepartmentById(myDepartment[i])
                if (department && department.name) {
                    myDepartmentName.push(department.name.toLowerCase())
                }
            }
        }

        this.myDepartments = myDepartmentName
        this.loading = false

        this.$emit('changeHeader', "Трекер")
    },
    methods: {
        updateTask(tableChange: boolean) {
            console.log('WidgetManager: update task');
            (this.$refs.fitterWidget as any).updateTaskOnly(tableChange)
        },
        changeTask(fields: ChangeFieldValueType[]) {
            try {
                putTask(this.getTask().id, fields)
            } catch (err) {
                console.log('ERRRR', err)
            }
        },
        async changeTable(fields: ChangeFieldValueType[]) {
            try {
                return await putTask(this.getTask().id, fields)
            } catch (err) {
                console.log('ERRRR', err)
            }
        },
    }
})
