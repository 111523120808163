import {getAllUsers} from '@/axios_requests/get_all_users'
import {Commit} from "vuex";
import {Bot, CommonUser, Department, User} from "@/shared/api";
import {getUsersFromLocalStorage, saveUsersInLocalStorage} from "@/utils/storage/storage-user-method";


type State = {
    data: {
        bot_id: string,
        chat_id: string
    },
    allUsers: User[],
    bots: Bot[],
    departments: Department[],
    isLogin: boolean
}

export default {
    state: {
        data: {},
        allUsers: [],
        bots: [],
        departments: [],
        isLogin: false,
    },
    mutations: {
        addUsers(state: State, payload: User[] | Bot[] | Department[] | null) {
            const users = (payload as User[]).filter(item => item.type === 'user')
            const bots = (payload as Bot[]).filter(item => item.type === 'bot')
            const departments = (payload as Department[]).filter(item => {
                if ('name' in item) {
                    return item
                }
            })

            state.allUsers = users;
            state.departments = departments;
            state.bots = bots
        },
        changeIsLogin(state: State, payload: boolean) {
            state.isLogin = payload
        },
    },
    actions: {
        async addUsers({commit}: { commit: Commit }) {
            // Получаю пользователей с бэка и обновляю их
            const users = await getAllUsers()

            if (users && Array.isArray(users) && users.length >= 0) {
                saveUsersInLocalStorage(users as CommonUser[])

                commit('addUsers', users)
            }
        },
        async changeIsLogin({commit}: { commit: Commit }, payload: boolean) {
            commit('changeIsLogin', payload)
        },
        async addUsersFromCache({commit}: { commit: Commit }) {
            // Получаю пользователей из кэша и вывожу их до запроса
            const cacheUsers = getUsersFromLocalStorage()

            if (cacheUsers && Array.isArray(cacheUsers) && cacheUsers.length > 0) {
                commit('addUsers', cacheUsers)
            }
        }
    },
    getters: {
        getAllUsers(state: State) {
            return state.allUsers
        },
        getDepartments(state: State) {
            return state.departments;
        },
        getBots(state: State) {
            return state.bots;
        },
        getIsLogin(state: State) {
            return state.isLogin
        }
    }
}
