
import {Bot, User} from "@/shared/api";
import {TaskComment} from "@/shared/api";
import {getUserById} from "@/utils/filter_messages";
import {defineComponent, PropType} from "vue";
import MessageReply from "@/components/chat/message_type/MessageReply.vue";
import MessageCommon from "@/components/chat/message_type/MessageCommon.vue";
import MessageImage from "@/components/chat/message_type/MessageImage.vue";
import MessageSystem from "@/components/chat/message_system/MessageSystem.vue";
//@ts-ignore
import {SelectItemHoldMixin} from "@/mixin/SelectItemHoldMixin";
import {copyTextToClipboard} from "@/helpers/copyText";
import {canEditMessageTime} from "@/utils/message";
import MessageError from "@/components/chat/message_error/MessageError.vue";
import {deleteUnsentMessageById, getUnsentCommentFromMap} from "@/utils/storage/unsent-comment-method";
import {ChatApi} from "@/shared/api/request/chat-api";
//@ts-ignore

export default defineComponent({
    name: 'Message',
    components: {MessageError, MessageSystem, MessageImage, MessageCommon, MessageReply},
    props: {
        message: {
            type: Object as PropType<TaskComment>,
            required: true
        },
        openImageFullSize: Function,
        allUsers: {
            type: Array as PropType<User[]>,
            required: true
        },
        replyMessageHandler: {
            type: Function,
            required: true
        },
        editMessageHandler: {
            type: Function,
            required: true
        },
        step: {
            type: Array,
            required: true
        },
        bigMessage: Boolean,
        lastMessage: Boolean,
        taskId: {
            type: Number,
            required: true
        },
    },
    mixins: [SelectItemHoldMixin],
    setup() {
        return {
            canEditMessageTime
        }
    },
    data() {
        return {
            userMessage: false,
            messageWithUser: Object as unknown as User,
            name: '',
            surname: '',
            id: this.message && this.message.id ? this.message.id : null,
            selectedIdArr: [],
        }
    },
    async mounted() {
        if (this.message) {
            this.userMessage = Number(this.$store.getters.getCurrentUserId) === Number(this.message.author_id)

            const allUsersAndBots = [...this.$store.getters.getAllUsers, ...this.$store.getters.getBots]

            const res = await getUserById(this.message, allUsersAndBots)

            if (res !== -1) {
                this.messageWithUser = res as unknown as User

                if (this.messageWithUser && this.messageWithUser.info) {

                    if (this.messageWithUser.type === 'bot') {
                        const botInfo = this.messageWithUser as unknown as Bot;

                        if (botInfo.info.bot_name) {
                            this.name = botInfo.info.bot_name
                        }

                    } else {
                        if (this.messageWithUser.info.name) {
                            this.name = this.messageWithUser.info.name
                        }

                        if (this.messageWithUser.info.surname && this.messageWithUser.info.surname !== '-') {
                            this.surname = this.messageWithUser.info.surname
                        }
                    }
                }
            }


            if (!this.message.is_read) {
                let options = {
                    rootMargin: "0px",
                    threshold: 0,
                };

                const callback = async (entries: any, observer: IntersectionObserver) => {
                    if (entries[0].isIntersecting) {
                        if (this.message) {
                            this.message.is_read = true

                            let messageCount = this.$store.getters.getNewMessageCount
                            messageCount -= 1
                            if (messageCount > 0) {
                                await this.$store.dispatch('changeNewMessageCount', messageCount)
                            }

                            observer.disconnect()
                        }
                    }
                }

                const observer = new IntersectionObserver(callback, options);

                observer.observe(this.$refs[`container${this.message.id}`] as HTMLElement)
            }
        }
    },
    computed: {
        selectedMessagePopup(): number | null {
            return this.$store.getters.getSelectedMessage
        },
        isMobileDevice(): boolean {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    },
    watch: {
        selectedMessagePopup() {
            if (this.selectedMessagePopup) {
                this.$emit('blurFilter', 'add')
            }
        }
    },
    methods: {
        selectImage(url: string) {
            this.$emit('openImageFullSize', url)
        },
        pressThresholdReached(id?: number) {
            if (!this.isMobileDevice) {
                return
            }

            this.$store.dispatch('changeSelectedMessage', null)

            if (id) {
                console.log('message selected', id)
                this.$store.dispatch('changeSelectedMessage', id)
            }
        },
        getApprovalChoice(approval_choice: string) {
            let approval_choice_parag;

            const greenColor = this.userMessage ? 'color: #ffffff' : 'color: rgb(70, 165, 70)'

            switch (approval_choice) {
                case 'approved':
                    approval_choice_parag = `<p style="${greenColor}; font-weight: 700;">Утверждено</p>`
                    break
                case 'acknowledged':
                    approval_choice_parag = '<p style="color: rgb(55, 55, 245); font-weight: 700;">Прочитано</p>'
                    break
                case 'rejected':
                    approval_choice_parag = '<p style="color: rgb(250, 48, 48); font-weight: 700;">Против</p>'
                    break
                case 'revoked':
                    approval_choice_parag = '<p style="color: grey; font-weight: 700;">Не участвовать</p>'
                    break
            }
            return approval_choice_parag
        },
        replyMessage(id: number) {
            this.$store.dispatch('changeEditMode', false)
            this.resetSelectedMessage()
            this.replyMessageHandler(id)
        },
        deleteMessage(id: number) {
            this.$store.dispatch('deleteNotSendMessageById', id)

            const message = getUnsentCommentFromMap(this.taskId, id);

            navigator.serviceWorker.controller?.postMessage({
                type: 'DELETE_MESSAGE',
                data: message
            });

            deleteUnsentMessageById(this.taskId, id);
        },
        async repeatSendMessage(id: number) {
            const messageNotSend = getUnsentCommentFromMap(this.taskId, id);
            await this.$store.dispatch('changeUnsentMessageStatusToSend', id)

            if (!messageNotSend) return

            messageNotSend.isSending = true
            messageNotSend.type = 'comment'

            // Удаляю сообщение из неотправленных
            await this.$store.dispatch('deleteNotSendMessageById', id)
            deleteUnsentMessageById(this.taskId, id);

            // Отправка сообщения без сокетов
            await this.$store.dispatch('addNewMessagesToStart', messageNotSend)

            const statusMessage = this.$store.getters.getIsMessageSend
            await this.$store.dispatch('changeMessageSendStatus', !statusMessage)

            const notificationError = {
                message: 'Ошибка при отправке',
                status: true
            }

            if ('serviceWorker' in navigator) {
                const messageStore = [{
                    data: messageNotSend,
                    id: this.taskId.toString()
                }]

                navigator.serviceWorker.controller?.postMessage({
                    type: 'SEND_MESSAGE_AGAIN',
                    data: messageStore
                });
            } else {
                // Отправка сообщения
                const messageData = {
                    text: messageNotSend.text,
                    attachment_ids: messageNotSend.attachments
                }

                const messageSend = await ChatApi.sendMessage(messageData, this.taskId.toString())

                if (!messageSend) {
                    await this.$store.dispatch('setErrorNotification', notificationError)

                    messageNotSend.isSending = false
                    messageNotSend.type = 'not_send'
                }
            }

        },
        editMessage(id: number) {
            this.resetSelectedMessage()
            this.$store.dispatch('changeEditMode', true)
            this.$store.dispatch('changeEditCommentId', id)
            this.editMessageHandler(id)
        },
        copyMessage(message: string) {
            this.$store.dispatch('changeEditMode', false)
            this.resetSelectedMessage()
            copyTextToClipboard(message)
        },
        resetSelectedMessage() {
            if (!this.isMobileDevice) {
                return
            }

            this.$emit('blurFilter', 'remove')
            this.$store.dispatch('changeSelectedMessage', null)
        },
        handleRightClick(event: any, id: number) {
            if (!this.isMobileDevice) {
                event.preventDefault(); // Предотвращаем стандартное контекстное меню

                const customEvent = new MouseEvent('customRightClick', {
                    bubbles: true,
                    cancelable: true,
                    button: 2, // Правая кнопка мыши (ПКМ)
                });

                event.target.dispatchEvent(customEvent);

                this.$store.dispatch('changeSelectedMessage', id)
            }
        }
    }
})
