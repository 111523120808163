import {createCabinetSuccess} from "@/components/tracker/model/interface";

export function validateCabinetData(host: string, login: string): string | null {
    if (host === "") {
        return 'Не заполнено поле система';
    }

    if (!login || login.trim() === "") {
        return 'Не заполнено поле логин в системе';
    }

    // Если данные прошли проверку, возвращаем null или пустую строку (выберите подходящее значение)
    return null;
}

export function isCreateCabinetSuccess(res: createCabinetSuccess): string | null {
    if (res && res.body) {
        if (res.body.status === 'success') {
            return res.body?.data?.data?.id as string
        }

        return null
    }

    return null
}