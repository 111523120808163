import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";

export function saveOpenedTasksList(name: string, key: keyof typeof STORAGE_KEY) {
    const openedTasks = LocalStorageService.getInstance().getArray(key)
    const openedTasksMap = LocalStorageService.localStorageToMap(openedTasks)

    if (!openedTasksMap.has(name)) {
        openedTasksMap.set(name, true)

        const mapToObject = LocalStorageService.mapToLocalStorage(openedTasksMap)

        LocalStorageService.getInstance().rewriteData(key, mapToObject)

        return
    }

    deleteFromTaskList(name, key)

}

export function deleteFromTaskList(name: string, key: keyof typeof STORAGE_KEY) {
    const openedTasks = LocalStorageService.getInstance().getArray(key)
    const openedTasksMap = LocalStorageService.localStorageToMap(openedTasks)

    openedTasksMap.delete(name)

    const mapToObject = LocalStorageService.mapToLocalStorage(openedTasksMap)

    LocalStorageService.getInstance().rewriteData(key, mapToObject)
}

export function getOpenedTaskList(key: keyof typeof STORAGE_KEY) {
    const openedTasks = LocalStorageService.getInstance().getArray(key)
    const openedTasksMap = LocalStorageService.localStorageToMap(openedTasks)

    return [...openedTasksMap.keys()]
}