import { Workbox } from "workbox-window";

let wb;


if ('serviceWorker' in navigator) {
	const serviceWorkerUrl = `${process.env.BASE_URL}custom-service-worker.js`;

	wb = new Workbox(serviceWorkerUrl);

	wb.addEventListener("controlling", () => {
		window.location.reload();
	});

	wb.register()
		.then(registration => {
			console.log('Service Worker registered: ', registration);

			let reloadPage = false;

			navigator.serviceWorker.addEventListener('controllerchange', () => {
				// Если есть новый контроллер, значит у нас есть новая версия Service Worker
				if (!reloadPage && registration.active) {
					reloadPage = true;
					// Перезагружаем страницу
					window.location.reload();
				}
			});

			// Регистрация фоновой синхронизации, если поддерживается
			if ('SyncManager' in window && registration.sync) {
				registration.sync.register('send-chats')
					.then(() => {
						console.log('Background sync task registered successfully.');
					})
					.catch(error => {
						console.error('Background sync registration failed: ', error);
					});
			}
		})
		.catch(error => {
			console.error('Service Worker registration failed: ', error);
		});
} else {
	wb = null;
}

export default wb;