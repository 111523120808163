import {User} from "@/shared/api";
import {CatalogItemType} from "@/components/tracker/model/interface";

/**
 * Бинарный поиск для пользователей
 *
 * @param {array} arr массив отсортированных пользователей
 * @param {number} val id по которому ищем пользователя
 */

export function binarySearch(arr: User[] | CatalogItemType[], val: number | string) {
	let start = 0
	let end = arr.length - 1

	while (start <= end) {
		let mid = Math.floor((start + end) / 2)

		if (arr[mid].id === Number(val)) {
			return arr[mid]
		}

		if (Number(val) < arr[mid].id) {
			end = mid - 1
		} else {
			start = mid + 1
		}
	}
	return -1
}
