
import {PropType} from "vue";
import {CreateByHandObject} from "@/components/tracker/model/interface";

export default {
    name: "create-sensor",
    props: {
        createByHandObject: Object as PropType<CreateByHandObject>
    }
}
