import {unit} from '@/components/tracker/model/interface'

export class qrBarManager {
    static handleQr(api: any) {
        qrBarManager._trackData()

        api.isQrcode = !api.isQrcode;
        api.isBarcode = false;
    }

    static handleQrChange(api: any, unit: unit) {
        qrBarManager._trackData()

        if (unit.state && unit.state.changeObject) {
            unit.state.changeObject.isQrCode = true
            unit.state.changeObject.isBarCode = false;
        }
    }

    static handleBar(api: any) {
        qrBarManager._trackData()

        api.isBarcode = !api.isBarcode;
        api.isQrcode = false;
    }

    static handleBarChange(api: any, unit: unit) {
        qrBarManager._trackData()

        if (unit.state && unit.state.changeObject) {
            unit.state.changeObject.isBarCode = true
            unit.state.changeObject.isQrCode = false;
        }
    }

    static decodeQr(api: any, data: string) {
        this._decodeMethod(api, data, api.handleQr.bind(api));
    }

    static decodeBar(api: any, data: string) {
        this._decodeMethod(api, data, api.handleBar.bind(api));
    }

    static decodeQrChange(api: any, data: string) {
        this._decodeMethod(api, data, api.handleQrChange.bind(api));
    }

    static decodeBarChange(api: any, data: string) {
        this._decodeMethod(api, data, api.handleBarChange.bind(api));
    }


    static closeQrConnection(unit: unit) {
        if (unit.state && unit.state.changeObject)
            unit.state.changeObject.isQrCode = false
    }

   static closeBatConnection(unit: unit) {
        if (unit.state && unit.state.changeObject)
            unit.state.changeObject.isBarCode = false
    }

    private static _decodeMethod(api: any, data: string, decodeCallback: () => void) {
        qrBarManager._decodeMethodHandler(api, data);
        decodeCallback();
    }


    private static _decodeMethodHandler(api: any, data: string) {
        if (Number.isInteger(+data)) {
            api.createByHandObject.userImeiOrSerial = data
        } else {
            if (data) {
                const arr = data.split("sn").pop() as string
                if (arr) {
                    api.createByHandObject.userImeiOrSerial = arr.substring(1).split('&')[0]
                }
            }
        }
    }


    private static _trackData() {
        let camera = document.getElementsByTagName('video')

        if (camera.length !== 0) {
            const stream = camera[0].srcObject;
            if (stream) {
                const tracks = (stream as any).getTracks();
                tracks.forEach(function (track: any) {
                    track.stop();
                })
            }
        }
    }
}