import {COMMENT_TYPE, CONSTANT_TASK_OBJECT} from '@/constants/constants'
import {findByCatalogIdElement} from '@/utils/catalogs'
import {ConditionType, TASK} from '@/utils/const'
import {TaskCustom, TaskCustomPromise} from "@/shared/api";
import {FieldValue} from "@/shared/api"
import {getDepartmentById, getUserById} from "@/store/interaction/userInteraction";
import {Department, User} from "@/shared/api";

// Получает родительские элементы и рекурсивно проходится по детям.
// Удаляет поле которое уже не будет использоваться
export const getAllTaskFieldsRecursion = async (task: TaskCustom[]) => {

    for (const item of task) {
        await findFieldsRecursion(item, task)
    }
}

// Рекурсивно проходится по детям и сортирует их по order
const findFieldsRecursion = async (item: any, task: TaskCustom[]) => {
    if ('nested' in item && item.nested !== null) {
        item.nested?.map(async (el: any) => {
            if (CONSTANT_TASK_OBJECT.includes(el.name)) {

                await setItemInParentById(el.parent_id, el, item, task)

                if (item.length > 0) {
                    item.sort((a: TaskCustom, b: TaskCustom) => a.order - b.order)
                }

                if (item.fields.length > 0) {
                    item.fields.sort((a: TaskCustom, b: TaskCustom) => a.order - b.order)
                }

            } else {
                await findFieldsRecursion(el, task)
            }
        })
    }
}

function getKeyByValue(object: { [key: string]: string }, value: string) {
    let isEditable = false
    const constName = Object.keys(object).find(key => object[key] === value)

    if (constName) {
        const isFind = constName.split('___')
        isEditable = isFind.length > 1
    }

    return {
        isEditable,
        catalogValue: constName
    }
}

// Берет каждый элемент и проверяет его по типу, если это catalog то записывает в value данные из него или обычное value
const setItemInParentById = async (parent_id: number, element: TaskCustom, parent: TaskCustom, task: TaskCustom[]) => {
    let type = COMMENT_TYPE.common

    if (element.name === TASK.place__address || element.name === TASK.place__address_old) {
        type = COMMENT_TYPE.address
    } else if (element.name === TASK.client__phone || element.name === TASK.client__additional_phone) {
        type = COMMENT_TYPE.phone
    } else if (element.name === TASK.pay__payment_fact) {
        type = COMMENT_TYPE.money
    } else if (element.name === TASK.pay__amount || element.name === TASK.pay__amount_old) {
        type = COMMENT_TYPE.money
    } else if (element.name === TASK.user || element.name === TASK.fitter || element.name === TASK.fitters) {
        type = COMMENT_TYPE.router
        element.type = COMMENT_TYPE.router
    } else if (element.name === TASK.fitter_note) {
        element.type = COMMENT_TYPE.note
        type = COMMENT_TYPE.note
    } else if (element.name === TASK.note) {
        type = COMMENT_TYPE.textfield
    }

    if (element.inputType === COMMENT_TYPE.checkmark || element.type === COMMENT_TYPE.checkmark) {
        element.type = COMMENT_TYPE.checkmark
        element.value = Boolean(element.value)
        type = COMMENT_TYPE.checkmark
    }

    if (element.type === COMMENT_TYPE.file || element.inputType === COMMENT_TYPE.file) {
        type = COMMENT_TYPE.file
        element.type = COMMENT_TYPE.file
    }


    return new Promise(resolve => {
        if ('info' in element) {

            if (!element.info) return

            let condition = null as unknown as TaskCustom
            let isVisible = true

            // Если у поля есть condition type, то смотрит нужно ли показывать это поле
            if (element.info.visibility_condition && element.info.visibility_condition.length > 0) {
                const field_id = element.info?.visibility_condition[0].field_id

                let isFind = false


                task.forEach(item => {
                    if (field_id && !isFind) {
                        if (item.id === field_id) {
                            condition = item
                            condition.type = item.inputType
                            isFind = true
                        }
                    }
                })

                if (!isFind) {
                    task.forEach(item => {
                        if (field_id) {
                            if ('nested' in item) {
                                item.nested?.map((el: TaskCustom) => {
                                    if (el.id === field_id) {
                                        condition = el
                                    } else {
                                        findFieldById(el, field_id)
                                    }
                                })
                            } else {
                                return
                            }
                        }
                    })
                }


                // Если true то добавлять поля в задачу
                if (condition && condition.type) {
                    const elementConditionType = element.info.visibility_condition[0].condition_type
                    const elementValue = element.info.visibility_condition[0].value
                    const conditionValue = condition.value

                    const resIsVisible = validateFieldVisibility(condition, elementConditionType, conditionValue, elementValue)
                    isVisible = Boolean(resIsVisible)
                }
            }


            // if (!isVisible) return

            const {catalogValue, isEditable} = getKeyByValue(TASK, element.name)

            let catalogs = null as unknown as number
            if (catalogValue && catalogValue.split('___').includes('edit')) {
                if (element.info.catalog_id) {
                    catalogs = element.info.catalog_id
                }
            }

            if (element.value !== null) {
                // Для двойных вложений
                if (element.type === COMMENT_TYPE.title && element.name === TASK.pay) {

                    const elementPayPayment = element.fields.find(item => item.name === TASK.pay__payment)
                    if (!elementPayPayment) return

                    const {catalogValue, isEditable} = getKeyByValue(TASK, elementPayPayment.name);

                    (parent.fields as unknown as TaskCustomPromise[]).push({
                        id: elementPayPayment.id,
                        name: elementPayPayment.name,
                        order: elementPayPayment.order,
                        value: elementPayPayment.value,
                        type: type,
                        catalogs_id: catalogs,
                        inputType: elementPayPayment.type,
                        constName: catalogValue || '',
                        isEditable: isEditable,
                        isVisible: isVisible,
                        info: elementPayPayment.info
                    } as unknown as TaskCustomPromise)
                }

                if (element.value === undefined) return

                findByCatalogIdElement(element.value).then(res => {
                    const value = res

                    if (value !== null || true || type === COMMENT_TYPE.checkmark) {

                        return resolve(
                            (parent.fields as unknown as TaskCustomPromise[]).push({
                                id: element.id,
                                name: element.name,
                                order: element.order,
                                value: value,
                                type: type,
                                catalogs_id: catalogs,
                                inputType: element.type,
                                constName: catalogValue || '',
                                isEditable: isEditable,
                                isVisible: isVisible,
                                info: element.info
                            } as unknown as TaskCustomPromise)
                        )
                    }
                })
            } else if (element.info.hasOwnProperty('content')) {
                return resolve(
                    (parent.fields as unknown as TaskCustomPromise[]).push({
                        id: element.id,
                        name: element.name,
                        order: element.order,
                        value: element.info.content,
                        type: type,
                        catalogs_id: catalogs,
                        inputType: element.type,
                        constName: catalogValue || '',
                        isEditable: isEditable,
                        isVisible: isVisible,
                        info: element.info
                    } as unknown as TaskCustomPromise)
                )
            } else if (isEditable || type === COMMENT_TYPE.file) {

                return resolve(
                    (parent.fields as unknown as TaskCustomPromise[]).push({
                        id: element.id,
                        name: element.name,
                        order: element.order,
                        value: element.value,
                        type: type,
                        catalogs_id: catalogs,
                        inputType: element.type,
                        constName: catalogValue || '',
                        isEditable: isEditable,
                        isVisible: isVisible,
                        info: element.info
                    } as unknown as TaskCustomPromise)
                )
            }
        } else {
            const {catalogValue, isEditable} = getKeyByValue(TASK, element.name)

            let catalogs = null as unknown as number
            if (catalogValue && catalogValue.split('___').includes('edit')) {
                if (element.info.catalog_id) {
                    catalogs = element.info.catalog_id
                }
            }

            if (element.value !== null) {
                return resolve(
                    (parent.fields as unknown as TaskCustomPromise[]).push({
                        id: element.id,
                        name: element.name,
                        order: element.order,
                        value: element.value ?? '',
                        type: type,
                        catalogs_id: catalogs,
                        inputType: element.type,
                        constName: catalogValue || '',
                        isEditable: isEditable,
                        isVisible: true,
                        info: element.info
                    } as unknown as TaskCustomPromise)
                )
            }
        }

    })
}


export const validateFieldVisibility = (condition: TaskCustom,
                                        elementConditionType: number,
                                        conditionValue: string | number | boolean | string[] | number[] | undefined,
                                        elementValue: FieldValue) => {
    let isVisible = false // Значение, которое возвращает функция
    const conditionTypeText = ['email', 'money', 'number', 'pinned_link', 'text', 'phone']

    if (!condition.type) {
        return
    }
    /**
     * element - Поле, которое показывается или не показывается в зависимости от условия
     * condition - Поле - родитель, в зависимости от elementValue в нем смотрим показывать или не показывать element
     */

    // Проверка чекбоксов
    if (condition.type === 'checkmark') {
        switch (elementConditionType) {
            case ConditionType.Equal:
                isVisible = conditionValue === elementValue
                break
            case ConditionType.NotEqual:
                isVisible = conditionValue !== elementValue;
                break
        }
    }

    // Проверка текстовых полей
    if (conditionTypeText.includes(condition.type)) {

        switch (elementConditionType) {
            case ConditionType.Equal:
                isVisible = elementValue && conditionValue ? elementValue.toString().toLowerCase().trim() === conditionValue.toString().toLowerCase().trim() : false
                break

            case ConditionType.NotEqual:
                isVisible = elementValue && conditionValue ? elementValue.toString().toLowerCase().trim() !== conditionValue.toString().toLowerCase().trim() : false
                break

            case ConditionType.StartWithValue:
                if (elementValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).startsWith((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break

            case ConditionType.EndWithValue:
                if (elementValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).endsWith((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break

            case ConditionType.IncludesValue:
                if (elementValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).includes((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break
        }
    }

    if (condition.type === 'catalog') {

        switch (elementConditionType) {
            case ConditionType.Equal:
                isVisible = elementValue && conditionValue ? elementValue === conditionValue : false
                break

            case ConditionType.NotEqual:
                isVisible = elementValue && conditionValue ? elementValue !== conditionValue : false
                break

            case ConditionType.StartWithValue:
                if (elementValue && conditionValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).startsWith((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break

            case ConditionType.EndWithValue:
                if (elementValue && conditionValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).endsWith((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break

            case ConditionType.IncludesValue:
                if (elementValue && conditionValue) {
                    isVisible = ((conditionValue as string).toString().toLowerCase().trim()).includes((elementValue as string).toString().toLowerCase().trim());
                    break
                }

                isVisible = false
                break
        }

    }

    if (condition.type === 'multi_catalog') {

        if (conditionValue && (conditionValue as string[]).length === 0) {
            isVisible = false
            return
        }

        switch (elementConditionType) {

            case ConditionType.Equal:
                if (conditionValue && (conditionValue as string[]).length && elementValue) {
                    let isFind = false;

                    if ((conditionValue as string[]).length === 1 && (elementValue as string[]).length === 1) {
                        if ((conditionValue as string[])[0] === (elementValue as string[])[0]) {
                            isFind = true
                        }
                    }


                    isVisible = isFind
                }

                break

            case ConditionType.NotEqual:
                if (((conditionValue as string[])).length && elementValue) {
                    let isFind = true;

                    if ((conditionValue as string[]).length === 1 && (elementValue as string[]).length === 1) {
                        if ((conditionValue as string[])[0] === (elementValue as string[])[0]) {
                            isFind = false
                        }
                    }

                    isVisible = isFind

                }

                break

            case ConditionType.StartWithValue:
                if ((conditionValue as string[]).length && elementValue) {
                    let isFind = false;

                    (conditionValue as string[]).forEach(item => {
                        const conditionValueSplit = item.split(' ')

                        if (conditionValueSplit[0]) {
                            if (conditionValueSplit[0].toLowerCase() === elementValue.toString().toLowerCase()) {
                                isFind = true
                                return
                            }
                        }

                    })

                    isVisible = isFind
                }

                break

            case ConditionType.EndWithValue:
                if ((conditionValue as string[]).length && elementValue) {
                    let isFind = false;

                    (conditionValue as string[]).forEach(item => {
                        const conditionValueSplit = item.split(' ')

                        if (conditionValueSplit[conditionValueSplit.length - 1]) {
                            if (conditionValueSplit[conditionValueSplit.length - 1].toLowerCase() === elementValue.toString().toLowerCase()) {
                                isFind = true
                                return
                            }
                        }

                    })

                    isVisible = isFind
                }

                break

            case ConditionType.IncludesValue:
                if ((conditionValue as string[]).length && elementValue) {
                    let isFind = false;

                    (conditionValue as string[]).forEach(item => {
                        const conditionValueSplit = item.split(' ')

                        if (conditionValueSplit.length) {
                            conditionValueSplit.forEach(el => {
                                if (el.toLowerCase() === elementValue.toString().toLowerCase()) {
                                    isFind = true
                                    return
                                }
                            })
                        }

                    })

                    isVisible = isFind
                }

                break
        }


    }

    if (condition.type === 'routing') {

        type elementUser = {
            users: number[],
            departments: number[]
        }

        const allUsers = conditionValue as unknown as elementUser

        const usersAndDepartments = [] as string[]

        if (allUsers) {
            if (allUsers.users.length > 0) {
                allUsers.users.forEach(el => {
                    const res = getUserById(el) as User
                    if (res) {
                        const fullName = `${res.info.name} ${res.info.surname}`
                        usersAndDepartments.push(fullName)
                    }
                })
            }

            if (allUsers.departments.length > 0) {
                allUsers.departments.forEach(el => {
                    const res = getDepartmentById(el) as Department
                    if (res) {
                        const fullName = `${res.name}`
                        usersAndDepartments.push(fullName)
                    }
                })
            }

        }

        switch (elementConditionType) {

            case ConditionType.Equal:
                if (elementValue) {
                    let isFind = false

                    const allUsers = elementValue as unknown as elementUser

                    const usersInElementValue = [] as string[]

                    if (allUsers.users.length > 0) {
                        allUsers.users.forEach(el => {
                            const res = getUserById(el) as User
                            if (res) {
                                const fullName = `${res.info.name} ${res.info.surname}`
                                usersInElementValue.push(fullName)
                            }
                        })
                    }

                    if (allUsers.departments.length > 0) {
                        allUsers.departments.forEach(el => {
                            const res = getDepartmentById(el) as Department
                            if (res) {
                                const fullName = `${res.name}`
                                usersInElementValue.push(fullName)
                            }
                        })
                    }

                    // Массив имен
                    for (let i = 0; i < usersAndDepartments.length; i++) {
                        for (let j = 0; j < usersInElementValue.length; j++) {
                            if (usersAndDepartments[i].toLowerCase() === usersInElementValue[j].toString().toLowerCase()) {
                                isFind = true
                            }
                        }
                    }

                    isVisible = isFind
                    break
                }

                break


            case ConditionType.NotEqual:
                if (elementValue) {
                    let isFind = true

                    const allUsers = elementValue as unknown as elementUser

                    const usersInElementValue = [] as string[]

                    if (allUsers.users.length > 0) {
                        allUsers.users.forEach(el => {
                            const res = getUserById(el) as User
                            if (res) {
                                const fullName = `${res.info.name} ${res.info.surname}`
                                usersInElementValue.push(fullName)
                            }
                        })
                    }

                    if (allUsers.departments.length > 0) {
                        allUsers.departments.forEach(el => {
                            const res = getDepartmentById(el) as Department
                            if (res) {
                                const fullName = `${res.name}`
                                usersInElementValue.push(fullName)
                            }
                        })
                    }


                    // Массив имен
                    for (let i = 0; i < usersAndDepartments.length; i++) {
                        for (let j = 0; j < usersInElementValue.length; j++) {
                            if (usersAndDepartments[i].toLowerCase() === usersInElementValue[j].toString().toLowerCase()) {
                                isFind = false
                            }
                        }
                    }

                    isVisible = isFind
                    break
                }

                break


            case ConditionType.StartWithValue:
                if (elementValue) {
                    let isFind = false
                    const usersAndDepartmentsSplit = [] as string[]

                    usersAndDepartments.forEach(item => {
                        const splitValue = item.split(' ')
                        usersAndDepartmentsSplit.push(...splitValue)
                    })

                    usersAndDepartmentsSplit.forEach(item => {
                        if (item.toString().toLowerCase().startsWith(elementValue.toString().toLowerCase().trim())) {
                            isFind = true
                        }
                    })

                    isVisible = isFind
                    break
                }

                break

            case ConditionType.IncludesValue:
                if (elementValue) {
                    let usersAndDepartmentsSplit = [] as string[]

                    usersAndDepartments.forEach(item => {
                        const splitValue = item.split(' ')
                        usersAndDepartmentsSplit.push(...splitValue)
                    })

                    usersAndDepartmentsSplit = usersAndDepartmentsSplit.map(item => {
                        return item.toLowerCase()
                    })

                    if (usersAndDepartmentsSplit.includes(elementValue.toString().toLowerCase())) {
                        isVisible = true
                        break
                    }


                    isVisible = false
                }

                break

        }
    }

    return isVisible
}


// Рекурсивно пробегает по всем элементам массива и ищет подходящий по id
export const findFormElementById = (parentData: TaskCustom[], id: number, newValue: number) => {
    parentData.forEach(item => {
        return findFormElementByIdRecursion(item, id, newValue, 'fields')
    })

    parentData.forEach(item => {
        return findFormElementByIdRecursion(item, id, newValue, 'nested')
    })
}

const findFormElementByIdRecursion = (item: TaskCustom, id: number, newValue: number, type: string) => {

    if (type in item) {
        (item as any)[type]?.map(async (el: TaskCustom) => {
            if (el.id === id) {
                let value = null
                if (el.inputType === 'catalog' || el.inputType === 'multi_catalog') {
                    if (el.value) {
                        value = await findByCatalogIdElement(newValue)
                        el.value = value
                    }
                } else {
                    // isElementAlreadyExist = true
                    // Проверить есть ли это поле в fields
                    // Если нет, то добавляю его туда

                    let isElementAlreadyExist = false;
                    (item as any)[type].forEach((nestedElem: TaskCustom) => {
                        if (nestedElem.id === el.id) {
                            isElementAlreadyExist = true
                        }
                    })

                    if (!isElementAlreadyExist) {
                        (item as any)[type].push(el)
                    }

                    return (el.value = newValue)
                }
            } else {
                await findFormElementByIdRecursion(el, id, newValue, type)
            }
        })
    }
}


const findFieldById = (task: TaskCustom, field_id: number) => {
    if ('nested' in task) {
        task.nested?.map((el: TaskCustom) => {
            if (el.id === field_id) {
                return el
            } else {
                findFieldById(el, field_id)
            }
        })
    } else {
        return
    }
}
