// Переводит систему из Wialon Local 2 в local2
import {HOST_MAP} from "@/components/tracker/model/const";
import {CatalogDataType, DynamicFieldsType, FieldsTypeTask, TaskDataType} from "@/components/tracker/model/interface";

export class UtilsWidget {
    static getHostByValue(host: string | null) {
        let result = null

        Object.entries(HOST_MAP).forEach(item => {
            let key = item[0];
            (item as string[][])[1].forEach((element: string) => {
                if (element === host) {
                    result = key
                }
            })
        })

        return result
    }


    static clearCreateByHandObjectFunc(api: any) {
        api.createByHandObject.ibutton = 'ibutton'
        api.createByHandObject.can = 'can'
        api.createByHandObject.gerkon = 'gerkon'
        api.createByHandObject.block = 'block'
        api.createByHandObject.prikur = 'prikur'
        api.createByHandObject.temperature = 'temperature'
        api.createByHandObject.temperaturebluetooth = 'temperaturebluetooth'
        api.createByHandObject.fuel = 'fuel'
        api.createByHandObject.fuel_multiply = 'fuel_multiply'
        api.createByHandObject.fuel_ble = 'fuel_ble'
        api.createByHandObject.fuel_standart = 'fuel_standart'
        api.createByHandObject.fuel_standart2 = 'fuel_standart2'
    }


    static closeCreateByHandModal(api: any) {
        api.select.createInput = false;
        api.select.createInputSensors = false;
        api.createByHandObject.objectInfo.isCreateError = false
        api.createByHandObject.objectInfo.isFindError = false;
        api.createByHandObject.imei = ''
    }

    static lastTrackerMess(time: number) {
        let result = "Ожидание";
        if (time) {
            let lastOnline = Date.now() - time * 1000;
            let seconds = lastOnline / 1000,
                minuts = lastOnline / 1000 / 60,
                hours = lastOnline / 1000 / 60 / 60,
                days = lastOnline / 1000 / 60 / 60 / 24;

            if (days >= 1) {
                result = Math.floor(days) + "д";
            } else if (hours >= 1 && days < 1) {
                result = Math.floor(hours) + "ч";
            } else if (minuts >= 1 && hours < 1 && days < 1) {
                result = Math.floor(minuts) + "м";
            } else if (seconds >= 1 && minuts < 1 && hours < 1 && days < 1) {
                result = Math.floor(seconds) + "с";
            }
        }

        return result;
    }
}


export function findFieldInMrTable(api: any, partName: string, tableName: string) {
    const field = (api.task.fields as FieldsTypeTask[]).find(field => field.name === partName)
    if (!field) return

    return field.fields?.find(el => el.name === tableName)
}


export function findField(field: DynamicFieldsType, index: string, value: unknown, strict = true): FieldsTypeTask[] {
    // find field in Task by index (attribute) and value
    const res = [];
    let search_query = false;
    if (strict && typeof (field[index]) !== typeof ('') && typeof (value) !== typeof ('')) {
        search_query = field[index] === value;
    } else {
        search_query = (field[index] as string).includes(value as string) || (value as string).includes(field[index] as string);
    }
    if (search_query) {
        res.push(field);
    }
    if (field.info !== undefined && field.info !== null && field.fields !== undefined && field.fields !== null) {
        for (const innerField of field.fields) {
            res.push(...findField(innerField, index, value));
        }
    } else if (field.values !== undefined && field.values !== null && field.values.length > 0) {
        for (const row of field.values) {
            for (const innerField of row.cells) {
                res.push(...findField(innerField, index, value));
            }
        }
    }
    return res;
}


export function findFieldTask(task: TaskDataType, index: string, value: unknown, strict = true) {
    // find field in Task by index (attribute) and value
    if (task.fields === null || task.fields === undefined || index === undefined || value === undefined) {
        return [];
    }
    const res = [];
    for (const field of task.fields) {
        res.push(...findField(field, index, value, strict));
    }
    return res;
}