let axios = require('axios')
import {HeaderAxios, ResponseAxios} from "@/shared/api";

export const addTaskObserver = (task_id: number, id: number, type: string) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`
    return axios({
        method: 'POST',
        data: {
            id,
            type
        },
        url: `${process.env.VUE_APP_TASK_RADAR}/task/${task_id}/spectators`,
        withCredentials: true,
        headers
    })

}
