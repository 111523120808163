
import {defineComponent, PropType} from 'vue'
import {TaskComment} from "@/shared/api";
import MessageTime from "@/components/chat/ui/MessageTime.vue";
import {replaceMessage} from "@/components/chat/model/lib/messageMethod";

export default defineComponent({
    name: "MessageError",
    components: {MessageTime},
    emits: ['deleteMessage', 'repeatSendMessage'],
    props: {
        message: {
            type: Object as PropType<TaskComment>,
            required: true
        },
    },
    data() {
        return {
            isModalVisible: false,
        }
    },
    computed: {
        selectedMessagePopupDeleted(): number | null {
            return this.$store.getters.getSelectedMessageDeleted;
        },
    },
    methods: {
        replaceMessage,
        toggleModal() {
            if (this.$store.getters.getSelectedMessageDeleted === this.message.id) return;

            this.$store.dispatch('changeSelectedMessageDeleted', this.message.id)
        },
        resetSelectedMessage() {
            this.$store.dispatch('changeSelectedMessageDeleted', null)
        },
        deleteMessageHandler() {
            this.$emit('deleteMessage', this.message.id)
            this.$store.dispatch('changeSelectedMessageDeleted', null)
        },
        repeatSendMessageHandler() {
            this.$emit('repeatSendMessage', this.message.id)
            this.$store.dispatch('changeSelectedMessageDeleted', null)
        }
    }
})
