import { render, staticRenderFns } from "./CommonFile.vue?vue&type=template&id=b1e4b148&scoped=true"
import script from "./CommonFile.vue?vue&type=script&lang=js"
export * from "./CommonFile.vue?vue&type=script&lang=js"
import style0 from "./CommonFile.vue?vue&type=style&index=0&id=b1e4b148&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e4b148",
  null
  
)

export default component.exports