enum TextValueVar {
    SN_1 = 'Бак 1',
    Filter_1 = 'Фильтрация 1',
    Fuel_1 = 'Объем бака 1',
    SN_2 = 'Бак 2',
    Filter_2 = 'Фильтрация 2',
    Fuel_2 = 'Объем бака 2',
}

interface ITextValue {
    [TextValueVar.SN_1]?: string,
    [TextValueVar.Filter_1]?: string,
    [TextValueVar.Fuel_1]?: string
    [TextValueVar.SN_2]?: string,
    [TextValueVar.Filter_2]?: string,
    [TextValueVar.Fuel_2]?: string
}

interface IReturnType {
    calibrationFile_1: number[][],
    calibrationFile_2: number[][],
    calibrationInfoFile: ITextValue,
}


export const parseMultiFuel = (file: string): IReturnType => {
    const rows = file.split('\n');
    const extractedValues_1: string[][] = [];
    const extractedValues_2: string[][] = [];

    const serialNumber_1 = TextValueVar.SN_1
    const filter_1 = TextValueVar.Filter_1
    const fuel_1 = TextValueVar.Fuel_1

    const serialNumber_2 = TextValueVar.SN_2
    const filter_2 = TextValueVar.Filter_2
    const fuel_2 = TextValueVar.Fuel_2

    const textValue: ITextValue = {};

    rows.forEach((row) => {
        const values = row.split(',').map((value) => value.trim()); // Разбиваем строку на значения и удаляем пробелы в начале и конце каждого значения
        const validValues = values.filter((value) => !isNaN(parseFloat(value))); // Оставляем только числовые значения
        extractedValues_1.push(validValues.slice(0, 2)); // Берем первые три числовых значения из каждой строки
        extractedValues_2.push(validValues.slice(3, 5)); // Берем первые три числовых значения из каждой строки
    });


    // Удаляю все пустые и не заполненые массивы, осталяю только данные для тарировки
    const calibrationValue_1 = extractedValues_1
        .filter(subArray => subArray.length === 2) // Фильтруем массивы с длиной 2
        .map(subArray => subArray.map(Number)) // Преобразуем каждый элемент в числа
        .filter(subArray => subArray[0] !== 0 || subArray[1] !== 0)

    calibrationValue_1.shift()

    // Удаляю все пустые и не заполненые массивы, осталяю только данные для тарировки
    const calibrationValue_2 = extractedValues_2
        .filter(subArray => subArray.length === 2) // Фильтруем массивы с длиной 2
        .map(subArray => subArray.map(Number)) // Преобразуем каждый элемент в числа
        .filter(subArray => subArray[0] !== 0 || subArray[1] !== 0)


    // Получаю дополнительные данные для тарировки
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];

        const parts = row.split(',');
        const indexSerial = parts.indexOf(serialNumber_1)
        const indexFilter = parts.indexOf(filter_1)
        const indexFuel = parts.indexOf(fuel_1)

        const indexSerial2 = parts.indexOf(serialNumber_2)
        const indexFilter2 = parts.indexOf(filter_2)
        const indexFuel2 = parts.indexOf(fuel_2)


        if (indexSerial !== -1 && indexSerial + 1 < parts.length) {
            textValue[serialNumber_1] = parts[indexSerial + 2].trim()
        }

        if (indexFilter !== -1 && indexFilter + 1 < parts.length) {
            textValue[filter_1] = parts[indexFilter + 1].trim()
        }

        if (indexFuel !== -1 && indexFuel + 1 < parts.length) {
            if (parts[indexFuel + 1]) {
                textValue[fuel_1] = parts[indexFuel + 1].trim().replace(/\D/g, "")
            }
        }

        if (indexSerial2 !== -1 && indexSerial2 + 1 < parts.length) {
            textValue[serialNumber_2] = parts[indexSerial2 + 2].trim()
        }

        if (indexFilter2 !== -1 && indexFilter2 + 1 < parts.length) {
            textValue[filter_2] = parts[indexFilter2 + 1].trim()
        }

        if (indexFuel2 !== -1 && indexFuel2 + 1 < parts.length) {
            if (parts[indexFuel2 + 1]) {
                textValue[fuel_2] = parts[indexFuel2 + 1].trim().replace(/\D/g, "")
            }
        }
    }


    return {
        calibrationFile_1: calibrationValue_1,
        calibrationFile_2: calibrationValue_2,
        calibrationInfoFile: textValue
    }
}