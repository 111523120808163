
import {defineComponent} from "vue";

export default defineComponent({
    name: "NotFoundError",
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        path: {
            type: String,
            default: '/'
        }
    }
})
