import '../config/axios'
import {HeaderAxios, SuccessResponseGetTask} from "@/shared/api";
import axios from "axios";
import {
    callNotificationClearTimestampOne,
    callNotUpdateNotification
} from "@/utils/callNotUpdateNotification";
import {saveCommentsInLocalStorage} from "@/utils/storage/storage-comment-method";
import {createHeaders} from "@/shared/api/config/headers";
import globalStore from "@/store";

interface IPostComment {
    text: string,
    attachment_ids: number[]
}

export class ChatApi {

    static async getMessages(task_id: string, activeTab: string) {
        let responseObj = {} as SuccessResponseGetTask
        const headers = createHeaders()

        if (typeof headers === 'string') {
            return console.error(headers);
        }

        try {
            if (!globalStore.getters.getOnlineStatus) {
                throw new Error
            }

            const response = await axios.get(`/task/${task_id}/comments`, {
                headers
            })

            if (response && response.status === 200) {
                if (response.data.length <= 300) {
                    saveCommentsInLocalStorage(Number(task_id), response.data)
                }

                await callNotificationClearTimestampOne('chat')

                return response.data
            }

            if (activeTab === "chat") {
                await callNotUpdateNotification('comments', task_id)
            } else {
                await callNotUpdateNotification('comments', task_id, false)
            }

            responseObj.error = "Не удалось получить сообщения"
            responseObj.status_code = response.status ? response.status : 400


        } catch (error) {
            console.error('Не удалось получить сообщения ', error)
            responseObj.error = "Не удалось получить сообщения"

            if (activeTab === "chat") {
                await callNotUpdateNotification('comments', task_id)
            } else {
                await callNotUpdateNotification('comments', task_id, false)
            }
        }
    }

    static async sendMessage(data: IPostComment, task_id: string) {
        const headers = createHeaders()

        if (typeof headers === 'string') {
            return console.error(headers);
        }

        try {
            const response = await axios.post(`/async/comment/task/${task_id}`, data, {headers})

            return response.status === 200;

        } catch (error) {
            console.error('Не удалось отправить сообщение ', error)

            return false
        }

    }

    static async editMessage(data: IPostComment, comment_id: string) {
        let headers = createHeaders()

        if (typeof headers === 'string') {
            return console.error(headers);
        }

        try {
            const response = await axios.put(`/comment/${comment_id}`, data, {headers})

            if (response.status === 200) {
                return response.data
            }

            return null

        } catch (err) {
            console.log('Не удалось отредактировать сообщение', err)

            return null
        }

    }

}