
import {defineComponent, PropType} from "vue";
import {TaskComment} from "@/shared/api";
import {getFullTime} from "@/utils/time";

export default defineComponent({
    name: "MessageTime",
    props: {
        userMessage: {
            type: Boolean,
            required: true
        },
        message: {
            type: Object as PropType<TaskComment>,
            required: true
        }
    },
    setup() {
        return {
            getFullTime
        }
    },
    data() {
        return {

        }
    },
    computed: {
        messageInfo(): TaskComment {
            return this.message
        }
    }
})
