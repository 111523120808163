import {FieldsTypeTask} from "@/components/tracker/model/interface";

export function getOptionEquipmentValue(optionalEquipment: FieldsTypeTask[]) {
    if (!optionalEquipment[0].value) return null

    const equipment = optionalEquipment[0].value as string[] | string

    if (Array.isArray(equipment)) {
        return equipment.map((catalogValue: string) => {
            return catalogValue
        })
    } else {
        return [equipment]
    }
}