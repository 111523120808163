import {Commit} from "vuex";

type State = {
    dataNotUpdate: boolean,

    lastUpdateTask: number | null
    lastUpdateTaskList: number | null
    lastUpdateComments: number | null
    lastSync: number | null,
}

export default {
    state: {
        dataNotUpdate: false,
        lastUpdateTask: 0,
        lastUpdateTaskList: 0,
        lastUpdateComments: 0,
        lastSync: null,
        copySuccess: false,
    } as State,

    mutations: {
        changeTaskNotUpdated(state: State, payload: boolean) {
            state.dataNotUpdate = payload
        },
        changeLastUpdateTask(state: State, payload: number) {
            state.lastUpdateTask = payload
        },
        changeLastUpdateTaskList(state: State, payload: number) {
            state.lastUpdateTaskList = payload
        },
        changeLastUpdateComments(state: State, payload: number) {
            state.lastUpdateComments = payload
        },
        changeLastSync(state: State, payload: number) {
            state.lastSync = payload
        },
    },
    actions: {
       changeTaskNotUpdated({commit}: {commit: Commit}, payload: boolean) {
           commit('changeTaskNotUpdated', payload)
       },
        changeLastUpdateTask({commit}: {commit: Commit}, payload: number) {
            commit('changeLastUpdateTask', payload)
        },
        changeLastUpdateTaskList({commit}: {commit: Commit}, payload: number) {
            commit('changeLastUpdateTaskList', payload)
        },
        changeLastUpdateComments({commit}: {commit: Commit}, payload: number) {
            commit('changeLastUpdateComments', payload)
        },
        changeLastSync({commit}: {commit: Commit}, payload: number) {
            commit('changeLastSync', payload)
        },
    },
    getters: {
        getCanUpdateData(state: State) {
            return state.dataNotUpdate
        },
        getLastSyncTask(state: State) {
            return state.lastUpdateTask
        },
        getLastSyncTaskList(state: State) {
            return state.lastUpdateTaskList
        },
        getLastSyncComments(state: State) {
            return state.lastUpdateComments
        },
        getLastSync(state: State) {
            return state.lastSync
        },
    }
}
