<template>
  <div class="error-wrapper">
    <p>{{ mess }}</p>
  </div>
</template>
<script>
export default {
  props: {
    mess: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  margin-bottom: 5px;
}

.error-wrapper p {
    font-size: 14px;
}
</style>
