import {LayerData} from "@/components/tracker/model/interface";

enum IButton {
    type_1 = 'IButton',
    type_2 = 'I-Button',
}

enum CAN {
    type_1 = 'CAN-модуль',
    type_2 = 'Подключение к CAN-модулю',
    type_3 = 'CAN'
}

enum Gerkon {
    type_1 = 'Геркон',
    type_2 = 'Датчик Геркон'
}

enum Block {
    type_1 = 'Реле блокировки',
    type_2 = 'Блокировка ДВС',
    type_3 = 'Блок'
}

enum Temperature {
    type_1 = 'Температура',
    type_2 = 'Датчик температуры'
}

enum Temperature_ble {
    type_1 = 'BLE Температура',
}

enum Fuel {
    type_1 = 'ДУТ',
    type_2 = 'Датчик уровня топлива'
}

enum Fuel_2 {
    type_1 = '2ДУТ',
}

enum Fuel_ble {
    type_1 = 'BLE ДУТ'
}

enum Fuel_standart {
    type_1 = 'ШДУТ',
    type_2 = 'Штатный датчик уровня топлива'
}

enum Fuel_standart_2 {
    type_1 = '2ШДУТ'
}

enum Prikur {
    type_1 = 'Прикур'
}

export const ObjectsNameType = {
    ibutton: [IButton.type_1, IButton.type_2],
    can: [CAN.type_1, CAN.type_2, CAN.type_3] ,
    gerkon: [Gerkon.type_1, Gerkon.type_2],
    block: [Block.type_1, Block.type_2, Block.type_3],
    temperature: [Temperature.type_1, Temperature.type_2],
    temperaturebluetooth: [Temperature_ble.type_1],
    fuel: [Fuel.type_1, Fuel.type_2],
    fuel_multiply: [Fuel_2.type_1],
    fuel_ble: [Fuel_ble.type_1],
    fuel_standart: [Fuel_standart.type_1, Fuel_standart.type_2],
    fuel_standart2: [Fuel_standart_2.type_1],
    prikur: [Prikur.type_1],
}

export const wialonLocal = ['Wialon Local', 'Wialon', 'WialonRU']
export const wialonLocal2 = ['Wialon Local 2', 'Local 2']
export const wialonHosting = ['Wialon Hosting']
export const glonasSoft = ['ГлонассSoft', 'glonasssoft', 'glonassoft', 'ГЛОНАССSoft', 'ГлонаССSoft', 'gs']

export const FITTER_TOKEN = 'a61c1badfeb847e487f3d9752e21b0f120cddec9fd721aff35184573f6046dcc'

enum DepartmentName {
    Fitter = 'Монтажники',
    OI = 'Отдел Исполнения',
    CO = 'Сервисный отдел',
    TO = 'Технический отдел',
    OR = 'Отдел Разработки'
}

export const MR_Form = [DepartmentName.TO, DepartmentName.CO, DepartmentName.Fitter, DepartmentName.OI]
export const CR_Form = [DepartmentName.TO, DepartmentName.CO, DepartmentName.Fitter, DepartmentName.OI]
export const Delivery_Form = [DepartmentName.TO, DepartmentName.CO, DepartmentName.Fitter, DepartmentName.OI]
export const Old_Form = [DepartmentName.TO, DepartmentName.CO, DepartmentName.Fitter, DepartmentName.OI, DepartmentName.OR]
export const TO_Form = [DepartmentName.TO, DepartmentName.CO, DepartmentName.OI]
export const Check_Form = [DepartmentName.TO, DepartmentName.OI]

export const CALIBRATION_KEY = 'calibration_store'

export const TIME_CLEAR_CALIBRATION = 604800000

export const calibrationFindValue = ['Топливо', "Уровень топлива", "ДУТ", "ШДУТ"]
export const calibrationFindValueMultiple = ['2ДУТ']

export const HOST_MAP = {
    'local': wialonLocal,
    'hosting': wialonHosting,
    'local2': wialonLocal2,
    'glonassoft': glonasSoft,
}

export type UserCabinetStatus = 'delete' | 'block' | 'active'

export const layersData: LayerData[] = [
    {
        id: 'OSM',
        name: 'OpenStreetMap',
        crs: null,
        templates: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
    },
    {
        id: 'YandexMap',
        name: 'Yandex (Карта)',
        crs: null,
        templates: ['https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&scale=1&projection=web_mercator&lang='.concat('ru')],
    },
    {
        id: 'Yandex (Гибрид)',
        name: 'Yandex (Гибрид)',
        // crs: 'EPSG:3395',
        crs: null,
        templates: ['https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&projection=web_mercator&scale=1&lang='.concat('ru'), 'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.787.0&x={x}&y={y}&z={z}&lang='.concat('ru')],
    },
    {
        id: 'Google(Карта)',
        name: 'Google(Карта)',
        crs: null,
        templates: ['https://mt1.google.com/vt/lyrs=m@169000000&hl='.concat('ru', '&x={x}&y={y}&z={z}')],
    },
    {
        id: 'Google (Гибрид)',
        name: 'Google (Гибрид)',
        crs: null,
        templates: ['https://mt1.google.com/vt/lyrs=s,h@169000000&hl='.concat('ru', '&x={x}&y={y}&z={z}')],
    },
    {
        id: 'Google (Рельеф)',
        name: 'Google (Рельеф)',
        crs: null,
        templates: ['https://mt1.google.com/vt/lyrs=t@130,r@206000000&hl='.concat('ru', '&x={x}&y={y}&z={z}')],
    },
    {
        id: 'Google (Спутник)',
        name: 'Google (Спутник)',
        crs: null,
        templates: ['https://mt1.google.com/vt/lyrs=s@169000000&hl='.concat('ru', '&x={x}&y={y}&z={z}')],
    },
    {
        id: '2GIS',
        name: '2GIS',
        crs: null,
        templates: ['https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd'],
    },
];