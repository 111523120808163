import {Commit} from "vuex";
import {TaskComment} from "@/shared/api";

interface Image {
    file: File,
    url: string
}

type State = {
    attachments: Image[],
    editMode: boolean,
    editCommentId: number | null
    messageBeforeEdit: TaskComment | null,
}

export default {
    state: {
        attachments: [],
        editMode: false,
        editCommentId: null,
        messageBeforeEdit: null as unknown as TaskComment
    } as State,

    mutations: {
        addFileInAttachments(state: State, payload: Image[]) {
            state.attachments.push(...payload)
        },
        removeAttachments(state: State, payload: string) {
            state.attachments = state.attachments.filter(attach => attach.url !== payload)
        },
        clearAttachments(state: State) {
            state.attachments.length = 0;
            state.attachments = [];
        },
        changeEditMode(state: State, payload: boolean) {
            state.editMode = payload
        },
        changeEditCommentId(state: State, payload: number | null) {
            state.editCommentId = payload
        },
        addMessageInfoBeforeEdit(state: State, payload: TaskComment | null) {
            state.messageBeforeEdit = payload
        }
    },
    actions: {
        addFileInAttachments({commit}: { commit: Commit }, payload: File | File[] | null) {
            commit('addFileInAttachments', payload)
        },
        removeAttachments({commit}: { commit: Commit }, payload: number) {
            commit('removeAttachments', payload)
        },
        changeEditMode({commit}: { commit: Commit }, payload: boolean) {
            commit('changeEditMode', payload)
        },
        changeEditCommentId({commit}: { commit: Commit }, payload: number | null) {
            commit('changeEditCommentId', payload)
        },
        addMessageInfoBeforeEdit({commit}: { commit: Commit }, payload: TaskComment | null) {
            commit('addMessageInfoBeforeEdit', payload)
        },
        clearAttachments({commit}: { commit: Commit }) {
            commit('clearAttachments')
        }
    },
    getters: {
        getAttachmentsMessage(state: State) {
            return state.attachments
        },
        getEditMode(state: State) {
            return state.editMode
        },
        getEditCommentId(state: State) {
            return state.editCommentId
        },
        getMessageBeforeEdit(state: State) {
            return state.messageBeforeEdit
        }
    },
}