import {TaskComment} from "@/shared/api";
import {StorageComment} from "@/shared/api/types/storage";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";
import {deleteValueFromMapByTimestamp} from "@/utils/storage/common-method";


// Добавляет новое сообщение в локалсторадж
export function addUnsentCommentInLocalStorage(taskId: number, comment: TaskComment) {
    // Получаю сообщения и превращаю их в Map
    const commentFromLocalStorage = LocalStorageService.getInstance().getItem(STORAGE_KEY.unsentMessage)
    const commentsMap = LocalStorageService.localStorageToMap(commentFromLocalStorage)

    // Получаю нужные мне комменты по id задачи
    const commentsInTask = commentsMap.get(taskId.toString()) as StorageComment | undefined

    // Если сообщения для этой задачи уже есть
    if (commentsInTask) {
        const commentsArray = commentsInTask.comments

        // Добавляю новое сообщение
        commentsArray.push(comment)

        commentsInTask.comments = commentsArray

        commentsMap.set(taskId.toString(), commentsInTask)

        // Сохраняю обновленный Map
        const mapToObject = LocalStorageService.mapToLocalStorage(commentsMap)
        LocalStorageService.getInstance().saveData(STORAGE_KEY.unsentMessage, mapToObject)
    } else {

        const commentMap: Map<number, StorageComment> = new Map()

        commentMap.set(taskId, {
            comments: [comment],
            timestamp: new Date().getTime(),
        })

        const mapToObject = LocalStorageService.mapToLocalStorage(commentMap)

        LocalStorageService.getInstance().saveData(STORAGE_KEY.unsentMessage, mapToObject)
    }
}


// Получает сообщения из локалсторадж
export function getUnsentCommentsFromMap(taskId: number) {
    const comments = LocalStorageService.getInstance().getArray(STORAGE_KEY.unsentMessage)
    const commentMap = LocalStorageService.localStorageToMap(comments)

    const comment = commentMap.get(taskId.toString()) as StorageComment | undefined

    console.log(comment)

    if (comment && comment.comments) {
        return comment.comments
    }

    return null
}

export function getUnsentCommentFromMap(taskId: number, commentId: number) {
    const commentFromLocalStorage = LocalStorageService.getInstance().getItem(STORAGE_KEY.unsentMessage)
    const commentsMap = LocalStorageService.localStorageToMap(commentFromLocalStorage)

    // Получаю нужные мне комменты по id задачи
    const commentsInTask = commentsMap.get(taskId.toString()) as StorageComment | undefined

    if (commentsInTask) {
        return commentsInTask.comments.find(message => message.id === commentId)
    }

    return null;
}


// Удаляет сообщения которым больше недели и возвращает Map
export function deleteUnsentCommentReturnMap(comments: TaskComment) {
    const commentMap = LocalStorageService.localStorageToMap(comments)

    return deleteValueFromMapByTimestamp(commentMap)
}


export function deleteUnsentCommentsOlderThanOneWeek(commentMap: TaskComment) {
    const comment = deleteUnsentCommentReturnMap(commentMap)
    const mapToObject = LocalStorageService.mapToLocalStorage(comment)

    LocalStorageService.getInstance().rewriteData(STORAGE_KEY.unsentMessage, mapToObject)
}


export function deleteUnsentMessageById(taskId: number, commentId: number) {
    const comments = LocalStorageService.getInstance().getArray(STORAGE_KEY.unsentMessage)
    let commentsMap = LocalStorageService.localStorageToMap(comments)

    // Получаю нужные мне комменты по id задачи
    const commentsInTask = commentsMap.get(taskId.toString()) as StorageComment | undefined

    if (commentsInTask) {
        commentsInTask.comments = commentsInTask.comments.filter(message => message.id !== commentId);

        // Если не осталось не отправленных сообщений, то полностью удаляю задачу из ls
        if (commentsInTask.comments.length === 0) {

            commentsMap.delete(taskId.toString());

        }

        const mapToObject = LocalStorageService.mapToLocalStorage(commentsMap)

        LocalStorageService.getInstance().rewriteData(STORAGE_KEY.unsentMessage, mapToObject)
    }
}