import {getStartAndEndDay} from "../utils/time";
import {deleteFromTaskList, getOpenedTaskList} from "../utils/storage/storage-task-list-method";
import {STORAGE_KEY} from "../utils/const";
import globalStore from "../store";
import {task_selected_tab} from "../constants/constants";

export const TaskListMethodMixin = {


	data() {
		return {
			abortController: new AbortController(),
			isAborted: false,
			isRequestShouldCancelled: false,
		}
	},

	watch: {
		date_preset() {
			if (this.date_preset) {
				const {dayStart, dayEnd} = getStartAndEndDay(this.date_preset)

				this.date_start = dayStart
				this.date_end = dayEnd
			}
		}
	},

	methods: {

		abort() {
			if (!this.isRequestShouldCancelled) return;
			this.abortController.abort()
			this.abortController = new AbortController()
		},

		changePopoverStatus(status) {
			this.popoverActive = status
		},

		resetDate() {
			this.date_start = null
			this.date_end = null
			this.date_preset = null

			this.$store.dispatch('addFilters', {
				date_start: this.date_start,
				date_end: this.date_end,
				date_preset: this.date_preset
			})
		},

		goToFullTask(value) {
			this.$emit('go-to-full-task', value)
		},

		async openToggleMenu(name) {
			await this.$store.dispatch('changeOpenListName', name)
		},

		isTaskPasses(obj, isTodayTaskShouldOpen) {
			const findTasks = []

			const currentTab = globalStore.getters.getTaskSelectedTab
			const storageKey = currentTab === task_selected_tab.my ? STORAGE_KEY.openTasksList : STORAGE_KEY.openTasksListAll

			const openedTasks = getOpenedTaskList(storageKey)
			for (let key in obj) {
				const dataItem = obj[key]

				let taskTime = obj[key].tasks[0].date
				let time = new Date()
				let dayTime = Number(time) / 1000 - Math.floor((time.getTime() / 1000) % 86400)
				let diff = dayTime - taskTime

				dataItem.show = (diff < 0 && diff > -86400) && isTodayTaskShouldOpen
				dataItem.active = diff < 0 && diff > -86400

				if (this.taskSelected) {
					for (let i = 0; i < obj[key].tasks.length; i++) {
						if (obj[key].tasks[i].id === this.taskSelected) {
							dataItem.show = true
							break
						}
					}
				}


				if (openedTasks.includes(key)) {
					findTasks.push(key)
					dataItem.show = true
				}
			}

			if (findTasks.length === 0 ) return

			// Ищу разницу в двух массивах, какие элементы не найдены и удаляю их из LocalStorage
			openedTasks.forEach(openTask => {
				if (!findTasks.includes(openTask)) {
					this.removeTaskListFromLocalStorageIfEmptyList(openTask, storageKey)
				}
			})
		},

		removeTaskListFromLocalStorageIfEmptyList(name, storageKey) {
			deleteFromTaskList(name, storageKey)
		},

		selectActiveTime(date_preset) {
			if (!date_preset) return;

			// Отвечает за пресеты календаря в фильтрации
			if (this.date_preset === date_preset) {
				this.date_preset = null
				this.date_start = null
				this.date_end = null

				return
			}

			this.date_preset = date_preset

			/**
			 * @param day Вчера Сегодня Завтра
			 */

			const {dayStart, dayEnd} = getStartAndEndDay(this.date_preset)

			if (this.date_preset === 'Вчера') {
				this.date_start = dayStart
				this.date_end = dayEnd
			} else if (this.date_preset === 'Сегодня') {
				this.date_start = dayStart
				this.date_end = dayEnd
			} else if (this.date_preset === 'Завтра') {
				this.date_start = dayStart
				this.date_end = dayEnd
			}
		},
	}

}