import {Commit} from "vuex";
import {INotification} from "@/shared/api/types/common";

type State = {
    isAppOnline: boolean,
    errorNotification: boolean,
    errorNotificationText: string,
    copySuccess: boolean,
    warningSuccess: boolean,
    messageSendSuccess: boolean
}

export default {
    state: {
        isAppOnline: true,
        errorNotification: false,
        errorNotificationText: '',
        copySuccess: false,
        warningSuccess: false,
        messageSendSuccess: false,
    } as State,

    mutations: {
        changeOnlineStatus(state: State, payload: boolean) {
            state.isAppOnline = payload
        },
        setErrorNotification(state: State, payload: INotification) {
            state.errorNotificationText = payload.message
            state.errorNotification = payload.status

            setTimeout(() => {
                state.errorNotificationText = ''
                state.errorNotification = false
            }, 3000)
        },
        changeCopyStatus(state: State, payload: boolean) {
            state.copySuccess = payload
        },
        changeWarningStatus(state: State) {
            state.warningSuccess = true
            setTimeout(() => {
                state.warningSuccess = false
            }, 2500)
        },
        changeSendMessageStatus(state: State) {
            state.messageSendSuccess = true
            setTimeout(() => {
                state.messageSendSuccess = false
            }, 2500)
        },
    },
    actions: {
        changeOnlineStatus({commit}: {commit: Commit}, payload: boolean) {
            commit('changeOnlineStatus', payload)
        },
        setErrorNotification({commit}: {commit: Commit}, payload: INotification) {
            commit('setErrorNotification', payload)
        },
        changeCopyStatus({commit}: {commit: Commit}, payload: boolean) {
            commit('changeCopyStatus', payload)
        },
        changeWarningStatus({commit}: {commit: Commit}) {
            commit('changeWarningStatus')
        },
        changeSendMessageStatus({commit}: {commit: Commit}) {
            commit('changeSendMessageStatus')
        },
    },
    getters: {
        getOnlineStatus(state: State) {
            return state.isAppOnline
        },
        getErrorNotificationInfo(state: State) {
            return {
                message: state.errorNotificationText,
                status: state.errorNotification
            }
        },
        getCopySuccessStatus(state: State) {
            return state.copySuccess
        },
        getWarningStatus(state: State) {
            return state.warningSuccess
        },
        getSendMessageSync(state: State) {
            return state.messageSendSuccess
        }

    }
}
