
import {defineComponent, PropType} from "vue";
import {attachmentResponse, FileExt, TaskComment} from "@/shared/api";

export default defineComponent({
    name: "MessageAnswer",
    props: {
        resetReply: {
            type: Function,
            required: true
        },
        replyMessage: {
            type: Object as PropType<TaskComment>,
            required: true
        }
    },
    data() {
        return {
            imgExtension: ['jpg', 'jpeg', 'png', 'webp', 'svg', 'gif'],
            message: "",
            attachments: "",
            radarDomain: process.env.VUE_APP_TASK_RADAR,
        }
    },
    computed: {
        editMode(): boolean {
            return this.$store.getters.getEditMode
        }
    },
    mounted() {
        this.getMessageTextAndImage(this.replyMessage)
    },
    watch: {
        replyMessage: function(newVal) {
            this.getMessageTextAndImage(newVal)
        }
    },
    methods: {
        getMessageTextAndImage(replyMessage: TaskComment) {
            this.attachments = ""
            const message = replyMessage.text.split('/blockquote>')[replyMessage.text.split('/blockquote>').length - 1];


            let file = null
            if (replyMessage.attachments.length > 0) {

                for (let i = 0; i < replyMessage.attachments.length; i++) {
                    const current = replyMessage.attachments[i]

                    const img = this.getMessageById(current)

                    const fileExt = this.getFileExtension(img ?? '')

                    if (fileExt) {
                        if (this.imgExtension.includes(fileExt)) {
                            file = img
                            break
                        }
                    }
                }

                if (file) {
                    this.attachments = this.radarDomain + file + '?is_preview=1'
                } else {
                    this.attachments= "file"
                }
            }

            if (this.attachments) {
                if (this.attachments === 'file') {
                    (this.$refs.reply_content as HTMLElement).innerHTML = `
                    <svg style="margin-right: 4px" width="15" height="18" viewBox="0 0 15 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.75 7.5V11.25C13.75 15 12.5 16.5 9.375 16.5H5.625C2.5 16.5 1.25 15 1.25 11.25V6.75C1.25 3 2.5 1.5 5.625 1.5H8.75"
                            stroke="#009bf1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.75 7.5H11.25C9.375 7.5 8.75 6.75 8.75 4.5V1.5L13.75 7.5Z" stroke="#009bf1"
                              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.375 9.75H8.125" stroke="#009bf1" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M4.375 12.75H6.875" stroke="#009bf1" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    ${message}
                    `
                } else {
                    (this.$refs.reply_content as HTMLElement).innerHTML = `
                    <img style="width: 40px; padding-right: 4px" src=${this.attachments} alt="" />

                    ${message}
                    `
                }
            } else {
                (this.$refs.reply_content as HTMLElement).innerHTML = message
            }
        },
        getFileExtension(file: string) {
            if (file) {
                return file.split('.')[file.split('.').length - 1]
            }
        },
        getMessageById(id: number) {
            if (id) {
                const img = this.$store.getters.getAllAttachments.find((el: attachmentResponse) => el.id === id) as attachmentResponse
                if (img && img.file_path) {
                    return img.file_path
                }
            }
        }
    }
})
