<template>
<div>
    <slot v-if="msg.info.spectators.users.length > 0">
        <div class="message-container-text message-container-title">Наблюдатели</div>
        <div class="message-container-fields message-container-main">
            <slot v-if="msg.info.spectators.users.length > 0">
                <div
                    v-for="userId in msg.info.spectators.users"
                    :key="userId"
                    class="message-container-text message-container-gray"
                >
                    <BaseSpectators :id="userId" type="user" />
                </div>
            </slot>

            <slot v-if="msg.info.spectators.departments.length > 0">
                <div
                    v-for="departmentId in msg.info.spectators.departments"
                    :key="departmentId"
                    class="message-container-text message-container-gray"
                >
                    <BaseSpectators :id="departmentId" type="department" />
                </div>
            </slot>
        </div>
    </slot>


    <slot v-if="msg.info.steps.length > 0">
        <div class="message-container-text message-container-title">Этапы</div>
        <div class="message-container-fields message-container-main">
            <slot v-if="msg.info.steps.length > 0">
                <div
                    v-for="(step, id) in msg.info.steps"
                    :key="id"
                    class="message-container-text message-container-gray"
                >
                    <BaseSteps :step="step" :allSteps="allSteps" />
                </div>
            </slot>
        </div>
    </slot>


    <slot v-if="msg.info.values.length > 0">
        <div class="message-container-text message-container-title">Созданные поля</div>
        <div class="message-container-fields message-container-main">
            <slot v-if="msg.info.values.length > 0">
                <BaseValues
                    :newValue="msg.info.values"
                />
            </slot>
        </div>
    </slot>

    <slot v-if="msg.info.tables.length > 0">
        <div class="message-container-text message-container-title">Созданные таблицы</div>
        <div class="message-container-fields message-container-main">
            <slot v-if="msg.info.tables.length > 0">
                <BaseTables
                    :msg="msg"
                    :newValue="msg.info.tables"
                />
            </slot>
        </div>
    </slot>
</div>
</template>

<script>
import BaseSteps from "@/components/chat/message_system/ui/BaseSteps";
import BaseSpectators from "@/components/chat/message_system/ui/BaseSpectators";
import BaseValues from "@/components/chat/message_system/ui/BaseValues";
import BaseTables from "@/components/chat/message_system/ui/BaseTables";
export default {
    name: "MessageSystemCreated",
    components: {BaseTables, BaseValues, BaseSpectators, BaseSteps},
    props: {
        msg: Object,
        title: String,
        iconPath: String,
        type: String,
        allSteps: Array,
    },
}
</script>

<style scoped>

</style>