<template>
	<div class="buttons-div">
		<button class="button-bar selected-router" @click="selectTab('task')">
			<div class="button-bar__wrapper">
				<div class="button-bar-link">
					<slot v-if="activeTab === 'task'">
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/task-full-selected.svg" />
						<p class="button-bar__title--selected">Задача</p>
					</slot>

					<slot v-else>
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/task-full.svg" />
						<p>Задача</p>
					</slot>
				</div>
			</div>
		</button>

		<button class="button-bar selected-router" @click="selectTab('tracker')">
			<div class="button-bar__wrapper">
				<div class="button-bar-link">
					<slot v-if="activeTab === 'tracker'">
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/tracker-selected.svg" />
						<p class="button-bar__title--selected">Трекер</p>
					</slot>

					<slot v-else>
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/tracker.svg" />
						<p>Трекер</p>
					</slot>
				</div>
			</div>
		</button>

		<button class="button-bar selected-router" @click="selectTab('chat')">
			<div class="button-bar__wrapper">
				<div class="button-bar-link" style="position: relative">
					<slot v-if="activeTab === 'chat'">
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/message-selected.svg" />
						<p class="button-bar__title--selected">Чат</p>
					</slot>

					<slot v-else>
						<img style="width: 24px; height: 24px" src="@/assets/img/TabBar/message.svg" />
						<p>Чат</p>
					</slot>
                    <div v-if="$store.getters.getNewMessageCount > 0" class="button__count">{{$store.getters.getNewMessageCount}}</div>
				</div>
			</div>
		</button>
	</div>
</template>

<script>
	export default {
		name: 'TabBar',
		props: {
			task: {
				type: Object,
				required: true
			},
			selectTab: {
				type: Function,
				required: true
			},
			activeTab: {
				type: String,
				required: true,
                default: 'task'
			}
		},
    }
</script>

<style scoped>
	.buttons-div {
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
        z-index: 100;
        padding-top: 4px;
		background-color: #fff;
		border-top: 1px solid #f5f5f3;

		filter: drop-shadow(0px -4px 8px rgba(36, 38, 41, 0.1));
	}
	.button-bar {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: #fff;
		height: 50px;
		width: 100%;
		border: none;
	}
	.button-bar-link {
		width: 100%;
	}
	.button-bar-link img {
		width: 24px;
		height: 24px;
	}
	.button-bar p {
		color: #999999;
		font-size: 12px;
		line-height: 15px;
		font-weight: 500;
        font-family: 'Montserrat', sans-serif;
	}
	.button-bar:not(:last-child) {
		border-right: 1px solid rgba(122, 118, 118, 0.2);
	}
	.button-bar__title--selected {
		font-weight: 600 !important;
		color: #009bf1 !important;
	}
	.button-bar__wrapper {
		width: 60px;
	}
    .button__count {
        position: absolute;
        top: -3px;
        left: 29px;

        min-width: 19px;

        padding: 2px 4px;

        background-color: #EC5555;
        border-radius: 40px;

        text-align: center;
        color: white;

        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
    }
</style>
