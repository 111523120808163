
import {defineComponent} from "vue";
import {getUserOrBotById} from "@/store/interaction/userInteraction";

export default defineComponent({
    name: "MessageWatch",
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            user: null as unknown as string
        }
    },
    mounted() {
        const res = getUserOrBotById(this.userId)

        if (res.type === 'bot') {
            this.user = res.info.bot_name
        }

        if (res.type === 'user') {
            this.user = res.info.name + ' ' + res.info.surname
        }
    }
})
