
import {TaskComment} from "@/shared/api";
import {plural} from "@/helpers/plural";
import {User} from "@/shared/api";
import {defineComponent, PropType} from "vue";
import {getFullTime} from "@/utils/time";
import MessageTime from "@/components/chat/ui/MessageTime.vue";

export default defineComponent({
    name: "MessageReply",
    components: {MessageTime},
    props: {
        msg: {
            type: null as unknown as PropType<TaskComment>,
            required: true
        },
        allUsers: {
            type: [] as PropType<User[]>,
            required: true
        },
        userMessage: {
            type: Boolean,
            required: true
        },
    },
    setup() {
        return {
            getFullTime
        }
    },
    data() {
        return {
            attachments: "",
            link: "",
            replyMessage: "",
            fullName: ""
        }
    },
    async mounted() {
        (this.$refs.msg_text as HTMLElement).innerHTML = this.msg.text

        const replyMessage = this.msg.text.split('/blockquote>')[this.msg.text.split('/blockquote>').length - 1];

        const message = this.msg.text.split('/blockquote>')[0] + '/blockquote>'

        if (replyMessage) {
            this.replyMessage = replyMessage
        }


        if (message) {
            (this.$refs.message_ref as HTMLElement).innerHTML = message
        }

        const block = (this.$refs.msg_text as HTMLElement).children[0]

        const name = block.getAttribute('data-author')

        // Получение информации о пользователе
        const user = this.allUsers.find((item: User) => item.id === Number(name))
        if (user) {
            this.fullName = user.info.name + " " + user.info.surname
        }

        const attachments = block.getAttribute('data-attachments')

        const link = block.getAttribute('data-noteid')

        if (link && Number(link) > 0) {
            this.link = link
        }

        if (attachments && Number(attachments) > 0) {
            const docText = plural(['документ', "документа", "документов"], Number(attachments))
            this.attachments = attachments + ' ' + docText
        }
    },
    methods: {
        goToMessage() {
            const message = document.getElementById(`${this.link}`)

            if (message) {
                message.style.background = '#DBEBFD8a'

                setTimeout(() => {
                    if (message.classList.contains('userMessage')) {
                        message.style.background = "#009bf1"
                    } else {
                        message.style.background = '#fff'
                    }
                }, 2500)

                message.scrollIntoView({block: "center", behavior: "smooth"});
            }
        }
    }
})
