import {HeaderAxios} from "@/shared/api";

export function createHeaders(): HeaderAxios | string {
    let headers = {} as HeaderAxios;
    const token = localStorage.getItem('current_user');

    if (!token) {
        return 'Не авторизован';
    }

    headers['Authorization'] = `Token ${token}`;
    return headers;
}
