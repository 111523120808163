import Vue from "vue";
import Vuex from "vuex";
import task from "@/store/reducer/task";
import current_user from "@/store/reducer/current_user";
import chat from "@/store/reducer/chat";
import user_info from "@/store/reducer/user_info";
import filter from "@/store/reducer/filter";
import system from "@/store/reducer/system";
import message from "@/store/reducer/message";
import notification from "@/store/reducer/notification";
import cache_data from "@/store/reducer/cache_data";


Vue.use(Vuex);

const globalStore = new Vuex.Store({
    modules: {
        user_info,
        chat,
        task,
        current_user,
        filter,
        system,
        message,
        notification,
        cache_data,
    },
});

export default globalStore
