

import {defineComponent} from "vue";

export default defineComponent({
    name: "popover",
    props: {
        status: Boolean,
        toggleFunction: Function,
        height: {
            type: String,
        },
        isFitContent: {
            type: Boolean,
            default: false
        }
    }
})
