import {SuccessResponseGetTask, TaskDataType, TaskPreviewType} from "@/shared/api";

interface TaskFormatted {
    tasks: TaskPreviewType[]
    show: boolean
}


export function sortTaskByDate(tasksObject: TaskPreviewType[]) {
    let dateCur = ''
    let date = [] as string[];
    const dataFormated = {}

    const data = tasksObject.sort((a, b) => (a.date > b.date ? 1 : -1))

    for (let i = 0; i < tasksObject.length; i++) {

        let dateTask = new Date(tasksObject[i].date * 1000),
            dayTask = dateTask.getDate(),
            monthTask = dateTask.getMonth() + 1,
            yearTask = dateTask.getFullYear()

        date[i] = `${dayTask}.${monthTask}.${yearTask}`
    }

    // Данный кусок кода можно наверно решить по другому, но тут предстваелно мое решение
    // Задача заключается в том, чтобы записать в новый объект задачи
    // Задачи должны быть в массиве и стоять в объекте по ключу своей даты
    // Пример:
    // dataFormated: {
    //                  19.05: {tasks: [Array[1]], show: true},
    //                  20.05: [Array[3]],
    //                  23.05: [Array[2]]
    //                }
    // Метод  this.$set перезаписывает элемент по существующему ключу
    // Поэтому перед тем как записывать данные в объект нужно собрать целый массив, состоящий уже из всех задач под нужным ключом

    // Присвиваю текущую дату первой задачи в массиве, чтобы цикл не записал пустое значение
    dateCur = date[0]

    // Массив для комоновки данных
    let allTaskInDay:TaskFormatted = {tasks: [], show: true}
    for (let i = 0; i < date.length; i++) {
        // Если дата прошлой задачи не равна дате текущей
        if (date[i] !== dateCur) {
            // Записываем в объект массив по ключу даты
            // @ts-ignore
            dataFormated[dateCur] = allTaskInDay
            // const obj = {[dateCur]: allTaskInDay}
            // dataFormated.push(obj)
            allTaskInDay = {tasks: [], show: true}
            // Не забываем записать текущую задачу
            allTaskInDay.tasks.push(data[i])
            // Обновляем текущую дату
            dateCur = date[i]

            allTaskInDay.tasks = allTaskInDay.tasks.sort((a: TaskPreviewType, b: TaskPreviewType) => (a.date - b.date))
            // Когда цикл дойдет до конца, записи текущей задачи не будет
            // Для этого проверяем заканчивается ли цикл и если да, то записываем в объект
            if (i === date.length - 1) {
                // @ts-ignore
                dataFormated[dateCur] = allTaskInDay
            }
        } else {
            (date[i] as string | null) = null
            allTaskInDay.tasks.push(data[i])

            allTaskInDay.tasks = allTaskInDay.tasks.sort((a: TaskPreviewType, b: TaskPreviewType) => (a.date - b.date))

            if (i === date.length - 1) {
                // @ts-ignore
                dataFormated[dateCur] = allTaskInDay
            }
        }
    }

    return removeNullTimestampFromTaskList(dataFormated)
}


function removeNullTimestampFromTaskList(dataFormatted: Record<string, TaskFormatted>) {
    if ('1.1.1970' in dataFormatted) {
        let firstJanuary = (dataFormatted as any)['1.1.1970']
        delete (dataFormatted as any)['1.1.1970'];

        (dataFormatted as any)[''] = removeDateFromTask(firstJanuary);
    }

    return dataFormatted
}

function removeDateFromTask(dataFormatted: TaskFormatted): TaskFormatted {
    dataFormatted.tasks.forEach(item => (item.date as any) = null)

    return dataFormatted
}


export function loadTaskCondition(task: TaskDataType, response: SuccessResponseGetTask) {
    return checkLoadCondition(task, response);
}

export function loadMessageCondition(isMessageLoadFromCache: boolean, response: SuccessResponseGetTask) {
    return checkLoadCondition(null, response, isMessageLoadFromCache);
}


function checkLoadCondition(task: TaskDataType | null, response: SuccessResponseGetTask, isMessageLoadFromCache?: boolean) {
    if ((!response || response.error) && (!task || Object.keys(task).length === 0) && !isMessageLoadFromCache) {
        if (response && response.status_code === 404) {
            return "NONE_EXIST";
        } else {
            return "ERROR";
        }
    } else if (response && !response.error) {
        return "SUCCESS";
    } else {
        return "SKIP";
    }
}

export function openActiveTaskList(openMenuName: string, dataFormated: TaskFormatted[]) {
    // Оставляет список с задачами открытым при изменении сокетов
    if (openMenuName) {
        for (let key in dataFormated) {
            if (key === openMenuName) {
                return key
            }
        }
    }
}
