import {Commit} from "vuex";
import {User} from "@/shared/api";
import {saveUserDataInLocalStorage} from "@/utils/storage/storage-user-method";

type State = {
    current_user: string
    current_user_id: string
    user_data: User
}

export default {
	state: {
		current_user: localStorage.getItem('current_user') || null,
		current_user_id: localStorage.getItem('current_user_id') || null,
	},
	mutations: {
		addCurrentUser(state: State, payload: {token: string, user_id: string}) {
			state.current_user = payload.token
			state.current_user_id = payload.user_id

			localStorage.setItem('current_user', state.current_user)
			localStorage.setItem('current_user_id', state.current_user_id)
		},
        addCurrentUserData(state: State, payload: User) {
            saveUserDataInLocalStorage(payload)

            state.user_data = payload
        },
        exitFromAccount() {
            localStorage.removeItem('current_user')
            localStorage.removeItem('current_user_id')
        },
	},
	actions: {
		addCurrentUser({ commit }: { commit: Commit }, payload: {token: string, user_id: string}) {
			commit('addCurrentUser', payload)
		},
        addCurrentUserData({ commit }: { commit: Commit }, payload: User) {
            commit('addCurrentUserData', payload)
        },
        exitFromAccount({ commit }: { commit: Commit }) {
            commit('addCurrentUserData', null)
            commit('addCurrentUser', {token: "", user_id: ""})
            commit('exitFromAccount')
        }
	},
	getters: {
		getCurrentUser(state: State) {
			return state.current_user
		},
		getCurrentUserId(state: State) {
			return state.current_user_id
		},
        getUserData(state: State) {
		    return state.user_data
        }
	}
}
