<template>
    <div class="LayerControlWrapper">
        <div class="LayerControl">
            <div class="layerSelect" @click="toggleFullSize">
                <div class="layerSelectedIcon">
                    <!-- Замените на ваш SVG-иконку -->
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#009bf1"/>
                        <path d="M5.99945 18.7499C5.80945 18.7499 5.61945 18.6799 5.46945 18.5299C5.17945 18.2399 5.17945 17.7599 5.46945 17.4699L17.4695 5.46994C17.7595 5.17994 18.2395 5.17994 18.5295 5.46994C18.8195 5.75994 18.8195 6.23994 18.5295 6.52994L6.52945 18.5299C6.37945 18.6799 6.18945 18.7499 5.99945 18.7499Z" fill="#009bf1"/>
                        <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V6.75H14C13.59 6.75 13.25 6.41 13.25 6C13.25 5.59 13.59 5.25 14 5.25H18C18.41 5.25 18.75 5.59 18.75 6V10C18.75 10.41 18.41 10.75 18 10.75Z" fill="#009bf1"/>
                        <path d="M10 18.75H6C5.59 18.75 5.25 18.41 5.25 18V14C5.25 13.59 5.59 13.25 6 13.25C6.41 13.25 6.75 13.59 6.75 14V17.25H10C10.41 17.25 10.75 17.59 10.75 18C10.75 18.41 10.41 18.75 10 18.75Z" fill="#009bf1"/>
                    </svg>
                </div>
            </div>

            <div class="layerSelect" @click="toggleList">
                <div class="layerSelectedIcon">
                    <!-- Замените на ваш SVG-иконку -->
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.28998 7.77998V17.51C2.28998 19.41 3.63998 20.19 5.27998 19.25L7.62998 17.91C8.13998 17.62 8.98998 17.59 9.51998 17.86L14.77 20.49C15.3 20.75 16.15 20.73 16.66 20.44L20.99 17.96C21.54 17.64 22 16.86 22 16.22V6.48998C22 4.58998 20.65 3.80998 19.01 4.74998L16.66 6.08998C16.15 6.37998 15.3 6.40998 14.77 6.13998L9.51998 3.51998C8.98998 3.25998 8.13998 3.27998 7.62998 3.56998L3.29998 6.04998C2.73998 6.36998 2.28998 7.14998 2.28998 7.77998Z" stroke="#009bf1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.56 4V17" stroke="#009bf1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.73 6.62012V20.0001" stroke="#009bf1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="LayerControlList" :class="{ LayerControlListShown: showList }">
                <div v-for="layer in layers" :key="layer.id" class="LayerItem">
                    <Layer :layer="layer" :selectedLayer="selectedLayer" @layer-toggle="onLayerToggle" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layer from "./Layer";
export default {
    name: "LayerControl",
    components: {Layer},
    props: {
        layers: {
            type: Array,
            required: true
        },
        selectedLayer: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            showList: false
        };
    },
    methods: {
        toggleList() {
            this.showList = !this.showList;
        },
        // handleClickOutside(event) {
        //     const target = event.target;
        //     if (target && target.closest('.layerSelect')) {
        //         this.showList = true;
        //     } else if (target && !target.closest('.LayerControlList')) {
        //         this.showList = false;
        //     }
        // },
        onLayerToggle(id) {
            this.showList = false
            this.$emit('onLayerToggle', id);
        },
        toggleFullSize() {
            console.log('render')
            this.$emit('openMapFullSize')
        }
    },
    // mounted() {
    //     document.addEventListener('click', this.handleClickOutside);
    // },
    // beforeDestroy() {
    //     document.removeEventListener('click', this.handleClickOutside);
    // }
};
</script>

<style scoped>
.LayerControl {
    position: relative;
}

.LayerControlWrapper {
    position: absolute;
    right: 0;

    margin-right: 20px;
    padding: 4px;

    background: transparent;
    z-index: 2;
}

.layerSelect {
    display: flex;
    justify-content: flex-end;
}

.layerSelect:nth-child(2) {
    margin-top: 4px;
}

.layerSelectedIcon {
    padding: 10px;
    height: min-content;
    align-items: center;
    display: flex;
    background-color: var(--primary-light);
    border-radius: 4px;
}

.layerSelectedIcon svg{
    width: 24px;
    height: 24px;
}
.layerSelectedText {
    color: var(--primary-color);
    font-size: 14px;
}

.LayerControlList {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: white;
    border-radius: 6px;
    padding: 6px;
    gap: 6px;
    height: 0;
    visibility: hidden;
}

.LayerControlListShown {
    opacity: 1;
    height: auto;
    visibility: visible;
}
</style>
