import {
    CalibrationBody, CalibrationEditBody, ChangeTrackerData,
    createCabinetProp,
    createCabinetSuccess, CreateUnitsType,
    FieldValue, GeoCode, IChangeSim, ISimData, TaskStatus, unit,
    UserData
} from "@/components/tracker/model/interface";
import {getRequest, patchRequest, postRequest, putRequest} from "@/utils/http-request";

export class APIFitter {

    static async getCabinetsFromApi(api: any, login: FieldValue, host: string | null) {
        let body: { [k: string]: unknown } = {login: login};
        if (host !== null) {
            // Получает хост в сокращенном виде
            body.host = host
        }

        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/search/user`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (res.body.status === 'error') {
            return []
        }

        return res.body.data
    }

    static async createCabinetAPI(api: any, login: string, host: string, taskId: string) {
        let body = {
            login: login,
            host: host,
            create_by: taskId.toString()
        }

        return await postRequest(`${api.FITTER_DOMAIN}/fitter/account/create`, body, {
            'CREATE-ACCOUNT-TOKEN': api.FITTER_TOKEN,
            'Authorization': api.RADAR_TOKEN
        }, true) as createCabinetSuccess
    }


    static async createCabinetAPIWithoutLogin(api: any, body: createCabinetProp) {

        return postRequest(`${api.FITTER_DOMAIN}/fitter/account/create`, body, {
            'CREATE-ACCOUNT-TOKEN': api.FITTER_TOKEN,
            'Authorization': api.RADAR_TOKEN
        }, true)
    }


    static getApiUnits(api: any, userData: UserData) {
        const host = userData.host

        if (userData.user_id) {
            return getRequest(`${api.FITTER_DOMAIN}/fitter/user/${userData.user_id}/units`, {}, {
                'host-wg': host,
                'Authorization': api.RADAR_TOKEN
            }, true)
        }

        return null
    }

    static async getStatusMany(api: any, unit_ids: number[], userData: UserData) {
        let body = {
            host: userData.host,
            unit_ids
        }

        if (unit_ids.length > 0 && userData.host) {
            return postRequest(`${api.FITTER_DOMAIN}/fitter/user/${userData.user_id}/unit/status`, body, {
                'Authorization': api.RADAR_TOKEN
            }, true)
        }

        return null
    }


    static async getUnitDataByIMEI(api: any, imei: string) {
        const res = await getRequest(`${api.FITTER_DOMAIN}/fitter/tracker_by_token/${imei}`, {}, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            if (res.status === 404) {
                return api.$store.dispatch("setErrorNotification", {
                    message: "Объект не найден",
                    status: true
                })
            }

            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }


    static async changeUnitNameAPI(api: any, unit_name: string, unit_id: string, change_name: string, unit_serial: string) {
        const body = {
            unit_name,
            host: api.userData.host,
            unit_id,
            change_name,
            unit_serial,
            task_id: api.task.id
        }

        try {
            const res = await patchRequest(`${api.FITTER_DOMAIN}/fitter/user/${api.userData.user_id}/unit/name`, body, {
                'Authorization': api.RADAR_TOKEN
            }, true)

            if (!res || res.status !== 200) {
                api.$store.dispatch("changeWarningStatus")
                return false
            }

            return true

        } catch (err) {
            for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
                const current = api.unitsData.inGsAndInTable[i] as unknown as unit

                if (current.unit.unit_id === unit_id) {
                    if (current.state) {
                        api.$store.dispatch("changeWarningStatus")
                    }
                }
            }
        }
    }


    static async changeUnitMileageAPI(api: any, change_mileage: string, unit_id: string, unit_name: string) {
        const body = {
            change_mileage,
            host: api.userData.host,
            unit_id,
            unit_name,
            task_id: api.task.id
        }

        try {
            const res = await patchRequest(`${api.FITTER_DOMAIN}/fitter/user/${api.userData.user_id}/unit/editor/mileage`, body, {
                'Authorization': api.RADAR_TOKEN
            }, true)

            if (!res || res.status !== 200) {
                api.$store.dispatch("changeWarningStatus")
                return false
            }

            return true

        } catch (err) {
            for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
                const current = api.unitsData.inGsAndInTable[i] as unknown as unit

                if (!current || !current.unit) return api.$store.dispatch("changeWarningStatus")

                if (current.unit.unit_id === unit_id) {
                    if (current.state) {
                        api.$store.dispatch("changeWarningStatus")
                    }
                }
            }

            return false
        }
    }

    static async executeCommandAPI(api: any, command_name: string, unit_id: string, unit_name: string) {
        let body = {
            command_name,
            host: api.userData.host,
            unit_id,
            unit_name,
            task_id: api.task.id
        }

        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/user/${api.userData.user_id}/unit/command`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }


    static async executeExactCommandAPI(api: any, command_id: string) {
        return getRequest(`${api.FITTER_DOMAIN}/fitter/user/unit/command/${command_id}`, {}, {
            'Authorization': api.RADAR_TOKEN
        }, true)
    }

    static async checkSimAPI(api: any, phone_number: string) {
        const body = {
            number: phone_number
        }

        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/check_sim`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async createUnitsAPI(api: any, body: CreateUnitsType) {
        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/user/${api.userData.user_id}/units/creator`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async decodeGeoCode(api: any, body: GeoCode) {
        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/geo_code`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async updateObjectStatus(api: any, task_id: number, body: TaskStatus) {
        const res = await patchRequest(`${api.FITTER_DOMAIN}/fitter/task/status/${task_id}`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async createCalibration(api: any, body: CalibrationBody) {
        const res = await patchRequest(`${api.FITTER_DOMAIN}/fitter/editor/unit/calibration`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }


    static async editCalibrationFields(api: any, body: CalibrationEditBody) {
        const res = await putRequest(`${api.FITTER_DOMAIN}/fitter/task/edit/unit_fuel`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }


    static async changeUnitSerial(api: any, body: ChangeTrackerData) {
        const res = await patchRequest(`${api.FITTER_DOMAIN}/fitter/editor/unit/tracker`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async updateUnitsTrackerAPI(api: any, body: CreateUnitsType) {
        const res = await putRequest(`${api.FITTER_DOMAIN}/fitter/user/${api.userData.user_id}/units/creator`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        if (!res || res.status !== 200) {
            api.$store.dispatch("changeWarningStatus")
        }

        return res
    }

    static async changeSim(api: any, body: IChangeSim) {
        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/sim/change-sim`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        return res.status
    }


    // 200 - симка сменилась
    // 202 -  процесс смены запущен, об этом можно как-то сообщить, что оно сменится в течение какого-то времени (в фоне будет работать)

    static async disableSim(api: any, body: ISimData) {
        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/sim/disable-lock`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        return res.status
    }

    static async enableSim(api: any, body: ISimData) {
        const res = await postRequest(`${api.FITTER_DOMAIN}/fitter/sim/enable-lock`, body, {
            'Authorization': api.RADAR_TOKEN
        }, true)

        return res.status
    }
}