export const TASK = {
    client__new: 'Новый клиент',

	date: 'Дата проведения',
	date_old: 'Дата проведения работ',
	date_and_time: 'Дата и время',
	fitter: "Установщик",
	fitters: "Установщики",
    description: 'Описание',
    auto: 'Авто',
    contact_person: "Контактное лицо",
    description_or_recommendation: 'Описание или рекомендации',
    fitter_note: 'FitterNote',
    to_number: 'Номер ТО',

	place: 'Место',
    place_old: 'Место проведения работ',
    place__city: 'Город',
	place__address: 'Адрес',
	place__address_old: 'Адрес установки',
	place__client_call_in: 'Набрать клиента за',
	place__system: 'Система',
	client__info: 'Информация о клиенте',
	client__client_name: 'Имя клиента',
	client__client_login: 'Логин в системе',
	client__client_login_fact: 'Логин фактический',
	client__phone: 'Телефон',
	client__additional_phone: 'Дополнительный телефон',
	client__additional_contact: 'Дополнительный контакт',
	client__company: 'Юр. Лицо',
	client__client_class: 'Класс клиента',
	client__enter_data: 'Как отправить данные для входа?',
    client__enter_data_delivery: 'Как оправить данные для входа?',
	client__contact: 'Контакт',
	pay: 'Оплата',
	pay_old: 'Модуль оплаты',
	pay__payment: 'Вид оплаты',
	pay__payment_fact: 'Оплачено по факту',
	pay__comment_for_ko: 'Комментарий для КО',
	pay__ppa: 'PPA',
	pay__amount: 'Сумма',
	pay__amount_old: 'Взять с клиента',
	task: 'Тех задание',

    work_result_old: 'Результат работ',
    work_info: 'Информация о работах',
    work_info_old: 'Техническое задание',
    work_TO: 'Проведение работ',
    work__progress: 'Ход работ',
	work__progress_old: 'Информация о работах',
	work__car_count: 'Кол-во авто',
	work__work_type: 'Вид работ',
	work__optional_equipment: 'Дополнительное оборудование',
	work__model: 'Марка, модель техники',
	work__model_old: 'Марка, модель, год выпуска авто',
	work__to_function: 'Функции ТО',
	work__recommend: 'Рекомендации для монтажника',
	work__text: 'Текст для монтажника',
	work__dut_dead: 'ДУТ загрублен',

	task__object_count: 'Кол-во объектов',
	task__car_description: 'Имя объекта(ов)',
	task__error_type: 'Тип проблемы',
	task__possible_reason: 'Возможная причина',
	task__what_todo: 'Что делаем',
	task__what_todo_question: 'Что делаем?',
	task__initial_equipment: 'Оборудование (исходное)',
	task__replacement: 'Производим замену',

    delivery__auto: 'Информация о авто',
    delivery__auto_ext: 'Доп оборудование',
    delivery__delivery: 'Доставка',
    delivery__address: 'Адрес доставки',
    delivery__by: 'Кем доставят',

    TO_before: "Перед ТО проверить лампочки/жидкости. При необходимости поменять/долить",

    file__serial_book_entry : 'Запись в сервисной книжке (фото)',
    file__conclusion_based_results_of_maintenance : 'Заключение дилера по результатам ТО (фото)',
    remains_of_equipment: 'Остатки оборудования',
    remainders_match: 'Остатки совпадают',

    eo__weekend : 'Выходной',
    eo_end_of_business_report: 'Отчет на конец рабочего дня',
    eo_photo_parker_car : 'Фото припаркованного авто',
    eo_fixing_incident: 'Фиксация происшествия',
    eo_freelance_incidents : 'Внештатные происшествия',
    eo_gas : 'Заправки за наличные',
    eo_gas_station_checks: 'Чеки заправок',

	// ПХД
	phd_car_equipment: 'Комплектация авто',
	phd_extension: 'Удлинитель',
	phd_set_of_sockets_and_ratchets: 'Набор головок и трещоток (96 предметов)',
	phd_hacksaw_for_metal: 'Ножовка по металлу',
	phd_angle_grinder : 'Углошлифовальная машинка (болгарка)',
	phd_sapper_shovel : 'Лопата саперная',
	phd_screwdriver : 'Шуруповерт',
	phd_spare_wheel : 'Запасное колесо (фото)',
	phd_control_pandora : 'Контролька (Pandora Alt-205)',
	phd_unu : 'УНУ (программатор для ДУТ), 2 шт',
	phd_flashlight : 'Фонарь',
	phd_coarse_and_fine_filter : 'Фильтр грубой и тонкой очистки',
	phd_fire_extinguisher : 'Огнетушитель (обязательно проверить срок годности)',
	phd_warning_triangle : 'Знак аварийной остановки',
	phd_first_aid_kit : 'Аптечка',
	phd_laptop_with_charger: 'Ноутбук с зарядным устройством',

	phd_carrying_out_work : 'Проведение работ',
	phd_car_wash : 'Мойка авто + фото по кругу на предмет повреждений',
	phd_inflate_tires : 'Подкачать шины',
	phd_check_bulbs : 'Проверить лампочки/жидкости. По необходимости поменять/долить',
	phd_spare_wheel_inspection : 'Осмотр запасного колеса',

	phd_mileage_photo : 'Пробег (фото)',
	phd_mileage___edit : 'Пробег',
	phd_meter_calibration : 'Калибровка счетчика для ДУТ',
	phd_video_monitoring : 'Проверка видеомониторинга (онлайн + архив)',


    // Редактируемые
    phd_spare_wheel_condition___edit : 'Состояние запасного колеса',
	phd_claims_for_the_work___edit___end : 'Претензии к работе авто',
    eo_detailed_description_of_incident___edit : 'Подробное описание происшествия',
    eo_number_of_filling_stations___edit : 'Количество заправок',

    // Финальный этап
    stock___end: "Склад",
    TO_end___end: "ТО проведено",
    service_performed___end: 'Сервис проведен',
    total___end: 'Итог',


    router: 'roitung',
	user: 'user',
	watch: 'Наблюдатели',
    note: 'Примечание',
    text1: 'Текст 1',
    text2: 'Текст 2',
}



export enum ConditionType {
    Equal = 1,
    NotEqual,
    NotEmpty,
    Empty,
    StartWithValue,
    EndWithValue,
    IncludesValue
}

export enum notificationStatus {
    connection,
    error,
    success,
    warning,
    full_fill,
    info,
    loading,
}


export const STORAGE_KEY = {
    task: 'task',
    taskList: 'taskList',
    openTasksList: 'openTasksList',
    openTasksListAll: 'openTasksListAll',
    comments: 'comments',
    activeTab: 'activeTab',
    users: 'users',
    current_user_id: 'current_user_id',
    current_user: 'current_user',
    current_user_data: 'current_user_data',
    cache_version: 'cache_version',
    calibration_store: 'calibration_store',
    prev_task_id: 'prev_task_id',
    unsentMessage: 'unsentMessage',
	task_selected_tab : 'task_selected_tab',
} as const

export enum LoadConditionResult {
    NONE_EXIST = "NONE_EXIST",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    SKIP = "SKIP"
}

export const VERSION = '1'

export const WEEK = 604800000
export const ENABLE_EDIT_TIME =  10800000