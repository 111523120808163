
import {defineComponent, PropType} from 'vue'
import { LockStatus, unit } from "@/components/tracker/model/interface";

enum ButtonStatus {
    DISABLED = 'disabled',
    ENABLED = 'enable',
    LOADING = 'loading',
    FAIL = 'fail'
}

const ButtonText = {
    [ButtonStatus.DISABLED]: "Функция неактивна",
    [ButtonStatus.ENABLED]: "Функция активна",
    [ButtonStatus.FAIL]: "IMEI не опознан",
    [ButtonStatus.LOADING]: "Идет подключение"
}

export default defineComponent({
    name: "simLock",
    props: {
        imeiLockStatus: {
            type: [String, undefined] as PropType<LockStatus | undefined>,
            default: undefined
        },
        triggered: {
            type: [Boolean, undefined] as PropType<boolean | undefined>,
            default: undefined
        },
		unit: {
			type: Object as PropType<unit>,
			required: true
		},
		taskId: {
			type: Number,
			required: true
		},
		simToggleStatus: {
			type: Boolean,
			required: true
		},
        interactivity: {
            type: Boolean,
            default: true
        }
    },
	emits: ['disableSimHandler', 'enableSimHandler'],
    computed: {
        buttonStyle(): string {
			if (this.simToggleStatus) {
				return ButtonStatus.LOADING
			}

            switch (this.imeiLockStatus) {
                case 'disabled':
                    return ButtonStatus.DISABLED
                case 'enabled':
                    return ButtonStatus.ENABLED
                case 'disabling':
                    return ButtonStatus.LOADING
                case 'enabling':
                    return ButtonStatus.LOADING
                case 'imei_failed':
                    return ButtonStatus.FAIL
                default:
                    return ButtonStatus.DISABLED
            }
        },
        buttonText(): string {
			if (this.simToggleStatus) {
				return "Выполняется..."
			}

            if (this.buttonStyle === ButtonStatus.ENABLED) {
                return this.triggered ? 'Функция сработала' : 'Функция активна'
            }

            return (ButtonText as any)[this.buttonStyle];
        },
    },
	methods: {
		disableSimHandler() {
			this.$emit('disableSimHandler', {
				number: this.unit.many?.sim.number,
				task_id: this.taskId,
				imei: this.unit.tracker.tracker_imei
			})
		},
		enableSimHandler() {
			this.$emit('enableSimHandler', {
				number: this.unit.many?.sim.number,
				task_id: this.taskId,
				imei: this.unit.tracker.tracker_imei
			})
		},
	}
})
