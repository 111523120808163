import {TaskTableTracker, unit, User} from "@/components/tracker/model/interface";
import {findFieldTask} from "@/components/tracker/model/utils";
import {CatalogInteraction} from "@/components/tracker/model/methods";

export function getUserWhoAcceptObject(objectStatus: TaskTableTracker, current: unit, allUsers: User[]) {

    const getUserById = (userId: number): User | null => {
        const foundUser = allUsers.find((user) => user.id.toString() === userId.toString());

        return foundUser
            ? {
                id: foundUser.id,
                info: {
                    name: foundUser.info.name,
                    surname: foundUser.info.surname,
                },
                avatar_url: foundUser.avatar_url,
            } as User
            : null;
    };

    const setUserAccept = (userId: number, property: string) => {
        const user = getUserById(userId);
        if (user) {
            if (current.state && current.state.buttonStatus) {
                (current.state.buttonStatus as any)[property] = user;
            }
        }
    };

    if (objectStatus.fitter_user) {
        setUserAccept(objectStatus.fitter_user, 'fitterAccept');
    }

    if (objectStatus.to_user) {
        setUserAccept(objectStatus.to_user, 'toAccept');
    }
}

export function getTableColumnByImei(api: any, unit: unit): number | null | undefined {
    const unit_imei = unit.tracker.tracker_imei
    const unit_serial = unit.tracker.tracker_serial

    // Получаю id строки в таблице по IMEI
    const table = CatalogInteraction.findTable(api)

    if (table && table[0].values.length > 0) {
        for (let i = 0; i < table[0].values.length; i++) {
            const current = table[0].values[i]

            for (let j = 0; j < current.cells.length; j++) {
                const currentCell = current.cells[j]

                // Нахожу совпадение по IMEI и беру row_id
                if (currentCell.name === "IMEI" || currentCell.name === "Номер блока" || currentCell.name === 'S/N') {
                    if (currentCell.value) {
                        if (currentCell.value.toString() === unit_imei.toString() || currentCell.value.toString() === unit_serial.toString()) {
                            if (current.row_id !== null && current.row_id !== undefined) {
                                return current.row_id as number
                            }
                        }
                    }
                }
            }
        }
    }

    return null
}