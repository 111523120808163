<template>
    <div>
        <div v-for="(value, i) in addedFields" :key="i" class="message-container-fields message-container-main">
            <div v-if="value.fieldName" class="message-container-text"
                 style="padding: 5px 0;">Поле: {{ value.fieldName }}
            </div>
           <div>
               <div v-for="(item, j) in value" :key="j" class="message-container-text message-container-gray">
                   <slot v-if="item.name">{{ item.name }} - <slot v-if="item.value">{{ item.value }}</slot><slot v-else>Пусто</slot></slot>
                   <slot v-if="item.name"> в таблице {{item.field_id + 1}}</slot>
               </div>
           </div>
        </div>
    </div>
</template>

<script>

import {getFormFieldById, timestampToDate} from "@/utils/field";
import {getCatalogFromCache} from "@/utils/catalogs";

export default {
    name: "baseTables",
    props: {
        msg: Object,
        newValue: Array,
    },
    data() {
        return {
            addedFields: [],
        }
    },
    mounted() {
        this.getField()
    },
    methods: {
        async getField() {
            for (let i = 0; i < this.newValue.length; i++) {
                const current = this.newValue[i]

                if (current.values.length > 0) {
                    // Получаю эту таблицу по id
                    const res = getFormFieldById(current.field_id)

                    const result = JSON.parse(JSON.stringify(res.values[0].cells))
                    const resultField = []

                    for (let j = 0; j < res.values[0].cells.length; j++) {
                        const currentArrayItem = result[j]

                        for (const item of current.values) {
                            currentArrayItem.value = ''
                            currentArrayItem.old_value = ''
                            currentArrayItem.row_id = ''
                        }
                    }

                    for (let j = 0; j < current.values.length; j++) {
                        const currentDeleted = current.values[j]

                        for (let k = 0; k < result.length; k++) {
                            if (result[k].id === currentDeleted.field_id) {
                                const newItem = JSON.parse(JSON.stringify(result[k]))
                                let res = null

                                res = await this.checkType(newItem, currentDeleted, i)

                                resultField.push(res)
                            }
                        }
                    }
                    this.addedFields.push({fieldName: res.name}, resultField)
                }
            }
        },
        async checkType(newItem, currentElementType, index, type) {
            if (newItem.type === "catalog") {
                currentElementType.value.forEach(item => {
                    newItem.value += item
                })

                if (currentElementType.old_value) {
                    currentElementType.old_value.forEach(item => {
                        newItem.old_value += item
                    })
                }

                newItem.field_id = currentElementType.row_id

            } else if (newItem.type === "multi_catalog") {


                currentElementType.value.forEach(item => {
                    newItem.value += item
                })

                if (currentElementType.old_value) {
                    currentElementType.old_value.forEach(item => {
                        newItem.old_value += item
                    })
                }

                newItem.field_id = currentElementType.row_id

            } else if (newItem.type === "checkmark") {
                if (newItem.value === true) {
                    newItem.value = "Нет"
                    newItem.old_value = "Да"
                    newItem.field_id = currentElementType.row_id
                } else {
                    newItem.value = "Да"
                    newItem.old_value = "Нет"
                    newItem.field_id = currentElementType.row_id
                }

            } else if (newItem.type === 'due_date') {
                newItem.value += timestampToDate(this.newValue[0].value)
                newItem.field_id = currentElementType.row_id
            } else {
                currentElementType.value.forEach(item => {
                    newItem.value += item
                })
                newItem.field_id = currentElementType.row_id
                if (type === 'updated') {
                    if (currentElementType.old_value) {
                        currentElementType.old_value.forEach(item => {
                            newItem.old_value += item
                        })
                    }
                }
            }

            return newItem
        }
    }
}
</script>

<style scoped>
.message-container-info {
    display: flex;
    align-items: center;
}

.message-username {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
}

.message-department {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.message-status {
    display: flex;
    align-items: center;
    margin-left: 6px;
}

.message-container-title {
    margin-top: 6px;
}

.message-container-gray {
    white-space: pre-line;
    font-size: 13px;
}

.message-container-fields {
    display: flex;
    flex-direction: column;
}

.message-status-change {
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #999999;
    margin-top: 4px;

    text-align: left;
    white-space: pre-line;
    margin-left: -40px;
}

.message-status p {
    padding-left: 4px;
    margin: 0;

    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}
</style>