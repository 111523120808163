import {User} from "@/shared/api";

let axios = require('axios')

export const login_radar = (userAccount:  {username: string, password: string}): Promise<{data: {token: string, user_id: string} }| boolean> => {

	return axios({
		method: 'post',
		url: `${process.env.VUE_APP_TASK_RADAR}/users/login`,
		data: userAccount,
		withCredentials: true
	})
		.then((resp: { status: number; data: {data: {token: string, user_id: string} }}) => {
			if (resp.status === 200) {
				return resp.data
			} else {
				console.error('Не удалось войти в кабинет. Код ошибки ', resp.status)
			}
		})
		.catch(() => {
			return false
		})
}
