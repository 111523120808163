<template>
    <div>
        <slot v-if="stepInfo" style="margin-top: 10px">{{stepInfo[0].name}}</slot>
        <slot v-if="stepInfo">
            <slot v-if="stepInfo[0].users.length > 0">
                <div
                    v-for="user in stepInfo[0].users"
                    :key="user.id"
                    class="message-container-text message-container-gray"
                >
                    <BaseSpectators :id="user.id" type="user" :entityName="entityName"/>
                </div>
            </slot>

            <slot v-if="stepInfo[0].departments.length > 0">
                <div
                    v-for="(department, id) in stepInfo[0].departments"
                    :key="id"
                    class="message-container-text message-container-gray"
                >
                    <BaseSpectators :id="department.id" type="department" :entityName="entityName"/>
                </div>
            </slot>
        </slot>
    </div>
</template>

<script>
import BaseSpectators from "@/components/chat/message_system/ui/BaseSpectators";

export default {
    name: "BaseSteps",
    components: {BaseSpectators},
    props: {
        step: Object,
        allSteps: Array,
        entityName: String,
    },
    data() {
        return {
            stepInfo: null
        }
    },
    mounted() {
        this.stepInfo = this.allSteps.filter(item => {
            return item.id === this.step.step_id
        })
    }
}
</script>

<style scoped>

</style>