import {TaskAttachment, TaskComment} from "@/shared/api";
import {User} from "@/shared/api";
import {binarySearch} from "@/helpers/binarySearch";
import globalStore from "@/store";

/**
 * Возвращает все сообщения вместе с пользователем
 *
 * @param {object} messages Массив сообщений из task
 * @param {object} users Массив пользователей, который идет из store
 * @param {boolean} showSystem Показывать системные сообщения (по умолчание не показывать)
 */

export const filter_messages = async (messages: TaskComment[], users: User[], showSystem = false) => {
    let systemMessageCount = 0
    // Удаляет системные сообщения
    messages.filter(item => {
        if (item.type === 'system') {
            systemMessageCount++
        }
    })


    const filteredUsers = users.filter(user => user.type === 'user').sort((a, b) => a.id - b.id)

    messages.map(item => {
        return binarySearch(filteredUsers, item.author_id)
    })


    if (!showSystem) {
        const filterMessage = messages.filter(item => {
            if (item.type !== 'system') {
                return item
            }
        })

        return {messages: filterMessage, systemMessageCount, allMessage: messages}
    }

    return {messages, systemMessageCount, allMessage: messages}
}

/**
 * Возвращает измененное сообщение где вместо id информация о пользователе
 *
 * @param {object} message  Сообщение из task
 * @param {object} users Массив пользователей, который идет из store
 */
export const getUserById = async (message: TaskComment, users: User[]): Promise<User | number> => {
    const filteredUsers = users.filter(user => (user.type === 'user' || user.type === 'bot')).sort((a, b) => a.id - b.id)

    return binarySearch(filteredUsers, message.author_id) as User
}

/**
 * Возвращает информация о пользователе по его id
 *
 * @param {number} id id пользователя
 */
export const getUserInfoById = async (id: string) => {
    const currentUser = globalStore.getters.getUserData as User

    if (currentUser.id.toString() === id) {
        return currentUser
    }


}

export const getAttachmentById = (id: number) => {
    const attachments = globalStore.getters.getAllAttachments as TaskAttachment[];
    return attachments.find(x => x.id === id);
}


export const validateLastSetReadSendByTimestamp = (timestamp: number | null): boolean => {
    if (!timestamp) return true

    const date = new Date().getTime()

    if (date - timestamp >= 2000) {
        return true
    }

    return false
}