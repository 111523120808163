import {createCabinetProp, createCabinetSuccess} from "@/components/tracker/model/interface";
import {UtilsWidget} from "@/components/tracker/model/utils";
import {APIFitter} from "@/components/tracker/model/api/fitter";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import {isCreateCabinetSuccess, validateCabinetData} from "@/components/tracker/model/core/methods/createLoginFunc";
import {
    getClientClass, getRegionId,
    getWhereToSend, isAllFieldsFilled, isHostCorrect,
    validateGenerateLogin
} from "@/components/tracker/model/core/methods/generateLoginFunc";



export async function createCabinetByLoginHandler(api: any) {
    const hostFromTask = await CatalogInteraction.getHostValueFromTask(api.task);

    if (hostFromTask) {
        api.userData.host = hostFromTask
        api.userData.host_fullName = hostFromTask
    }

    api.widgetStatusActive = api.widgetStatus.createByLogin
}

export async function generateCabinetHandler(api: any) {
    const hostFromTask = await CatalogInteraction.getHostValueFromTask(api.task);

    if (hostFromTask) {
        api.userData.host = hostFromTask
        api.userData.host_fullName = hostFromTask
    }

    // Получение класса клиента и региона
    const bodyDataLogin = await CatalogInteraction.getDataForGenerateLogin(api)

    // Проверка на обязательное поле регион
    if (bodyDataLogin.region) {
        api.userData.region = bodyDataLogin.region
    }

    // Проверка на обязательное поле класс клиента
    if (bodyDataLogin.clientClass) {
        api.userData.client_class = bodyDataLogin.clientClass
    }

    api.widgetStatusActive = api.widgetStatus.generateLogin
}


export async function createCabinetUsingLogin(api: any) {
    let loginFromTask = await CatalogInteraction.getLoginValueFromTask(api);
    // Переводит систему из общего вида к сокращенному
    const host = UtilsWidget.getHostByValue(api.userData.host_fullName)

    if (host) {
        api.userData.host = host
    }

    const taskId = api.task.id
    const hostName = api.userData.host
    const login = loginFromTask.toString().trim()

    const errorMessage = validateCabinetData(hostName, loginFromTask)

    if (errorMessage) {
        return api.createCabinetByLogin.errorMessage = errorMessage;
    }


    const res = await APIFitter.createCabinetAPI(api, login, hostName, taskId) as unknown as createCabinetSuccess

    const isCreateSuccess = isCreateCabinetSuccess(res)

    if (isCreateSuccess) {
        api.userData.user_id = isCreateSuccess

        // Переход на следующий этап
        api.createCabinetByLogin.errorMessage = ""
        api.widgetStatusActive = api.widgetStatus.getCabinetObject

        await api.getUserObjects()
    } else {
        if (res.body && res.body.data) {
            return api.createCabinetByLogin.errorMessage = res.body.data.message
        } else {
            return api.createCabinetByLogin.errorMessage = 'Ошибка'
        }

    }

    return
}


export async function createCabinetUsingGenerator(api: any) {
    const correctHost = ['ГЛОНАССSoft', 'Wialon Local 2']

    const isCorrectSelectedHost = isHostCorrect(correctHost, api.userData.host_fullName)

    if (isCorrectSelectedHost) {
        // Убираю текст об ошибке
        api.createCabinetBeGenerate.errorMessage = ""
        // Переводит систему из общего вида к сокращенному
        const host = UtilsWidget.getHostByValue(api.userData.host_fullName)

        if (host) {
            api.userData.host = host
        }


        const clientClass = api.userData.client_class
        const login_method = api.userData.login_method
        const region = api.userData.region;
        const phone = api.userData.phone
        const contact = api.userData.contact
        const taskId = api.task.id
        const hostName = api.userData.host

        if (isAllFieldsFilled(taskId, hostName, clientClass, region, phone, contact, login_method)) {

            api.userData.login_method = login_method
            api.userData.client_class = clientClass
            api.userData.region = region
            api.userData.phone = phone
            api.userData.contact = contact

            const numberClientClass = getClientClass(clientClass)

            const whereToSend = getWhereToSend(login_method)

            const region_id = getRegionId(region)

            const body = {
                host: hostName,
                create_by: taskId.toString(),
                data_for_login: {
                    client_class: numberClientClass,
                    contact: contact, //url task/289
                    region: Number(region_id)
                },
                login_method: whereToSend,
                login_method_value: contact // Поле контакт
            }

            //Запрос на создание к серверу
            const response = await APIFitter.createCabinetAPIWithoutLogin(api, body as unknown as createCabinetProp)

            const isAccountCreateWithError = validateGenerateLogin(response)

            if (isAccountCreateWithError) {
                return api.createCabinetBeGenerate.errorMessage = isAccountCreateWithError
            }

            api.userData.user_id = response.body.data.data.id

            // Переход на следующий этап
            api.createCabinetBeGenerate.errorMessage = ""
            api.widgetStatusActive = api.widgetStatus.getCabinetObject

            await api.getUserObjects()

        } else {
            api.createCabinetBeGenerate.errorMessage = "Не все обязательные поля заполнены"
        }

    } else {
        api.createCabinetBeGenerate.errorMessage = "Выберите другую систему"
    }

}