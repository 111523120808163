import {getRequest} from "@/utils/http-request";
import globalStore from "@/store";
import { CatalogDataType } from "@/shared/api";

export class APIRulse {

    static async getAllCatalogApi(api: any) {
        const isCatalogHeader = globalStore.getters.getCatalogsHeader

        if (isCatalogHeader && isCatalogHeader.length > 0) {
            return api.allCatalogs = isCatalogHeader
        }

        try {
            const res = await getRequest(`${api.RADAR_DOMAIN}/catalog/`, {}, {
                'Authorization': api.RADAR_TOKEN
            }, false);

            if (res.status === 200) {
                await globalStore.dispatch("setCatalogHeader", res.body)
                api.allCatalogs = res.body
            }

        } catch (err) {
            console.error(err)
        }
    }

    static async getCatalogValues(api: any, catalog_id: number) {
        const isCatalogInStore = globalStore.getters.getCatalogs.find((catalog: CatalogDataType) => catalog.id === catalog_id)
        if (isCatalogInStore && Object.values(isCatalogInStore).length !== 0) {
            await globalStore.dispatch("changeCatalog", isCatalogInStore);
            return {
                status: 200,
                body: isCatalogInStore,
            }
        }

        try {
            const res = await getRequest(`${api.RADAR_DOMAIN}/catalog/${catalog_id}`, {}, {
                'Authorization': api.RADAR_TOKEN
            }, false)

            if (res.status === 200) {
                await globalStore.dispatch("changeCatalog", res.body);
            }
        } catch (err) {
            console.log(err)
        }
    }

}