import {unit} from "@/components/tracker/model/interface";
import {isColumnLayoutCorrect} from "@/components/tracker/model/calibration/validate";


export function confirmCalibration(api: any, unit: unit, editOnlyText = true) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    // Проверка, правильно ли заполнены поля таблицы (не перепутаны ли местами столбцы и нет ли опечаток в правом столбце)
    if (!isColumnLayoutCorrect(unit)) {
        calibrationInfo.calibrationRewritePopover = false
        return;
    }

    // Закрывает модалку с вопросом о перезаипси тарировки

    calibrationInfo.calibrationRewritePopover = false
    calibrationInfo.calibrationEditOnlyText = editOnlyText
    calibrationInfo.calibrationConfirmPopover = true;

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

// Открывает модалку что бы изменить только информацию о тарировке
export function changeAdditionCalibrationData(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationRewritePopover = true;

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

export function closeCalibrationPopover(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationConfirmPopover = false
    calibrationInfo.calibrationConfirmPopoverError = ''

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

export function closeRewriteCalibrationPopover(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationRewritePopover = false

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

export function closeClearCalibrationPopover(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationConfirmClearPopover = false

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}


export function closeSwapCalibrationPopover(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationSwapPopover = false

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

export function closeCalibrationFilePopover(api: any, unit: unit) {
     unit.calibrationConfirmFilePopover = false

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}


export function openPopupBeforeClearCalibration(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationConfirmClearPopover = true

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}


export function openConfirmPopoverAndCloseSwapPopover(api: any, unit: unit) {
    const calibrationInfo = getCalibrationInfo(unit)
    if (!calibrationInfo) return

    calibrationInfo.calibrationSwapPopover = false

    calibrationInfo.calibrationConfirmPopover = true;

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}

function getCalibrationInfo(unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    return calibrationInfo
}