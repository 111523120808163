import {unit} from "@/components/tracker/model/interface";
import {APIFitter} from "@/components/tracker/model/api/fitter";

export class Sim {

    static async activateSim(api: any, phone: number, index: number) {
        if (phone) {

            const parent = (api.unitsData.inGsAndInTable[index] as unknown as unit)

            if (parent.state) {

                if (!parent.state.simIsActiveClick) {
                    parent.state.simIsLoading = true

                    const res = await APIFitter.checkSimAPI(api, phone.toString())

                    if (res.body.status === 'success') {
                        console.log('widget: sim activate success')

                        parent.state.simIsActiveClick = true
                        parent.state.simQueryStatus = 'success'
                    } else {
                        console.log('widget: sim activate fail')

                        parent.state.simQueryStatus = 'error'
                    }

                    parent.state.simIsLoading = false

                }
                api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
            }
        }
    }
}