import {HeaderAxios, ResponseAxios, TaskDataType} from "@/shared/api";
import globalStore from "@/store";
import {validateLastSetReadSendByTimestamp} from "@/utils/filter_messages";

let axios = require('axios')


export const setReadComment = async (task_id: number, message_id: number) => {
    if (!Number.isInteger(message_id)) return

    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    if (!task_id || !message_id) {
        console.warn('set-read comment empty value')
        return
    }

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const timestamp = globalStore.getters.getLastSetReadTimestamp

    const permissionToSendRequest = validateLastSetReadSendByTimestamp(timestamp)
    await globalStore.dispatch('changeLastSetReadTimestamp', new Date().getTime())

    if (!permissionToSendRequest) {
        await sleep(2000);
    }

    await globalStore.dispatch('changeTaskState', {id: task_id, is_read: false})

    headers['Authorization'] = `Token ${token}`
    return await axios({
        method: 'POST',
        data: {},
        url: `${process.env.VUE_APP_TASK_RADAR}/task/${task_id}/comments/${message_id}/set-read`,
        withCredentials: true,
        headers
    })
        .then((resp: any) => {
            if (resp.status === 200) {
                return resp.data as TaskDataType[]
            } else {
                console.error(`Не удалось прочитать сообщения`)
            }
        })
        .catch((err: any) => {
            return err.response
        })

}
