// Сохранение объекта в localStorage
import {CalibrationLS} from "@/components/tracker/model/interface";

export function saveObjectToLocalStorage(key: string, obj: CalibrationLS) {
    // Преобразование объекта в строку JSON и сохранение в localStorage
    const jsonString = JSON.stringify(obj);
    localStorage.setItem(key, jsonString);
}

// Получение объекта из localStorage
export function getArrayFromLocalStorage(key: string) {
    // Получение строки из localStorage и преобразование её в объект
    const storedItem = localStorage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : [];
}

export function getObjectFromLocalStorage(key: string, task_id: number, id: string, calibrationId: number): CalibrationLS | undefined {
    const existingArr = getArrayFromLocalStorage(key);
    return existingArr.find((item: CalibrationLS) => {
        if (item.id === id && task_id === item.task_id && item.calibrationIndex === calibrationId) {
            return item
        }
    });
}

// Редактирование объекта в localStorage
export function saveObjectToArrayInLocalStorage(key: string, task_id: number, obj: CalibrationLS) {
    const existingArr = getArrayFromLocalStorage(key);
    const index = existingArr.findIndex((item: CalibrationLS) => {
        if (item.id === obj.id && task_id === item.task_id && item.calibrationIndex === obj.calibrationIndex) {
            return item
        }
    });

    if (index !== -1) {
        // Если объект с таким id уже существует, заменяем его
        existingArr[index] = obj;
    } else {
        // Если объекта с таким id нет, добавляем новый объект
        existingArr.push(obj);
    }

    saveObjectToLocalStorage(key, existingArr);
}

// Функция для удаления объекта из массива объектов в localStorage по id
export function removeObjectFromArrayInLocalStorage(key: string, id: string, calibrationIndex: number) {
    const existingArr = getArrayFromLocalStorage(key);
    const index = existingArr.findIndex((item: CalibrationLS) => item.id === id && item.calibrationIndex === calibrationIndex);

    if (index !== -1) {
        // Если объект с таким id найден, удаляем его из массива
        existingArr.splice(index, 1);
        saveObjectToLocalStorage(key, existingArr);
    }
}