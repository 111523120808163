import {HeaderAxios, ResponseAxios, CatalogDataType} from "@/shared/api";

let axios = require('axios')

export const getCatalogById = (catalog_id: number) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }


    headers['Authorization'] = `Token ${token}`

	return axios({
		method: 'GET',
		url: `${process.env.VUE_APP_TASK_RADAR}/catalog/${catalog_id}`,
		withCredentials: true,
        headers: headers
	})
		.then((resp: any) => {
			if (resp.status === 200) {
				return resp.data as CatalogDataType
			} else {
				console.error(`Каталог ${catalog_id} не найден`)
			}
		})
		.catch((err: any) => {
			return err.response
		})

}
