export function isHostCorrect(correctHosts: string[], host: string) {
    return correctHosts.includes(host);
}

export function isAllFieldsFilled(taskId: number, hostName: string, clientClass: string, region: string, phone: string, contact: string, login_method: string) {
    return !!(taskId &&
        hostName &&
        clientClass &&
        region &&
        phone && phone.trim() &&
        contact && contact.trim() &&
        login_method);
}

export function getClientClass(clientClass: string) {
    switch (clientClass.toUpperCase()) {
        case "TOP":
            return  1;
        case "A":
            return  2;
        case "B":
            return  3;
        case "C":
            return  4;
    }
}

export function getWhereToSend(login_method: string) {
    switch (login_method) {
        case "По почте":
            return 'email'
        case "По телефону":
            return 'sms'
        case "По WhatsApp":
            return 'wtsp'
    }
}

export function getRegionId(region: string) {
    const region_id = region.split(', ')[1]

    if (region_id.toString() === '172') {
        return '2'
    }

    return region_id
}

export function validateGenerateLogin(response: any) {
    if (response.status === 500 || response.body?.data?.code === 500) {
        return "Произошла ошибка при создании кабинета"
    }

    if (response.body?.status === 'error') {
        return "Ошибка в формате введенных данных"
    }

    if (response.body?.status === 'fail') {
        return response.body.data?.message
    }

    return null
}