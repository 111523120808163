import {CalibrationLS, calibrationValues, TaskDataType, unit} from "@/components/tracker/model/interface";
import {removeObjectFromArrayInLocalStorage, saveObjectToArrayInLocalStorage} from "@/components/tracker/model/storage";
import {CALIBRATION_KEY} from "@/components/tracker/model/const";
import {createObject} from "@/components/tracker/model/helpers";
import {setCalibrationToDefaultValue} from "@/components/tracker/model/calibration/init";


export function updateLocalStorageWhenInput(api: any, unit: unit, task: TaskDataType) {
    if (api.debounceTimer) {
        clearTimeout(api.debounceTimer);
    }

    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    api.debounceTimer = setTimeout(() => {
        if (!calibrationInfo.calibrationValue) return

        saveCalibrationValueInLs(unit, calibrationInfo.calibrationValue, task)
    }, 500);
}

export function saveCalibrationValueInLs(unit: unit, calibration: calibrationValues[], task: TaskDataType) {
    const date = new Date();
    const timestamp = date.getTime()

    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    const calibration_ls = createObject({
        id: unit.unit.unit_id.toString(),
        task_id: task.id,
        value: calibration,
        created_at: timestamp,
        calibrationIndex: activeCalibrationIndex,
        tank_volume: calibrationInfo.calibrationInput.calibrationVolumeTank,
        fuel_serial: calibrationInfo.calibrationInput.calibrationSerialNumber,
        filter: calibrationInfo.calibrationInput.calibrationFilter
    } as CalibrationLS)

    saveObjectToArrayInLocalStorage(CALIBRATION_KEY, task.id, calibration_ls)
}

export function clearCalibrationTable(api: any, unit: unit) {
    setCalibrationToDefaultValue(unit, undefined, unit.activeCalibrationIndex)
    removeObjectFromArrayInLocalStorage(CALIBRATION_KEY, unit.unit.unit_id.toString(), unit.activeCalibrationIndex)

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}