let axios = require('axios')
import {HeaderAxios, ResponseAxios} from "@/shared/api";

export const editUserAvatar = (file: FormData, user_id: number) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`

    return axios({
        method: 'POST',
        data: file,
        url: `${process.env.VUE_APP_TASK_RADAR}/users/${user_id}/avatar`,
        withCredentials: true,
        headers
    }).then((resp: any) => {
        if (resp.status === 200) {
            return resp.data
        } else {
            console.error(`Не удалось обновить аватарку`)
        }
    })
        .catch((err: any) => {
            return err.response
        })
}
