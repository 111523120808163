import {HeaderAxios, ResponseAxios, TaskDataType} from "@/shared/api";
import globalStore from "@/store";

let axios = require('axios')

type Filters = {
    contains_string: any,
    due_date_start: number | null,
    due_date_end: number | null,
    form_id: string[],
    is_closed: string,
    my_task_bool: boolean,
}


export const findTasks = (data: Filters) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    // Добавляю параметры, которые должны быть доступны в url
    let inputUrl = new URL(`${process.env.VUE_APP_TASK_RADAR}/task/`);

    if (data.contains_string && data.contains_string.length < 3) {
        return []
    }

    if (data.form_id.length > 0) {
        inputUrl.searchParams.append('form_ids', data.form_id.toString());
    }

    if (data.contains_string !== null && data.contains_string !== "") {
        inputUrl.searchParams.append('contains_string', data.contains_string)
    }

    if (data.due_date_start) {
        inputUrl.searchParams.append('due_date_start', String(Math.floor(data.due_date_start / 1000)))
    }

    if (data.due_date_end) {
        inputUrl.searchParams.append('due_date_end', String(Math.floor(data.due_date_end / 1000)))
    }

    if (data.is_closed !== null) {
        inputUrl.searchParams.append('is_closed', data.is_closed)
    }

    if (data.my_task_bool) {
        inputUrl.searchParams.append('participant_user_ids', globalStore.getters.getCurrentUserId)
    }

    inputUrl.searchParams.append('max_items', '300')

    inputUrl.searchParams.append('sort_type', '2')
    inputUrl.searchParams.append('sort_ascending', '0')



    headers['Authorization'] = `Token ${token}`
    return axios({
        method: 'GET',
        url: inputUrl.href,
        withCredentials: true,
        headers
    })
        .then((resp: any) => {
            if (resp.status === 200) {
                return resp.data as TaskDataType[]
            } else {
                console.error(`Не удалось найти задачи`)
            }
        })
        .catch((err: any) => {
            return err.response
        })

}
