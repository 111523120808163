import {FieldsTypeTask} from "@/components/tracker/model/interface";


export function validateCarCountField(carCountField: FieldsTypeTask[] | undefined, tableLength: number) {
    if (!carCountField) {
        return "Не удалось найти поле Кол-во авто"
    }

    let carCountValue = 0
    if (carCountField && carCountField.length > 0) {
        if (carCountField[0].value) {
            carCountValue = Number(carCountField[0].value)
        }
    }

    if (!carCountValue) {
        return 'Заполните поле кол-во авто'
    }

    if (tableLength > carCountValue) {
        return 'Количество авто в таблице превышает значение в поле кол-во авто'
    }

    return null
}