<template>
  <div>
      <div v-for="(value, i) in fields" :key="i" class="message-container-text"
           style="white-space: pre-line;">
          Добавлено поле {{ value.name }} <slot v-if="value.value">- {{ value.value }}</slot>
      </div>
  </div>
</template>

<script>


import {findFieldConvertValue, getFormFieldById} from "@/utils/field";

export default {
    name: "baseValues",
    props: {
        newValue: Array,
    },
    data() {
        return {
            fields: [],
        }
    },
    methods: {
        async getField() {
            for (let i = 0; i < this.newValue.length; i++) {
                const field = getFormFieldById(this.newValue[i].field_id);
                const res = JSON.parse(JSON.stringify(field));
                if (res) {
                    res.value = await findFieldConvertValue(this.newValue[i]);
                    this.fields.push(res);
                }
            }
        }
    },
    mounted() {
        this.getField();
    }
}
</script>

<style scoped>

.message-container-text p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
</style>