
import {changeDateToMonth} from "@/utils/time";
import {TaskDataType} from "@/shared/api";
import {defineComponent, PropType} from "vue";
import {saveOpenedTasksList} from "@/utils/storage/storage-task-list-method";
import globalStore from "@/store";
import {task_selected_tab} from "@/constants/constants";
import {STORAGE_KEY} from "@/utils/const";

interface TaskType {
    show: boolean,
    active: boolean,
    tasks: TaskDataType[]
}

export default defineComponent({
    name: "TaskItemList",
    props: {
        value: {
            type: Object as PropType<TaskType>,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        isCardClose(): boolean {
            return this.value.show
        }
    },
    methods: {
        toggleCard() {
            const currentTab = globalStore.getters.getTaskSelectedTab
            const storageKey = currentTab === task_selected_tab.my ? STORAGE_KEY.openTasksList : STORAGE_KEY.openTasksListAll

            saveOpenedTasksList(this.name, storageKey)
            this.value.show = !this.value.show
        },
        changeDateToMonthHandler(dateNumber: string) {
            return changeDateToMonth(dateNumber)
        },
    }
})
