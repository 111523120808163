import {CatalogInteraction} from "@/components/tracker/model/methods";
import {getOptionEquipmentValue} from "@/components/tracker/model/core/methods/checkSensor";


export function getAvailableSensor(api: any) {
    const optionalEquipment = CatalogInteraction.getEquipment(api)

    api.createByHandObject.objectInfo.availableObject = []

    if (optionalEquipment) {
        if (optionalEquipment.length > 0) {

            const equipment = getOptionEquipmentValue(optionalEquipment)
            if (!equipment) return

            api.createByHandObject.objectInfo.availableObject.push(...equipment)
        }
    }
}