import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from 'vue-apexcharts';
// @ts-ignore
import VueMask from 'v-mask'
// @ts-ignore
import vClickOutside from 'v-click-outside'
// @ts-ignore
import  "./registerServiceWorker.js";
import "./app/style/main.css";
import store from "./store";
// @ts-ignore
import VCalendar from 'v-calendar';
// @ts-ignore
import wb from "./registerServiceWorker";
export const eventBus = new Vue()
Vue.prototype.$workbox = wb;


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);
Vue.use(VCalendar);
Vue.use(VueMask);
Vue.use(vClickOutside)

new Vue({
    //@ts-ignore
    VCalendar,
    VueMask,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");