import {APIFitter} from "@/components/tracker/model/api/fitter";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import * as trackerMethod from "@/components/tracker/model/methods"
import {validateCarCountField} from "@/components/tracker/model/core/methods/beforeCreateObject";
import {getAvailableSensor} from "@/components/tracker/model/core/objects/sensorObject";

export async function findCreatedByHandElementIMIE(api: any) {

    if (api.createByHandObject.userImeiOrSerial) {
        api.createByHandObject.objectInfo.isLoading = true
        api.createByHandObject.objectInfo.isFindError = false
        api.createByHandObject.objectInfo.isCreateError = false
        api.createByHandObject.objectInfo.findErrorMessage = 'Ошибка при поиске'

        api.clearCreateByHandObject()

        // Проверяет поле количество авто и если число в нем меньше чем количество машин в таблице, то не позоваляет создать
        if (!api.formWithoutTable.includes(api.task.form_name)) {
            // Проверяет поле количество авто и если число в нем меньше чем количество машин в таблице, то не позоваляет создать
            const carCountField = await trackerMethod.CatalogInteraction.getCarCount(api)
            const tableLength = api.unitsData.allUnitsInTableCount

            const isCarCountError = validateCarCountField(carCountField, tableLength)

            if (isCarCountError) {
                api.createByHandObject.objectInfo.isFindError = true
                api.createByHandObject.objectInfo.findErrorMessage = isCarCountError
                api.createByHandObject.objectInfo.isLoading = false

                return
            }
        }

        const res = await APIFitter.getUnitDataByIMEI(api, api.createByHandObject.userImeiOrSerial)

        if (res.status === 200) {
            if (res.body.status === 'success') {
                api.createByHandObject.tracker_name = res.body.data.name

                api.createByHandObject.imei = res.body.data.imei
                api.createByHandObject.nameObject = api.createByHandObject.userImeiOrSerial
                api.createByHandObject.serial = res.body.data.serial


                getAvailableSensor(api)

                api.select.createInputSensors = true;
            } else {
                api.createByHandObject.objectInfo.isFindError = true
            }
        } else {
            api.createByHandObject.objectInfo.findErrorMessage = 'Объект не найден'
            api.createByHandObject.objectInfo.isFindError = true
        }
        api.createByHandObject.objectInfo.isLoading = false
    } else {
        if (!api.isFitter) return;

        // Если не введен imei или серийник
        if (api.$refs.widget_create_input) {
            (api.$refs.widget_create_input as HTMLElement).style.borderColor = '#EC5555'

            setTimeout(() => {
                if (api.$refs.widget_create_input) {
                    (api.$refs.widget_create_input as HTMLElement).style.borderColor = '#ffffff'
                }
            }, 5000)
        }
    }
}