import {STORAGE_KEY} from "@/utils/const";

interface Identifiable {
    id: number;
}

type StorageKey = keyof typeof STORAGE_KEY;

export class LocalStorageService<T extends Identifiable> {
    private constructor() {}

    getItem(key: StorageKey): T[] {
        const storedItem = localStorage.getItem(key);
        return storedItem ? JSON.parse(storedItem) : [];
    }

    saveItem(key: StorageKey, items: any): void {
        const jsonString = JSON.stringify(items);
        localStorage.setItem(key, jsonString);
    }

    removeItem(key: StorageKey): void {
        localStorage.removeItem(key)
    }

    static getInstance<T extends Identifiable>(): LocalStorageService<T> {
        return new LocalStorageService<T>();
    }

    static mapToLocalStorage(map: any) {
        // Преобразуем Map в объект для корректного преобразования в JSON
        return Object.fromEntries(map);
    }

    static localStorageToMap(tasks: any) {
        return new Map(Object.entries(tasks));
    }

    static getTimestamp(key: keyof typeof STORAGE_KEY, id?: string) {
        const data = LocalStorageService.getInstance().getArray(key) as any
        if (!data) {
            return 0
        }

        if (id) {
            const map = LocalStorageService.localStorageToMap(data)
            const mapData = map.get(id.toString()) as any;

            return mapData?.timestamp || 0;

        } else {
            return data.timestamp || 0;
        }
    }

    saveData(key: StorageKey, data: Partial<Record<string, any>>): void {
        const existingData = this.getItem(key);

        const updatedData = { ...existingData, ...data };

        this.saveItem(key, updatedData);
    }

    rewriteData(key: StorageKey, data: Partial<Record<string, any>>): void {
        this.saveItem(key, data)
    }

    saveArray(key: StorageKey, items: T[]): void {
        this.saveItem(key, items)
    }

    getArray(key: StorageKey): T[] | undefined {
        return this.getItem(key)
    }

    getString(key: StorageKey): T | undefined {
        const storedItem = localStorage.getItem(key);
        return storedItem ? JSON.parse(storedItem) : '';
    }

    removeArray(key: StorageKey): void {
        return this.removeItem(key)
    }
}