import {unit} from "@/components/tracker/model/interface";

export class ObjectMethod {
    static checkIsObjectInTouch(item: unit) {
        const inTouch = item.many?.time

        let lastContact = null

        if (inTouch) {
            lastContact = Date.now() - inTouch
            lastContact = Math.floor(lastContact / 1000 / 60 / 60 / 24); // 300000 - 5 минут
        }

        // Если сим активна и последний выход на связь меньше 15 минут назад
        if (!lastContact) {
            return true
        }

        return lastContact > 90000;
    }
}