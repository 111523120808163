import {SensorUnit} from "@/components/tracker/model/interface";

export function quickSortSensorUnit(sensors: SensorUnit[]): SensorUnit[] {
    if (sensors.length < 2) {
        return sensors;
    }

    const index = Math.floor((sensors.length - 1) / 2)
    const currentItem = sensors[index]

    const less = []
    const more = []

    for (let i = 0; i < sensors.length; i++) {
        if (i === index) {
            continue
        }

        if (Number(sensors[i].param) < Number(currentItem.param)) {
            less.push(sensors[i])
        } else {
            more.push(sensors[i])
        }
    }

    return [
        ...quickSortSensorUnit(less),
        currentItem,
        ...quickSortSensorUnit(more)
    ]
}