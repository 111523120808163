
import {defineComponent, PropType} from "vue";
    export default defineComponent({
        emits: ['togglePassword'],
        props:{
          passwordType: {
              type: String as PropType<'password' | 'text'>,
              required: true,
          }
        },
        methods:{
            clickHandler(){
                if(this.passwordType === 'text'){
                    this.$emit('togglePassword', 'password');
                } else {
                    this.$emit('togglePassword', 'text');
                }
            }
        }
    })
