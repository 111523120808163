import axios from '../config/axios'
import {HeaderAxios, ResponseAxios, SuccessResponseGetTask} from "@/shared/api";
import globalStore from "@/store";
import {
    callNotificationClearTimestamp,
    callNotificationClearTimestampOne,
    callNotUpdateNotification
} from "@/utils/callNotUpdateNotification";
import {saveTaskInLocalStorage} from "@/utils/storage/storage-task-methods";


export class TaskApi {

    static async getTaskById(id: string) {
        let responseObj = {} as SuccessResponseGetTask
        let headers = {} as HeaderAxios

        const token = localStorage.getItem('current_user')

        if (!token) {
            return (responseObj.error = 'Не авторизован')
        }

        headers['Authorization'] = `Token ${token}`

        try {
            if (!globalStore.getters.getOnlineStatus) {
                throw new Error
            }

            const response = await axios.get(`/task/${id}`, {headers})

            if (response.status === 200) {
                saveTaskInLocalStorage(response.data)
                await callNotificationClearTimestampOne('task')

                return {task: response.data}
            }

            await callNotUpdateNotification('task', id)

            responseObj.error = "Не удалось получить задачу"
            responseObj.status_code = response.status

            return responseObj

        } catch (error) {
            responseObj.error = "Не удалось получить задачу"

            await callNotUpdateNotification('task', id)
        }
    }


    static async getTasks(user: string, type = 'my', signal: AbortSignal) {
        let responseObj = {} as ResponseAxios
        let headers = {} as HeaderAxios

        const token = user

        if (!token) {
            console.log('Не авторизован')
            return (responseObj.error = 401)
        }

        headers['Authorization'] = `Token ${token}`

        // Добавляю параметры, которые должны быть доступны в url
        let inputUrl = new URL(`${process.env.VUE_APP_TASK_RADAR}/task/`);


        inputUrl.searchParams.append('sort_type', '1')
        inputUrl.searchParams.append('inbox', '1')
        inputUrl.searchParams.append('sort_ascending', '1')
        inputUrl.searchParams.append('read_status', '1')

        if (type === 'my') {
            inputUrl.searchParams.append('spectator_user_ids', globalStore.getters.getCurrentUserId)
            inputUrl.searchParams.append('is_closed', '0')

            let maxItems = '50'
            if (window.location.hostname === 'localhost' || window.location.hostname === 'ofc-test-01.tspb.su') {
                maxItems = '300'
            }

            inputUrl.searchParams.append('max_items', maxItems)
        } else {
            inputUrl.searchParams.append('max_items', '300')
        }

        try {
            if (!globalStore.getters.getOnlineStatus) {
                throw new Error
            }

            const response = await axios.get(inputUrl.href, {
                headers,
                withCredentials: true,
                signal
            } as any)

            if (response && response.status === 200) {
                await callNotificationClearTimestamp()

                return response.data
            }

            await callNotUpdateNotification('taskList')

            if (response && response.status === 401) {
                responseObj.error = 401
                console.error('Не авторизован')
            } else if (response.status === 404) {
                responseObj.error = 404
                console.error('Ошибка 404')
            } else if (response.status === 204) {
                responseObj.error = 204
                console.error('Нет задач')
            } else {
                responseObj.error = response.status
            }
            return responseObj

        } catch (error) {
            responseObj.error = 204

            await callNotUpdateNotification('taskList')
        }
    }


    static async setReadTask(task_id: string[]) {
        let responseObj = {} as ResponseAxios
        let headers = {} as HeaderAxios

        const token = localStorage.getItem('current_user')

        if (!token) {
            return (responseObj.error = 'Не авторизован')
        }

        headers['Authorization'] = `Token ${token}`

        const body = {
            'tasks_ids': task_id
        }

        try {
            const {data} = await axios.post(`/task/set-read/`, body, {headers})

            if (data) {
                return {resultCode: 0}
            }

            return responseObj.error = "Не удалось прочитать задачу"

        } catch (error) {
            responseObj.error = "Не удалось прочитать задачу"
        }
    }
}