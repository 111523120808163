import {unit} from "@/components/tracker/model/interface";
import {APIFitter} from "@/components/tracker/model/api/fitter";

export class ChangeDOMElementCommon {

    static async blurInputActive(api: any, status: string, tracker_imei: string) {

        if (status === 'name') {

            (api.unitsData.inGsAndInTable as any) = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {

                        if (parent.unit.name === parent.state.inputStatus.nameUnitActiveName) {
                            parent.state.inputStatus.isNameUnitActive = false;
                            return api.unitsData.inGsAndInTable[i]
                        }

                        if (parent.state.inputStatus.nameUnitActiveName && parent.state.inputStatus.nameUnitActiveName.trim().length <= 3) {
                            parent.state.inputStatus.isNameUnitActive = false;
                            return api.unitsData.inGsAndInTable[i]
                        }

                        if (!parent.state.inputStatus.nameUnitActiveName) {
                            parent.state.inputStatus.isNameUnitActive = false;
                            return api.unitsData.inGsAndInTable[i]
                        }

                        api.NAME_IS_CHANGE_UPDATE_TASK = true

                        const nameBefore = JSON.parse(JSON.stringify(parent.unit.name))
                        parent.unit.name = parent.state.inputStatus.nameUnitActiveName
                        parent.state.inputStatus.isNameUnitActive = false;
                        parent.state.inputStatus.isNameChanged = true

                        APIFitter.changeUnitNameAPI(
                            api,
                            parent.tracker.tracker_name,
                            parent.unit.unit_id,
                            parent.state.inputStatus.nameUnitActiveName.trim(),
                            parent.tracker.tracker_serial
                        ).then(res => {
                            if (!res) {
                                parent.unit.name = nameBefore
                            } else {
                                if (parent.state) {
                                    parent.state.inputStatus.isNameChanged = true

                                    setTimeout(() => {
                                        if (parent.state) {
                                            parent.state.inputStatus.isNameChanged = false
                                        }
                                    }, 3000)
                                }
                            }

                            api.NAME_IS_CHANGE_UPDATE_TASK = false
                        })

                        setTimeout(() => {
                            if (parent.state) {
                                parent.state.inputStatus.isNameChanged = false
                            }
                        }, 3000)
                    }

                    return api.unitsData.inGsAndInTable[i]

                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })
        }

        if (status === 'mileage') {

            (api.unitsData.inGsAndInTable as any) = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {

                        if (parent.unit.mileage === parent.state.inputStatus.mileageUniActiveName) {
                            parent.state.inputStatus.isMileageUnitActive = false;
                            return api.unitsData.inGsAndInTable[i]
                        }

                        parent.state.inputStatus.isMileageUnitActive = false

                        if (!isNaN(Math.floor(parent.state.inputStatus.mileageUniActiveName))) {
                            const mileageBefore = JSON.parse(JSON.stringify(parent.unit.mileage))
                            parent.state.inputStatus.mileageUniActiveName = Math.floor(parent.state.inputStatus.mileageUniActiveName);
                            parent.unit.mileage = Math.floor(parent.state.inputStatus.mileageUniActiveName)

                            APIFitter.changeUnitMileageAPI(
                                api,
                                parent.state.inputStatus.mileageUniActiveName.toString(),
                                parent.unit.unit_id,
                                parent.tracker.tracker_name
                            ).then(res => {
                                if (!res) {
                                    parent.unit.mileage = mileageBefore
                                } else {
                                    if (parent.state) {
                                        parent.state.inputStatus.isMileageChanged = true

                                        setTimeout(() => {
                                            if (parent.state) {
                                                parent.state.inputStatus.isMileageChanged = false
                                            }
                                        }, 3000)
                                    }
                                }
                            })


                        } else {
                            parent.state.inputStatus.mileageUniActiveName = parent.unit.mileage
                        }
                    }

                    return api.unitsData.inGsAndInTable[i]
                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })
        }
    }

}