/**
 * @param {Array<String>} words [one, few, many]
 * @param {Number} count
 * @returns {String}
 */
export const plural = (words:string[], count:number):string => {
    let index;
    if (count % 10 === 1 && count % 100 !== 11) {
        index = 0;
    } else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
        index = 1;
    } else {
        index = 2;
    }
    return words[index] || ''
}