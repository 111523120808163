<template>
    <div>
        <div class="message-container-text message-container-title" v-if="newAddedFields.length > 0">Добавленные поля
        </div>
        <div v-for="(value, id) in newAddedFields" :key="`add${id}`" class="message-container-fields message-container-main">
            <div v-if="value.fieldName" class="message-container-text message-container-gray"
                 style="padding: 5px 0;">Поле: {{ value.fieldName }}
            </div>
            <div v-for="(item, idx) in value" :key="`add${id}${idx}`" class="message-container-text message-container-gray">
                <slot v-if="item.name">{{ item.name }} -
                    <slot v-if="item.value">{{ item.value }}</slot>
                    <slot v-else>Пусто</slot>
                </slot>
                <slot v-if="item.name"> в таблице {{ item.field_id + 1 }}</slot>
            </div>
        </div>


        <div class="message-container-text message-container-title" v-if="newEditedFields.length > 0">Измененные поля
        </div>
        <div v-for="(value, id) in newEditedFields" :key="`edit${id}`" class="message-container-fields message-container-main">
            <div v-if="value.fieldName" class="message-container-text message-container-gray"
                 style="padding: 5px 0;">Поле: {{ value.fieldName }}
            </div>
            <div v-for="(item, idx) in value" :key="`edit${id}${idx}`" class="message-container-text message-container-gray">
                <slot v-if="item.name">{{ item.name }} -
                    <slot v-if="item.value">{{ item.value }}</slot>
                    <slot v-else>Пусто</slot>
                </slot>
                <slot v-if="item.old_value"> было {{ item.old_value }}</slot>
                <slot v-if="item.name"> в таблице {{ item.field_id + 1 }}</slot>
            </div>
        </div>


        <div class="message-container-text message-container-title" v-if="newDeleteFields.length > 0">Удаленные поля
        </div>
        <div v-for="(value, id) in newDeleteFields" :key="`delete${id}`" class="message-container-fields message-container-main">
            <div v-if="value.fieldName" class="message-container-text message-container-gray"
                 style="padding: 5px 0;">Поле: {{ value.fieldName }}
            </div>
            <div v-for="(item, idx) in value" :key="`delete${id}${idx}`" class="message-container-text message-container-gray">
                <slot v-if="item.name">{{ item.name }} -
                    <slot v-if="item.value">{{ item.value }}</slot>
                    <slot v-else>Пусто</slot>
                </slot>
                <slot v-if="item.name"> в таблице {{ item.field_id + 1 }}</slot>
            </div>
        </div>
    </div>
</template>

<script>
import {getFormFieldById, timestampToDate, timestampToTime} from "@/utils/field";
import {getCatalogById} from "@/axios_requests/get_catalog";

export default {
    name: "MessageSystemChangeRows",
    props: {
        msg: Object,
        newValue: Array,
        user: String,
        isTitle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            newAddedFields: [],
            newEditedFields: [],
            newDeleteFields: [],
            catalogs: this.$store.getters.getCatalogs,
            dateArr: ['date', 'due_date', 'due_date_time'],
            timestampToDate,
            timestampToTime,
        }
    },
    mounted() {
        this.getField()
    },
    methods: {
        getField() {

            // Показывает добавленные поля в таблицу
            this.showMessageType('added', this.newAddedFields)

            // Показывает отредактированные поля
            this.showMessageType('edited', this.newEditedFields)

            // Показывает удаленные поля
            this.showMessageType('deleted', this.newDeleteFields)
        },

        showMessageType(type, arrForSave) {
            for (let i = 0; i < this.newValue.length; i++) {
                const current = this.newValue[i]

                if (current.values[type].length > 0) {
                    // Получаю эту таблицу по id
                    const res = getFormFieldById(current.field_id)

                    const result = JSON.parse(JSON.stringify(res.values[0].cells))
                    const resultField = []

                    for (let j = 0; j < res.values[0].cells.length; j++) {
                        const currentArrayItem = result[j]

                        for (const item of current.values[type]) {
                            currentArrayItem.value = ''
                            currentArrayItem.old_value = ''
                            currentArrayItem.row_id = ''
                        }
                    }

                    for (let j = 0; j < current.values[type].length; j++) {
                        const currentDeleted = current.values[type][j]

                        for (let k = 0; k < result.length; k++) {
                            if (result[k].id === currentDeleted.field_id) {
                                const newItem = JSON.parse(JSON.stringify(result[k]))

                                let res = null

                                if (type === 'edited') {
                                    res = this.checkType(newItem, currentDeleted, i, 'updated')
                                } else {
                                    res = this.checkType(newItem, currentDeleted, i)
                                }

                                resultField.push(res)
                            }
                        }
                    }
                    arrForSave.push({fieldName: res.name}, resultField)
                }
            }
        },

        checkType(newItem, currentElementType, index, type) {
            if (newItem.type === "catalog") {

                newItem.value = currentElementType.value
                newItem.old_value = currentElementType.old_value
                newItem.field_id = currentElementType.row_id

            } else if (newItem.type === "multi_catalog") {

                currentElementType.value.forEach(item => {
                    newItem.value += item
                })

                if (currentElementType.old_value) {
                    currentElementType.old_value.forEach(item => {
                        newItem.old_value += item
                    })
                }

                newItem.field_id = currentElementType.row_id


            } else if (newItem.type === "checkmark") {
                if (newItem.value === true) {
                    newItem.value = "Нет"
                    newItem.old_value = "Да"
                    newItem.field_id = currentElementType.row_id
                } else {
                    newItem.value = "Да"
                    newItem.old_value = "Нет"
                    newItem.field_id = currentElementType.row_id
                }
            } else if (this.dateArr.includes(newItem.type)) {
                newItem.value += timestampToDate(this.newValue[0].value)
                newItem.field_id = currentElementType.row_id
            } else if (newItem.type === 'time') {
                newItem.value += timestampToTime(this.newValue[0].value)
                newItem.field_id = currentElementType.row_id
            } else {
                newItem.value = currentElementType.value
                newItem.field_id = currentElementType.row_id
                if (type === 'updated') {
                    newItem.old_value = currentElementType.old_value
                }
            }

            return newItem
        }
    }
}
</script>

<style scoped>
.message-container-text {
    word-break: break-word;
}
</style>