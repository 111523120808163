<template>
    <div>
        <div class="message-container-info">
            <div class="message-status" :class="type ? type : null">
                <p>{{ title }}</p>
            </div>
        </div>
        <div class="message-container-text">
            <p style="white-space: pre-line">{{ text }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "MessageSystemPlaceholder",
    props: {
        msg: Object,
        title: String,
        text: String,
        iconPath: String,
        type: String,
        oldStep: Number,
        newStep: Number,
    },
    data() {
        return {

        }

    },
}
</script>

<style scoped>

</style>