
import {defineComponent} from "vue";
import PdfPreview from "@/components/chat/message_type/file/helpers/PdfPreview.vue";
import TextPreview from "@/components/chat/message_type/file/helpers/TextPreview.vue";
import CommonFile from "@/components/chat/message_type/file/helpers/CommonFile.vue";
import {getFileName} from "@/utils/message";

export default defineComponent({
    name: "MessageFile",
    components: {CommonFile, TextPreview, PdfPreview},
    props: {
        userMessage: {
            type: Boolean,
            required: true
        },
        file: {
            type: [Number, Object, File],
            required: true
        },
        getMessageById: {
            type: Function,
            required: true
        },
        downloadItem: {
            type: Function,
            required: true
        }
    },
    computed: {
        fileName(): string {
			if (typeof this.file === 'number') {
            	return getFileName(this.getMessageById(this.file))
			}

			return getFileName(this.file.name);
        },
        fileExtension(): string | null {
            if (!this.fileName) return null

            const splited = this.fileName.split('.')
            if (splited.length === 0 || splited.length === 1) return null

            return splited[splited.length - 1]
        },
        fileTextNameColor(): string {
            if (this.userMessage && (this.fileExtension === 'pdf' || this.fileExtension === 'txt')) {
                return 'white'
            }

            return '#009bf1'
        }
    }
})
