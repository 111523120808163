<template>
    <div class="qr-wrapper">
        <p class="error">{{ error }}</p>

        <div
            v-if="!newQR"
            class="qr-old"
        >
            <select name="" id="" class="task-input" v-model="selectedCamera" @change="changeCamera($event)">
                <option value=''>Выберите камеру</option>
                <option v-for="(device, index) in videoDevices" :key="index" :value="device.deviceId">Камера:
                    {{ index }} {{ device.label }}
                </option>
            </select>

            <div class="rounded-video">
                <QrcodeStream
                    :noInput="true"
                    :camera="camera"
                    :track="paintOutline"
                    @decode="onDecode"
                    @init="onInit"
                />
            </div>
            <div class="rounded-video">
                <QrcodeCapture @decode="onDecode" />
            </div>
        </div>
        <div
            v-if="newQR"
            class="qr-new"
        >
            <button
                @click="toggleFlashlight"
                class="qr-new__flashlight"
            >
                Вкл/выкл ФОНАРИК
            </button>

            <video
                class="qr-scanner-video"
                ref="qr-video-stream"
            ></video>
        </div>

        <div class="checkbox checkbox-qr">
            <input class="custom-checkbox" v-model="newQR" type="checkbox" id="color-2" name="color-2" value="red">
            <label for="color-2">Использовать новый QR-считыватель</label>
        </div>

        <button
            @click="closeConnection"
            class="qr-close-connection"
        >
            Закрыть
        </button>

    </div>
</template>
<script>
import QrScanner from 'qr-scanner';
import {QrcodeStream, QrcodeCapture} from "vue-qrcode-reader";

export default {
    components: {
        QrcodeStream,
        QrcodeCapture
    },
    props: {
      close: Boolean,
    },
    data() {
        return {
            error: "",
            camera: "auto",
            videoDevices: [],
            isLoaded: false,
            selectedCamera: '',

            qrScanner: null,

            newQR: false,
        };
    },
    async mounted() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(device => device.kind === 'videoinput');
    },
    watch: {
        newQR: function () {
            if (this.newQR) {
                this.$nextTick(() => {
                    this.qrScanner = new QrScanner(
                        this.$refs['qr-video-stream'],
                        result => {
                            this.onDecode(result['data']);
                        },
                        {
                            highlightScanRegion: true
                        });
                    this.qrScanner.start();
                    this.qrScanner.setInversionMode('botn');
                });
            } else {
                this.qrScanner.destroy();
                this.qrScanner = null;
            }
        },
        close: function (newValue) {
            if (newValue) {
                this.qrScanner.close()
            }
        },
    },
    methods: {
        onDecode(result) {
            if (result.length !== 15) {
                this.$emit("decode", result);
            }
        },
        toggleFlashlight() {
            this.qrScanner.toggleFlash(); // toggle the flash if supported; async.
        },
        paintOutline(detectedCodes, ctx) {
            console.log(detectedCodes, ctx);
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const {x, y} of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },

        closeConnection() {
            this.$emit('closeQrConnection')
        },

        async onInit(promise) {
            try {
                await promise;
                this.onLoadedHandler()
            } catch (error) {
                switch (error.name) {
                    case "NotAllowedError":
                        this.error = "ERROR: you need to grant camera access permisson";
                        break;
                    case "NotFoundError":
                        this.error = "ERROR: no camera on this device";
                        break;
                    case "NotSupportedError":
                        this.error = "ERROR: secure context required (HTTPS, localhost)";
                        break;
                    case "NotReadableError":
                        this.error = "ERROR: is the camera already in use?";
                        break;
                    case "OverconstrainedError":
                        this.error = "ERROR: installed cameras are not suitable";
                        break;
                    case "StreamApiNotSupportedError":
                        this.error = "ERROR: Stream API is not supported in this browser";
                        break;
                }
            }
        },
        onLoadedHandler() {
            this.isLoaded ? null : (localStorage.getItem('lastSelectedCamera') ? this.uploadPastCamera() : null);

        },

        uploadPastCamera() {
            this.isLoaded = true;

            for (let i = 0; i < this.videoDevices.length; i++) {
                if (this.videoDevices[i].label === localStorage.getItem('lastSelectedCamera')) {

                    this.selectedCamera = this.videoDevices[i].deviceId;
                    const constraints = {audio: false, video: {deviceId: this.selectedCamera}}

                    let camera = document.getElementsByTagName('video')
                    const stream = camera[0].srcObject;
                    const tracks = stream.getTracks();


                    tracks.forEach(function (track) {
                        track.stop();
                    })

                    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                        camera[0].srcObject = stream;
                    }).catch(function (error) {
                        console.log('Ошибка при выборе камеры', error)
                    })

                }
            }

        },
        changeCamera(e) {
            for (let i = 0; i < this.videoDevices.length; i++) {
                if (this.videoDevices[i].deviceId === e.target.value) {
                    localStorage.setItem('lastSelectedCamera', this.videoDevices[i].label);
                }
            }
            // this.qrScanner.setCamera(e.target.value);
            const constraints = {audio: false, video: {deviceId: e.target.value}}
            let camera = document.getElementsByTagName('video')
            const stream = camera[0].srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            })

            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                camera[0].srcObject = stream;
            }).catch(function (error) {
                console.log('Ошибка при выборе камеры', error)
            })
        }
    }
};
</script>

<style scoped>
.qr-wrapper {
    display: flex;
    flex-direction: column;
}

.qr-new {
    display: flex;
    flex-direction: column;
}

.rounded-video {
    border-radius: 10px;
    overflow: hidden; /* Обязательно используйте это свойство, чтобы скругление углов работало */
}

.qr-scanner-video {
    width: 100%;
    height: 100%;
    display: block;

    border-radius: 6px;
}

.custom-checkbox+label {
    flex-direction: row;
    width: fit-content;
}

.checkbox-qr {
    padding-top: 8px;
}

.task-input {
    width: calc(100vw - 32px);
    margin-bottom: 8px;

    background-color: white !important;
}

.qr-new__flashlight {
    margin-left: auto;
    margin-bottom: 8px;
    padding: 8px 12px;

    background: var(--primary-light);
    border-radius: 4px;
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    border: none;
}

.qr-close-connection {
    margin-left: auto;
    margin-top: 8px;
    padding: 8px 12px;

    background: var(--red-light);
    border-radius: 4px;
    color: var(--red);
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    border: none;
}
</style>
