<template>
    <pdf src="guide__fitter_mobile.pdf" :page="1">
        <template slot="loading">
           Загрузка...
        </template>
    </pdf>

</template>

<script>
import pdf from 'pdfvuer'

export default {
    name: "Guide",
    components: {
        pdf
    },
    data() {
        return {
            showTaskPart: 'Инструкция',
        }
    },
    mounted() {
        const header = document.querySelector('.burger-header')
        if (header) {
            header.textContent = this.showTaskPart
        }

        this.showTaskPart = 'Инструкция'
    },
    destroyed() {
        const header = document.querySelector('.burger-header')
        if (header) {
            header.textContent = 'Список задач'
        }
    },
}
</script>

<style scoped>
.guide {
    padding: 20px;

    margin: 50px auto 0;
}

h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-bottom: 12px;
}

svg {
    width: 100%;
}

h2 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-top: 32px;
    margin-bottom: 16px;
}


</style>