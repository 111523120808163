<template>
    <div class="wrapper">

        <slot v-if="chatError">
            <NotFoundError title="Не удалось загрузить сообщение"
                           description="Произошла ошибка при загрузке сообщений"/>
        </slot>

        <slot v-else>
            <div class="message__system-message" @click="hideOrShowSystemMessage">
                {{
                    $store.getters.getIsShowSystemMessage
                        ? 'Скрыть системные сообщения'
                        : 'Показать системные сообщения'
                }}
            </div>
            <Preloader v-if="isLoading"/>
            <div v-else id="body_chat" class="body_chat" ref="body_chat"
                 :style="`padding-bottom: ${acceptButtonPadding + replyMessagePaddingValue + taskClosedPadding + 90}px`"
            >
                <div class="observer" ref="observer"></div>
                <slot v-if="messageLoading">
                    <Preloader/>
                </slot>

                <div class="message__background" v-if="selectedImage" @click="closeImageFullSize">
                    <svg @click="closeImageFullSize" width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L1 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1 1L13 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <img @click.stop class="message__background--image" :src="selectedImage" alt="">
                </div>

                <div v-for="(comment, index) in messages" :ref="`container${comment.id}`" :key="index"
                     class="message_by_day" :class="`message_by_day_${comment.id}`">
                    <slot v-if="comment.type !== 'system'">
                        <div
                            v-if="
                      index === 0 ||
                      getDateFromUnixTime(comment['created_at']) !== getDateFromUnixTime(messages.slice()[index - 1]['created_at'])
                "
                            class="message_date"
                        >
                            <p v-if="comment['created_at']">
                                {{ getDateFromUnixTime(comment['created_at']) }}
                            </p>
                        </div>
                    </slot>

                    <slot
                        v-if="!$store.getters.getIsShowSystemMessage && (comment.type === 'system' && comment.id > firstUnreadComment || comment.type !== 'system')">
                        <Message :step="step" :key="comment.id" :allUsers="allUsers" :message="comment"
                                 :lastMessage="lastMessage"
                                 :replyMessageHandler="replyMessageHandler" :editMessageHandler="editMessageHandler"
                                 :taskId="task.id"
                                 @openImageFullSize="openImageFullSize" @blurFilter="blurFilter"
                                 :bigMessage="bigMessage"/>
                    </slot>

                    <slot v-if="$store.getters.getIsShowSystemMessage">
                        <Message :step="step" :key="comment.id" :allUsers="allUsers" :message="comment"
                                 :lastMessage="lastMessage"
                                 :replyMessageHandler="replyMessageHandler" :editMessageHandler="editMessageHandler"
                                 :taskId="task.id"
                                 @openImageFullSize="openImageFullSize" @blurFilter="blurFilter"
                                 :bigMessage="bigMessage"/>
                    </slot>
                </div>

                <div class="message_by_day" @click="changePopoverStatus(true)" v-if="readUsers.length > 0">
                    <div class="message_watch_by">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.23407 11.8299L8.40407 14.9999L14.7553 8.65991" stroke="#333333"
                                  stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19.5159 8.65991L13.1647 14.9999L11.1149 12.9501" stroke="#333333"
                                  stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <MessageWatch :userId="readUsers[0]"/>

                        <slot v-if="userReadInfo.length > 1">
                            &nbsp;и еще {{ userReadInfo.length - 1 }}
                        </slot>

                    </div>
                </div>

            </div>

            <Popup :active="popoverActive" @setActive="changePopoverStatus">
                <div class="popup_header">
                    <h2 class="popup_header__title">Просмотрено</h2>
                    <div @click="changePopoverStatus(false)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#F6F6F6"/>
                            <path d="M7.625 16.7639L16.7639 7.625" stroke="#999999" stroke-width="1.33657"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.7639 16.7639L7.625 7.625" stroke="#999999" stroke-width="1.33657"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>

                </div>
                <div class="popup_content">
                    <slot v-for="(user, index) in userReadInfo">

                        <div class="mention_block__select"
                             @click="selectUserForMentionHandler(user)"
                             :key="`user_${user.id}_${index}`">

                            <slot v-if="user.avatar_url === null">
                                <img src="../assets/img/Avatar/anon.png" class="mention_avatar" alt="">
                            </slot>
                            <slot v-else>
                                <img :src="`${urlForDownload(user.avatar_url, 64)}`" class="mention_avatar"
                                     alt="">
                            </slot>

                            <MessagePopoverUser :user="user"/>

                        </div>

                    </slot>
                </div>
            </Popup>

            <div class="scroll_to_bottom"
                 :style="$store.getters.getMessageVisibility ? 'opacity: 0; visibility: hidden;' : 'opacity: 1; visibility: visible'"
                 @click="scrollToBottom">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                          stroke="#009bf1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                <div v-if="$store.getters.getNewMessageCount" class="scroll__message--count">
                    {{ $store.getters.getNewMessageCount }}
                </div>
            </div>

            <div class="not_viewd" ref="not_viewd"></div>

            <slot v-if="!isTaskClosed">
                <div v-if="replyMessage" class="message_reply" ref="message_reply"
                     :style="taskStatusAccept ? 'bottom: 100px' : 'bottom: 152px'">
                    <MessageAnswer :replyMessage="replyMessage" :resetReply="resetReply"/>
                </div>
                <div v-if="taskStatusAccept === false" class="message_accept"
                     :style="`bottom: ${inputBlockHeight + 99 - 46}px`">
                    <button @click="switchStatus" class="message_accept-button">Принять задачу</button>
                </div>

                <div>
                    <MessageInput :isTaskClosed="isTaskClosed" :resetReply="resetReply" :replyMessage="replyMessage"
                                  :id="task.id" :accepted="taskStatusAccept" class="message_input"
                                  :isPlaceholderVisible="isPlaceholderVisible" @placeHolderHandler="placeHolderHandler"
                                  @onBlur="onBlur" :msgText="msgText" @clearInput="clearInput"
                                  @updateRef="updateRef" @updateMsgText="updateMsgText"
                    />

                    <transition name="fade">

                        <div
                            class="mention_block"
                            v-show="isShowMention"
                            :style="taskStatusAccept ? `bottom: ${inputBlockHeight + 100 - 46}px` : `bottom: ${inputBlockHeight + 158 - 47}px`"
                        >

                            <slot v-for="(user, index) in allMembersFilter">

                                <button type="button" class="mention_block__select"
                                        @click="selectUserForMentionHandler(user)"
                                        :key="`user_${user.id}_${index}`">

                                    <slot v-if="urlForDownload(user.avatar_url, 64) === ''">
                                        <slot v-if="user.type === 'user' || user.type === 'bot'">
                                            <img src="../assets/img/Avatar/anon.png" class="mention_avatar" alt="">
                                        </slot>
                                        <slot v-if="user.type === 'department'">
                                            <img src="../assets/img/Avatar/anon.png" class="mention_avatar" alt="">
                                        </slot>
                                    </slot>
                                    <slot v-else>
                                        <img :src="`${urlForDownload(user.avatar_url, 64)}`" class="mention_avatar"
                                             alt="">
                                    </slot>

                                    <div class="mention_block__flex">
                                        <div class="mention_block__name">
                                            {{ user.name }}
                                            {{ user.surname }}
                                        </div>
                                        <div class="mention_block__dep" v-if="user.department_name">
                                            {{
                                                user.department_name
                                            }}
                                        </div>
                                    </div>

                                </button>

                            </slot>
                        </div>
                    </transition>
                </div>

            </slot>

        </slot>


    </div>
</template>

<script>
import Message from '@/components/chat/Message.vue'
import MessageInput from '@/components/chat/MessageInput.vue'
import {CONSTANT_Arr_Months} from '@/constants/constants'
import {getDate} from '@/utils/time.ts'
import Preloader from '@/shared/ui/preloader'
import MessageAnswer from "@/components/chat/message_reply/MessageAnswer";
import {setReadComment} from "@/axios_requests/post_comment_read.ts";
import {getBotById, getDepartmentById, getUserById, getUserOrBotById} from "@/store/interaction/userInteraction";
import {urlForDownload} from '@/utils/misc';
import {addTaskObserver} from "@/axios_requests/post_observer_task";
import Popup from "@/shared/ui/popup/Popup";
import MessageWatch from "@/components/chat/ui/MessageWatch";
import MessagePopoverUser from "@/components/chat/ui/MessagePopoverUser";
import NotFoundError from "@/shared/ui/error/NotFoundError";
import {ChatApi} from "@/shared/api/request/chat-api";
import {getAttachmentsFromMessage} from "@/utils/message";
import {getUnsentCommentsFromMap} from "@/utils/storage/unsent-comment-method";

export default {
    name: 'Chat',
    components: {
        MessagePopoverUser,
        MessageWatch,
        Popup,
        MessageAnswer,
        Message,
        MessageInput,
        Preloader,
        NotFoundError
    },
    props: {
        task: {
            type: Object,
            required: true
        },
        allUsers: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean
        },
        isTaskClosed: {
            type: Boolean
        },
        step: {
            type: Array
        },
        getMessages: {
            type: Function
        },
        taskStatusProps: {
            type: Object
        },
        spectators: {
            type: Object
        },
        addSpectatorsPermission: {
            type: Boolean
        },
        readUsers: {
            type: Array,
            required: true
        },
        approveUserAndDep: {
            type: Object,
            required: true
        },
        chatError: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        return {
            urlForDownload,
            getDepartmentById,
            getUserById,
        }
    },
    data() {
        return {
            text: null,
            msgInput: null,
            messageText: '',
            selectedImage: '',
            replyMessage: null,
            messages: this.$store.getters.getPartMessages,
            firstUnreadComment: this.$store.getters.getFirstUnreadComment,
            replyMessageId: "",
            messageLoading: false,
            scrollHeight: this.$store.getters.getCurrentBodyHeight,
            prevScrollHeight: 0,
            mounted: false,
            showSystemMessage: this.$store.getters.getIsShowSystemMessage,
            taskStatusBlock: false,
            replyMessagePaddingValue: 0,

            taskSpectators: this.$props.spectators,

            selectionUsers: [...this.$store.getters.getAllUsers],
            selectionDepartments: [...this.$store.getters.getDepartments],
            selectionBots: [...this.$store.getters.getBots],

            mentionDepartmentName: ['Отдел исполнения', 'Технический отдел', 'Отдел Разработки'],

            allMembers: [],
            allMembersFilter: [],

            findString: '',

            isShowMention: false,
            isPlaceholderVisible: true,

            msgText: '',
            inputBlockHeight: 46,

            userReadInfo: [],
            popoverActive: false,

            editMessage: '',
            bigMessage: false,
            lastMessage: false,
        }
    },
    computed: {
        taskStatus() {
            return this.taskStatusProps
        },
        taskStatusAccept() {
            if (this.taskStatus && this.taskStatus.hasOwnProperty('accepted')) {
                return this.taskStatus.accepted
            }
        },
        taskClosedPadding() {
            if (this.isTaskClosed) {
                return 50
            } else {
                return 30
            }
        },
        acceptButtonPadding() {
            if (this.taskStatusAccept === false) {
                return 60
            } else {
                return 0
            }
        },

    },
    watch: {
        readUsers: {
            handler(newValue) {
                this.userReadInfo = this.getUserByIdHandler([...new Set(newValue)])
            }
        },
        '$store.getters.getPartMessages'() {
            this.messages = this.$store.getters.getPartMessages
        },
        msgText() {
            this.inputBlockHeight = this.msgInput.offsetHeight
        },
        '$store.getters.getAttachmentsMessage'() {
            setTimeout(() => {
                this.inputBlockHeight = this.msgInput.offsetHeight
            }, 0)
        },
        replyMessage() {
            if (this.replyMessage) {
                this.$nextTick(() => {
                    if (this.$refs.message_reply.offsetHeight) {
                        this.replyMessagePaddingValue = this.$refs.message_reply.offsetHeight
                    }
                });
            } else {
                this.replyMessagePaddingValue = 0
            }
        },

        isLoading: {
            immediate: true,
            handler() {
                setTimeout(() => {
                    const content = this.$refs.body_chat
                    if (content) {
                        content.scrollIntoView({block: 'end'})

                        if (this.messages.length) {
                            // Получаю id последнего сообщения и делаю его прочитанным
                            const id = this.messages[this.messages.length - 1].id

                            if (id && this.$store.getters.getFirstUnreadComment) {
                                this.$store.dispatch('changeNewMessageCount', 0)

                                setTimeout(() => {
                                    if (id > this.$store.getters.getFirstUnreadComment) {
                                        console.log('set read 500')
                                        setReadComment(this.task.id, id);
                                    }
                                }, 500)
                            }
                        }
                    }
                }, 0)
            }
        },
        mounted() {
            let options = {
                rootMargin: "0px",
                threshold: 0,
            };

            // Новые сообщения при скролле
            const callback = async (entries, observer) => {
                if (entries[0].isIntersecting) {

                    //Получение новых сообщений из стейта
                    const allMessages = this.$store.getters.getAllMessages
                    const storeId = this.$store.getters.getLastLoadMessageId

                    const container = this.$refs.body_chat;
                    const currentScrollHeight = container.scrollHeight;


                    for (let i = 0; i < allMessages.length; i++) {
                        const msg = allMessages[i]

                        if (msg.id === storeId) {
                            // Начиная с id storeId взять getLimit сообщений в меньшую сторону не учитывая системные

                            let counter = 0, partArr = [];
                            const allMessagesReverse = [...allMessages];
                            for (let i = 0; i < allMessagesReverse.length; i++) {

                                if (allMessagesReverse[i].id < msg.id) {

                                    if (counter <= this.$store.getters.getLimit) {
                                        const currentMessage = allMessagesReverse[i]

                                        if (currentMessage.type !== 'system') {
                                            counter++
                                        }

                                        partArr.push(currentMessage)
                                    }
                                }
                            }

                            const partMessages = partArr.reverse()

                            if (partMessages && partMessages.length > 0) {
                                const isExist = partMessages.find(item => item.id === storeId && partMessages.length < this.$store.getters.getLimit)
                                if (!isExist) {
                                    this.$store.dispatch('addNewMessagesPart', partMessages)
                                    this.$store.dispatch('changeLastLoadMessageId', partMessages[0].id)
                                }
                            }

                        }
                    }


                    await this.$nextTick(); // Дождаться обновления DOM


                    // Сохранить позицию прокрутки
                    window.scrollTo({
                        top: container.scrollHeight - currentScrollHeight,
                    });


                }
            }

            const callbackNotViewed = (entries) => {
                if (entries[0].isIntersecting) {
                    // Видно
                    if (this.$store.getters.getNewMessageCount !== 0) {
                        if (this.messages && this.messages.length && this.messages[this.messages.length - 1].id) {
                            if (Number.isInteger(this.messages[this.messages.length - 1].id)) {
                                console.log('set read not viewed')
                                setReadComment(this.task.id, this.messages[this.messages.length - 1].id);
                            }
                        }
                    }

                    this.$store.dispatch('changeMessageVisibility', true)
                    this.$store.dispatch('changeNewMessageCount', 0)

                } else {
                    this.$store.dispatch('changeMessageVisibility', false)
                }
            }

            if (this.chatError) return

            const observer = new IntersectionObserver(callback, options);
            const observerNotShow = new IntersectionObserver(callbackNotViewed, options);

            observer.observe(this.$refs.observer)
            observerNotShow.observe(this.$refs.not_viewd)
        }
    },
    mounted() {
        this.$emit('changeHeader', 'Чат')
        this.$store.dispatch('changeActiveTab', 'chat')

        if (!this.isLoading) {
            if (this.$refs.body_chat && this.$refs.body_chat.scrollHeight) {
                this.$store.dispatch('changeCurrentBodyHeight', this.$refs.body_chat.scrollHeight)
            }
        }

        this.scrollTop();

        this.text = document.querySelector('#message-input')
        this.msgInput = document.querySelector('#input-message-body')
        this.addUserForMention()

        this.userReadInfo = this.getUserByIdHandler([...new Set(this.readUsers)])

        this.mounted = true

        document.addEventListener('click', this.removeSelectFromMessage)
    },
    destroyed() {
        if (this.messages && this.messages.length) {
            const maxIdItem = this.messages.reduce((accumulator, current) => {
                return accumulator.id > current.id ? accumulator : current;
            });

            this.$store.dispatch('changeFirstUnreadComment', maxIdItem.id)
        }

        this.$store.dispatch('changeActiveTab', 'task')
        this.$store.dispatch('changeSelectedMessage', null)

        document.removeEventListener('click', this.removeSelectFromMessage)
    },
    methods: {
        async switchStatus() {
            if (!this.taskStatusBlock) {
                const data = {
                    accepted: !this.taskStatus.accepted,
                    accepted_user: Number(this.$store.getters.getCurrentUserId),
                }

                this.taskStatusBlock = true
                this.$emit('updateTaskStatusHandler', data)
                this.taskStatusBlock = false

                this.taskStatus.accepted = !this.taskStatus.accepted
            }
        },
        removeSelectFromMessage() {
            this.$store.dispatch('changeSelectedMessage', null)
            this.blurFilter('remove')
        },
        blurFilter(type) {
            // Находим все элементы, которые мы хотим размыть, включая body
            const elementsToBlur = document.querySelectorAll('.message_by_day');
            // Получаем ссылки на элементы
            const elementToExclude = document.querySelector(`.message_by_day_${this.$store.getters.getSelectedMessage}`);

            // Применяем размытие ко всем элементам, кроме исключенного
            elementsToBlur.forEach(element => {
                if (element !== elementToExclude) {
                    if (type === 'add') {
                        element.classList.add('blur-filter');
                    } else {
                        element.classList.remove('blur-filter');
                    }
                } else {
                    this.bigMessage = false
                    this.lastMessage = false

                    if (element.offsetHeight < 250) {
                        element.scrollIntoView({block: "center", behavior: 'smooth'});
                    } else {
                        if (this.$store.getters.getMessageVisibility) {
                            this.lastMessage = true
                        } else {
                            this.bigMessage = true
                            element.scrollIntoView({block: "center", behavior: 'smooth'});
                        }

                    }
                }
            });
        },
        getUserByIdHandler(idArr) {
            return idArr.map(user => {
                if (user) {
                    let userInfo = getUserById(user)
                    if (!userInfo) {
                        userInfo = getBotById(user)
                    }

                    if (userInfo) return userInfo
                }
            })
        },
        getIdDepartmentByName(departments, departmentName) {
            if (departments.length <= 0) return []

            const departmentLowerCase = departmentName.map(el => el.toLowerCase())

            return departments
                .filter(el => departmentLowerCase.includes(el.name.toLowerCase()))
                .map(el => el.id)
        },
        changePopoverStatus(status) {
            this.popoverActive = status
        },
        placeHolderHandler() {
            this.text.focus()
        },
        onBlur() {
            if (this.text && this.text.innerHTML === '') {
                this.isPlaceholderVisible = true
            }
        },
        updateRef() {
            if (this.text && this.isPlaceholderVisible
            ) {
                this.isPlaceholderVisible = false
                this.text.innerHTML = ''
            }
        },
        clearInput() {
            this.text.innerHTML = ''
            this.msgText = ''
            this.$emit('clearReadUsers')
            this.userReadInfo.length = 0
        },
        updateMsgText() {
            setTimeout(() => {
                this.isPlaceholderVisible = false

                // Если удалили все данные из строки
                if (this.text && this.text.innerHTML === '') {

                    this.isShowMention = false
                    this.isPlaceholderVisible = true

                    this.msgText = ''
                    this.text.innerHTML = ''
                }

                if (this.text) {
                    // После очистки текста с упоминанием, остается тег
                    if (this.text.innerHTML.includes('<font color="#3d7aa5">')) {
                        this.text.innerHTML = this.text.innerHTML.replace('<font color="#3d7aa5">', '')

                        this.setCaretAtEnd(this.text)
                    }

                    const charPenult = this.getCharacterPrecedingCaret(this.text, 'penultimate')

                    if (this.text.innerHTML.length === 1) {
                        if (charPenult === '@') {
                            this.isShowMention = true
                        }
                    }


                    this.findString = this.getCharacterAfterCommercialAt(this.text)

                    if (this.findString) {
                        this.allMembersFilter = this.allMembers.filter(item => {
                            // Если нужен поиск по отделу пользователя
                            // ('department_name' in item) && item.department_name.toLowerCase().includes(this.findString.toLowerCase())
                            return (
                                item.name.toLowerCase().includes(this.findString.toLowerCase())
                            )
                        })
                    } else {
                        this.allMembersFilter = this.allMembers
                    }
                }

                this.msgText = this.text ? this.text.innerHTML || '' : '';

            }, 200)

        },
        // Ищет что ввел пользователь после @
        getCharacterAfterCommercialAt(containerEl) {
            let precedingChar = "", sel, range, precedingRange, resultString = "";
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel && sel.rangeCount > 0) {
                    range = sel.getRangeAt(0).cloneRange();
                    range.collapse(true);
                    range.setStart(containerEl, 0);

                    const splitBy = range.toString().split("@")

                    if (splitBy.length > 1) {
                        const string = splitBy[splitBy.length - 1]
                        if (!string.includes(' ')) {
                            this.isShowMention = true

                            return string
                        } else {
                            this.isShowMention = false
                            this.allMembersFilter = this.allMembers
                        }
                    } else {
                        this.isShowMention = false
                        this.allMembersFilter = this.allMembers
                    }
                }

            } else if ((sel = document.selection) && sel.type != "Control") {
                range = sel.createRange();
                precedingRange = range.duplicate();
                precedingRange.moveToElementText(containerEl);
                precedingRange.setEndPoint("EndToStart", range);

                const splitBy = precedingRange.text.toString().split("@")

                if (splitBy.length > 1) {
                    const string = splitBy[splitBy.length - 1]
                    if (!string.includes(' ')) {
                        this.isShowMention = true

                        return string
                    } else {
                        this.isShowMention = false
                        this.allMembersFilter = this.allMembers
                    }
                } else {
                    this.isShowMention = false
                    this.allMembersFilter = this.allMembers
                }
            }

            return resultString;
        },
        selectUserForMentionHandler(user) {
            setTimeout(async () => {
                if (this.text) {
                    this.isShowMention = false
                    this.text.focus()
                    this.pasteHtmlAtCaret(`span1 ${user.name} span2`)

                    await this.updateMsgText();

                    const replaceSpan = this.text.innerHTML.split(' ').map(item => {
                        if (item.includes('@span1') || item.includes('span1')) {
                            return `<span contenteditable="false" class="message_mention" style="color: #3d7aa5" data-user="${user.id}">`
                        }

                        if (item.includes('span2')) {
                            return '</span>'
                        }

                        return item
                    })

                    replaceSpan.push('&nbsp;')
                    this.text.innerHTML = replaceSpan.join(' ')
                    this.text.innerHTML = this.text.innerHTML.replaceAll('   ', ' ')
                    this.text.innerHTML = this.text.innerHTML.replaceAll(' &nbsp;', '&nbsp;')

                    this.goBack()
                }
            }, 0)
        },
        // Получает последний и предпоследний символ (что бы проверить показывать пользователей после подсказки или нет)
        getCharacterPrecedingCaret(containerEl, char) {
            let precedingChar = "", sel, range, precedingRange, resultString;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel && sel.rangeCount > 0) {
                    range = sel.getRangeAt(0).cloneRange();
                    range.collapse(true);
                    range.setStart(containerEl, 0);
                    precedingChar = range.toString().slice(-2);

                    resultString = char === 'last' ? precedingChar[1] : precedingChar[0];
                }

            } else if ((sel = document.selection) && sel.type != "Control") {
                range = sel.createRange();
                precedingRange = range.duplicate();
                precedingRange.moveToElementText(containerEl);
                precedingRange.setEndPoint("EndToStart", range);
                precedingChar = precedingRange.text.slice(-2);

                resultString = char === 'last' ? precedingChar[1] : precedingChar[0];
            }

            return resultString;
        },
        // Переставляет курсов в конец строки
        setCaretAtEnd(node) {
            const range = document.createRange();
            range.selectNodeContents(node);
            range.collapse(false);
            const sel = window.getSelection();
            if (!sel) return

            sel.removeAllRanges();
            sel.addRange(range);
        },
        goToMentionProfile(dataUser, type, selectionArr, link) {
            if (dataUser.includes(type)) {
                const id = dataUser.split(type + '_')[1]
                if (id) {
                    selectionArr.forEach(user => {
                        if (user.id.toString() === id.toString()) {
                            this.$router.push({name: link, params: {id}})
                        }
                    })
                }
            }
        },
        getMention() {
            const mentions = document.querySelectorAll('.message_mention')
            mentions.forEach(mention => {
                mention.addEventListener('click', () => {
                    const dataUser = mention.dataset.user

                    if (!dataUser) return

                    this.goToMentionProfile(dataUser, 'user', this.selectionUsers, "Profile")
                    this.goToMentionProfile(dataUser, 'department', this.selectionDepartments, "Department")
                    this.goToMentionProfile(dataUser, 'bot', this.selectionBots, "Bot")
                })
            })
        },
        pasteHtmlAtCaret(html) {
            var sel, range;
            if (window.getSelection) {
                // IE9 and non-IE
                sel = window.getSelection();
                if (!sel) return

                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();

                    // Range.createContextualFragment() would be useful here but is
                    // non-standard and not supported in all browsers (IE9, for one)
                    var el = document.createElement("div");
                    el.innerHTML = html;
                    var frag = document.createDocumentFragment(), node, lastNode;
                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node);
                    }
                    range.insertNode(frag);

                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }
            } else if (document.selection && document.selection.type != "Control") {
                document.selection.createRange().pasteHTML(html);
            }
        },
        goBack() {
            if (!this.text) return

            const range = document.createRange();
            range.selectNodeContents(this.text);
            range.collapse(false);
            const sel = window.getSelection();

            if (!sel) return;

            sel.removeAllRanges();
            sel.addRange(range);
        },
        async addUserByMention() {
            if (this.addSpectatorsPermission) {
                // Получаю data-user и достаю оттуда id пользователей
                // Получаю data-user из строки, которые содержат id пользователя
                const dataUserId = this.msgText.split(' ').filter(item => {
                    if (item.includes('data-user')) {
                        return item
                    }
                })

                const userToAdd = dataUserId.map(item => item.slice(11).slice(0, -2))

                // Если строка содержит user то добавляем пользователя, если bot то тоже пользователя и так далее (user, department)
                for (const item of userToAdd) {
                    // Проверка есть ли пользователь в наблюдателях
                    if (item.includes('user') || item.includes('bot')) {
                        const id = item.split('_')[item.split('_').length - 1]
                        if (!id) return

                        const isExist = this.taskSpectators.users.some(item => item.id === Number(id))

                        if (!isExist) {
                            await addTaskObserver(this.task.id, Number(id), 'user')
                        }

                    }
                }
            }
        },
        filterUserForMention() {
            // Получаю id отделов, что бы забрать только ОИ и ТО. Также исключить тех кто в Админах, Разработке, отключенные пользователи и боты
            // Только для всех кто в сервисном отделе
            const FITTER = "Сервисный Отдел"
            // const FITTER = "Монтажники"
            const departments = this.$store.getters.getDepartments;

            const fitterDep = departments.find(dep => dep.name === FITTER)

            if (!fitterDep) {
                console.log("Не удалось найти сервисный отдел")

                return
            }

            const currentUserData = this.$store.getters.getUserData;

            if (!currentUserData || !currentUserData.department_ids.includes(fitterDep.id)) {
                console.log("Упоминание пользователей доступно только сервисному отделу")
                return
            }

            const OI = "Отдел исполнения"
            const TO = "Технический отдел"

            let OI_id = null, TO_id = null;

            const userForAdd = []

            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];

                if ([OI, TO].includes(department.name)) {
                    if (department.name === OI) {
                        OI_id = department.id
                    }

                    if (department.name === TO) {
                        TO_id = department.id
                    }

                    userForAdd.push(department.id)
                }
            }

            const ADMIN = "Администраторы"
            const DEV = "Отдел Разработки"

            const userForRemove = []

            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];

                if ([ADMIN, DEV].includes(department.name)) {
                    userForRemove.push(department.id)
                }
            }

            const usersTO = []
            const usersOI = []
            const AlexandraBerezina = []
            const otherUsers = []

            for (let i = 0; i < this.allUsers.length; i++) {
                const currentUser = this.allUsers[i]

                // Добавление Александры Березины
                if (currentUser.info.name === "Александра" && currentUser.info.surname === "Березина") {
                    AlexandraBerezina.push(currentUser);
                }

                // Проверка, если пользователь состоит в то или ои и что он не заблокирован и пользователь
                if (userForAdd.some(user => currentUser.department_ids.includes(user)) &&
                    !userForRemove.some(user => currentUser.department_ids.includes(user)) &&
                    currentUser.info.name !== "Александра" && currentUser.info.surname !== "Березина" &&
                    currentUser.enabled &&
                    currentUser.type === 'user'
                ) {
                    if (currentUser.department_ids.includes(OI_id)) {
                        usersOI.push(currentUser)
                    } else if (currentUser.department_ids.includes(TO_id)) {
                        usersTO.push(currentUser)
                    } else {
                        otherUsers.push(currentUser);
                    }
                }
            }

            const users = [...usersOI, ...AlexandraBerezina, ...usersTO, ...otherUsers]

            const readyUsers = []
            for (let i = 0; i < users.length; i++) {
                const currentUser = users[i]

                const name = currentUser.info.surname ? currentUser.info.name + ' ' + currentUser.info.surname : currentUser.info.name
                const obj = {
                    id: `user_${currentUser.id}`,
                    name: name,
                    type: 'user',
                    avatar_url: currentUser.avatar_url ?? "",
                }

                readyUsers.push(obj)
            }

            return readyUsers;
        },
        addUserForMention() {
            const users = []

            // Преобразуем уникальные id обратно в массив объектов
            this.taskSpectators.departments = this.getIdDepartmentByName(this.selectionDepartments, this.mentionDepartmentName)

            if (this.taskSpectators.departments && this.taskSpectators.departments.length > 0) {
                for (let i = 0; i < this.taskSpectators.departments.length; i++) {
                    const currentDepartment = this.taskSpectators.departments[i]
                    const department = getDepartmentById(currentDepartment)

                    if (!department) return

                    const obj = {
                        id: `department_${department.id}`,
                        name: department.name,
                        type: 'department',
                        avatar_url: department.avatar_url ?? "",
                    }

                    users.unshift(obj)
                }
            }

            const usersForMention = this.filterUserForMention()

            this.allMembers.length = 0
            this.allMembers.push(...users);
            this.allMembersFilter.push(...users);

            if (usersForMention && usersForMention.length > 0) {
                this.allMembers.push(...usersForMention)
                this.allMembersFilter.push(...usersForMention)
            }
        },
        getDateFromUnixTime(time) {
            let timeDMY = getDate(time),
                day = timeDMY.dateTime.dayTime,
                month = timeDMY.dateTime.monthTime

            return `${day} ${CONSTANT_Arr_Months[month - 1]}`
        },
        openImageFullSize(url) {
            this.selectedImage = url
            // document.documentElement.style.overflow = 'hidden'
        },
        closeImageFullSize() {
            this.selectedImage = ""
            // document.documentElement.style.overflow = 'scroll'
        },
        replyMessageHandler(id) {
            const message = this.messages.find(item => item.id === id)

            if (message) {
                this.replyMessage = message
                this.replyMessageId = message.id

                this.$refs.body_chat.scrollIntoView({block: "end", behavior: "smooth"})
            }
        },
        cancelEditMode() {
            this.editMessage = ''
            this.text.innerHTML = ''
            this.msgText = ''

            this.$store.dispatch('changeEditMode', false)
            this.$store.dispatch('addMessageInfoBeforeEdit', null)
        },
        resetReply() {
            if (this.$store.getters.getEditMode) {
                this.cancelEditMode()
            }

            this.replyMessageId = ""
            this.replyMessage = null
        },
        editMessageHandler(id) {
            const message = this.messages.find(item => item.id === id)

            if (message) {
                this.editMessage = message;
                this.replyMessage = message;

                const messageAttachments = getAttachmentsFromMessage(this.$store.getters.getAllAttachments, message.attachments)

                this.$store.dispatch('addFileInAttachments', messageAttachments)

                this.$store.dispatch('addMessageInfoBeforeEdit', JSON.parse(JSON.stringify(message)))

                let prevMessageFromArr = ""

                this.isPlaceholderVisible = false

                const textArr = this.editMessage.text.split('/blockquote>')
                if (textArr.length === 1) {
                    prevMessageFromArr = textArr[0]
                } else {
                    prevMessageFromArr = textArr[textArr.length - 1]
                }

                this.text.innerHTML = prevMessageFromArr
                this.msgText = prevMessageFromArr

                this.$refs.body_chat.scrollIntoView({block: "end", behavior: "smooth"})
            }
        },
        hideOrShowSystemMessage() {
            this.$store.dispatch('changeIsShowMessageSystem', !this.$store.getters.getIsShowSystemMessage)
        },
        scrollToBottom() {
            // Смотрит сколько на странице сейчас не системных сообщений и если их много, то делает быстрый скролл
            let notSystemMessageCount = 0
            this.$store.getters.getPartMessages.forEach(msg => {
                if (msg.type === 'system') {
                    notSystemMessageCount += 1
                }
            })

            this.$refs.body_chat.scrollIntoView({block: "end", behavior: "smooth"})
        },
        scrollTop() {
            // Определение высоты окна просмотра
            const viewportHeight = window.innerHeight;

            // Определение высоты документа
            const documentHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );

            // Прокрутка страницы вниз
            window.scrollTo({
                top: documentHeight - viewportHeight,
            });
        }
    }
}
</script>

<style scoped>
.wrapper {
    padding-top: 10px;
}

.show-more {
    border: 1px solid grey;
    border-radius: 5px;
    width: 150px;
    padding: 5px 10px;
    margin: 0 auto 10px auto;
}

.message_by_day {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    padding: 0 20px;
}

@media (max-width: 376px) {
    .message_by_day {
        padding: 0 14px;
    }
}

.message_watch_by {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 12px;

    width: 100%;

    color: var(--black, #333);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.message_date {
    margin: 12px 0 4px 0;

    padding: 6px 10px;

    color: #9F9F9F;
    font-size: 12px;
    font-weight: 500;

    border-radius: 6px;
    background-color: white;
}

.message_input {
    position: fixed;
    min-height: 45px;
    bottom: 54px;
    left: 0;
    width: 100%;
}

.message__background {
    width: 100%;
    height: 100%;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px;

    background-color: rgba(0, 0, 0, 0.75);

    z-index: 1000;
}

.message__background img {
    width: 100%;
    height: auto;
}

.message__background svg {
    position: absolute;
    top: 60px;
    right: 20px;

    width: 20px;
    height: 20px;
}

.message_reply {
    position: fixed;
}

.message_accept {
    position: fixed;
    bottom: 99px;

    background-color: white;
    width: 100%;

    padding: 8px 10px;

    border-bottom: 1px solid var(--divider-color);
}

.message_accept-button {
    width: 100%;

    padding: 12px 16px;

    border-radius: 6px;
    background: rgba(0, 155, 241, 0.11);
    border: none;

    color: #009BF1;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.observer {
    height: 30px;
}

.message__system-message {
    position: fixed;
    top: 47px;

    width: 100%;

    padding: 10px 12px;

    background-color: white;
    color: var(--primary-color);

    filter: drop-shadow(0px 4px 8px rgba(36, 38, 41, 0.1));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    font-size: 14px;
    z-index: 98;
    text-align: center;
}

.not_viewd {
    height: 1px;

    position: relative;
    top: -60px;

    opacity: 0;
    visibility: hidden;
}

.scroll_to_bottom {
    position: fixed;
    bottom: 165px;
    right: 12px;

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(36, 38, 41, 0.10);
    border-radius: 50%;

    z-index: 97;

    transition: opacity .3s ease;
}

.scroll_to_bottom svg {
    width: 20px;
    height: 20px;
}

.scroll__message--count {
    position: absolute;
    top: -10px;
    right: -8px;

    min-width: 20px;

    text-align: center;

    padding: 4px;

    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;

    font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.mention_block__select:not(:last-child) {
    padding-bottom: 12px;

    border-bottom: 1px solid var(--divider-color);
}

.mention_block__select:not(:first-child) {
    padding-top: 12px;
}

.mention_block__name {
    color: var(--black, #333);
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mention_block__dep {
    color: #9F9F9F;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
</style>
