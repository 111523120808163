import {CabinetData, TaskDataType} from "@/components/tracker/model/interface";
import {HOST_MAP} from "@/components/tracker/model/const";


export function selectCabinet(loginsFromApi: CabinetData[], loginFromTask: string, host: string) {
    if (loginsFromApi.length > 1) {
        const login = loginsFromApi.find(cabinet => {
            return cabinet.login.trim() === loginFromTask.trim() && (HOST_MAP as any)[cabinet.host].includes(host)
        });

        if (login) {
            return login
        } else {
            return loginsFromApi[0];
        }
    }
    return loginsFromApi[0];
}