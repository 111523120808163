import {CatalogTracker, FieldsTypeTask} from "@/components/tracker/model/interface";


export function getSelectedObject(tableValue: FieldsTypeTask[][], selectedItem: string): FieldsTypeTask[] | undefined {
    return tableValue.find((item: FieldsTypeTask[]) => item.some((el: FieldsTypeTask) => {
        const value = el.value ? el.value.toString().trim() : '';
        return (
            (el.name === 'IMEI' ||
                el.name === 'Номер блока' ||
                el.name === 'S/N new' ||
                el.name === 'S/N old' ||
                el.name === 'S/N') &&
            value === selectedItem
        );
    }));
}


export function addSensorFromTableToObject(arrayWithSensor: string[], objectInfo: CatalogTracker[], unitsName: {[key: string]: string }) {

    for (const id of arrayWithSensor) {
        const matchedObject = objectInfo.find(obj => obj.value[0] === id);

        if (matchedObject) {
            const valuesName = matchedObject.value[0];

            if (unitsName[valuesName]) {
                const param = {} as { type?: string, count?: number };

                if (unitsName[valuesName] === 'fuel_multiply') {
                    param.count = 2;
                }

                return {
                    name: unitsName[valuesName],
                    param,
                };
            }
        }
    }
}




// Добавление unit_name и number при выборе объекта из таблицы
const isSNField = (fieldName: string) => fieldName === 'S/N new' || fieldName === 'S/N';
const isIMEIField = (fieldName: string) => fieldName === 'IMEI' || fieldName === 'Номер блока';

export function setNumberAndUnitName(fieldValue: string[] | number[] | string | number | undefined) {
    if (fieldValue) {
        const number = fieldValue ? fieldValue.toString() : '';
        const unit_name = number;

        return {
            unit_name,
            number
        }
    }

}


export const shouldSetNumberAndUnitName = (fieldName: string, fieldValue: string[] | number[] | string | number) => {
    if (isSNField(fieldName) || isIMEIField(fieldName)) {
        return setNumberAndUnitName(fieldValue);
    }
};
