
import {defineComponent} from "vue";
import {notificationStatus} from "@/utils/const";


export default defineComponent({
    name: "VNotification",
    props: {
        status: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        type: {
            type: Number,
            required: true
        },
        link: {
            type: Number
        }
    },
    emits: ['hideNotification'],
    data() {
        return {
            notificationType: notificationStatus,
            notificationColor: new Map([
                [notificationStatus.success, "#00BB34"],
                [notificationStatus.connection, "#009bf1"],
                [notificationStatus.error, "#EC5555"],
                [notificationStatus.warning, "#E8A117"],
                [notificationStatus.info, "#009bf1"],
                [notificationStatus.loading, "#009bf1"]
            ])
        }
    },
    computed: {
        getNotificationStyle(): any {
            return {
                visibility: this.status ? 'visible' : 'hidden',
                opacity: this.status ? 1 : 0,
            };
        },
        getNotificationBackground(): {background: string} {
            const status = notificationStatus[notificationStatus.full_fill] === notificationStatus[this.type]
            return {
                background: status ? '#EC5555' : '#FFF'
            }
        },
        getNotificationColor(): {color: string} {
            const status = notificationStatus[notificationStatus.full_fill] === notificationStatus[this.type]
            return {
                color: status ? '#FFF' : '#000'
            }
        }
    },
    methods: {
        goToUrl() {
            this.$router.push({name: 'Task', params: {id: String(this.link)}})
            this.hideNotificationHandler()
        },
        hideNotificationHandler() {
            this.$emit('hideNotification')
        }
    }
})
