export const SelectItemHoldMixin = {
	data() {
		return {
			item: null,
			timerID: null,
			counter: 0,
			pressHoldEvent: new CustomEvent("pressHold"),
			pressHoldDuration: 45,
			selected: false,
		}
	},

	mounted() {
		if (this.id) {
			this.item = document.getElementById(this.id.toString())

			this.item.addEventListener("touchstart", this.pressingDown, false);
			this.item.addEventListener("touchend", this.notPressingDown, false);

			// Listening for our custom pressHold event
			this.item.addEventListener("pressHold", this.doSomething, false);

			this.selected = !!this.selectedIdArr.includes(this.id);
		}

		// Сохраняем ссылки на обработчики событий
		this.touchStartHandler = this.pressingDown.bind(this);
		this.touchEndHandler = this.notPressingDown.bind(this);
		this.pressHoldHandler = this.doSomething.bind(this);

		document.addEventListener('scroll', () => this.counter = 0)
	},

	destroyed() {
		if (this.item) {
			// Отписываемся от событий
			this.item.removeEventListener("touchstart", this.touchStartHandler);
			this.item.removeEventListener("touchend", this.touchEndHandler);
			this.item.removeEventListener("pressHold", this.pressHoldHandler);
		}

		// Отписываемся от события скролла
		document.removeEventListener('scroll', () => this.counter = 0);
	},

	watch: {
		selectedIdArr: {
			handler(newValue) {
				this.selected = !!newValue.includes(this.id);
			},
			deep: true
		},
	},

	methods: {
		pressingDown(e) {
			// Start the timer
			requestAnimationFrame(this.timer);
			console.log("Pressing!");
		},

		notPressingDown(e) {
			// Stop the timer
			cancelAnimationFrame(this.timerID);
			this.counter = 0;

			console.log("Not pressing!");
		},

		timer() {
			console.log("Timer tick!");

			if (this.counter < this.pressHoldDuration) {
				this.timerID = requestAnimationFrame(this.timer);
				this.counter++;
			} else {
				console.log("Press threshold reached!");

				window.navigator?.vibrate?.([50, 200]);

				this.pressThresholdReached(this.id)
				this.item.dispatchEvent(this.pressHoldEvent);
			}
		},

		doSomething(e) {
			console.log("pressHold event fired!");
		},
	}


}