import {APIFitter} from "@/components/tracker/model/api/fitter";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import * as coreService from '@/components/tracker/model/core'
import {UserCabinetStatus} from "@/components/tracker/model/const";
import {selectCabinet} from "@/components/tracker/model/methods/login/cabinet";

export async function getCabinetFromTask(api: any) {
    console.log('widget: get cabinet from task');

    if (api.formWithoutUser.includes(api.task.form_name)) {
        handleCabinetEO(api);
        return;
    }

    const loginFromTask = await CatalogInteraction.getLoginValueFromTask(api);
    await coreService.getUserData(api);

    if (!loginFromTask) {
        handleEmptyLoginField(api);
        return;
    }

    const loginsFromApi = await APIFitter.getCabinetsFromApi(api, loginFromTask.trim(), api.userData.host);

    if (loginsFromApi.length === 0) {
        handleCabinetNotFound(api);
        return;
    }


    const hostCabinet = await CatalogInteraction.getHostValueFromTask(api.task)
    if (!hostCabinet) return

    const selectedCabinet = selectCabinet(loginsFromApi, loginFromTask, hostCabinet);

    updateUserDataAndCabinet(api, selectedCabinet);

    api.isFitter && (api.loading.cabinetLoading = true);
    api.widgetStatusActive = api.widgetStatus.getCabinetObject;

    await api.getUserObjects();
}

function handleCabinetEO(api: any) {
    console.log('widget: cabinet EO');

    api.userData.user_id = api.eo_login;
    api.userData.login = api.eo_login;
    api.userData.host_fullName = 'Local';
    api.userData.host = 'local';

    api.widgetStatusActive = api.widgetStatus.getCabinetObject;
    api.isFitter && (api.loading.cabinetLoading = true);

    api.getUserObjects();
}

function handleEmptyLoginField(api: any) {
    console.log('widget: login field is empty');

    api.isFitter && (api.loading.cabinetLoading = true);
    api.widgetStatusActive = api.widgetStatus.cabinetNotFound;
}

function handleCabinetNotFound(api: any) {
    console.log('widget: cabinet not found');

    api.isFitter && (api.loading.cabinetLoading = true);
    api.widgetStatusActive = api.widgetStatus.cabinetNotFound;
}


function updateUserDataAndCabinet(api: any, selectedCabinet: any) {
    api.userData.user_id = selectedCabinet.id;
    api.userData.login = selectedCabinet.login;
    api.userData.host = selectedCabinet.host;
    api.cabinet = selectedCabinet;

    api.userData.blockStatus = getUserCabinetStatus(selectedCabinet.block_status, selectedCabinet.delete_status)
}

export function getUserCabinetStatus(blockStatus: boolean, deleteStatus: boolean): UserCabinetStatus {
    if (deleteStatus) return 'delete'
    if (blockStatus) return 'block'

    return 'active'
}
