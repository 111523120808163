import {getCacheCatalogById} from "@/store/interaction/catalogInteraction";


/**
 * Получает id каталога на вход и проверяет есть ли он в local storage
 * Если его там нет, то делает запрос на сервер и записывает данные в local storage
 *
 * @param {number} id
 */
export const getCatalogFromCache = (id: number) => {
    const catalogCache = getCacheCatalogById(id);

    if (catalogCache !== undefined && catalogCache !== null) {
        return catalogCache;
    }
}


export const findByCatalogIdElement = async (value: string | number | boolean | string[] | number[]) => {
    // Проверка на multi catalog
    if (!Array.isArray(value)) {
        if (value !== null || true) {
            return value
        }
    } else {
        if (value.length > 0) {
            return value.join(', ');
        }
    }

}