
import {Bot, Department, User} from "@/shared/api";
import {getBotById, getDepartmentById, getUserById} from "@/store/interaction/userInteraction";
import {defineComponent} from "vue";

export default defineComponent({
    name: "BaseSpectators",
    props: {
        type: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true,
        },
        entityName: String,
    },
    data() {
        return {
            user: null as unknown as User,
            userDepartment: {} as Department,
            bot: null as unknown as Bot,
        }
    },
    mounted() {
        if (this.type === 'user') {
            const user = getUserById(this.id);
            if (!user) {
                const user = getBotById(this.id);
                if (!user) {
                    return
                }
                this.bot = user;
                const department = getDepartmentById(this.bot.department_ids[0]);
                if (!department) {
                    return
                }
                this.userDepartment = department;
                return
            }
            this.user = user;
            const department = getDepartmentById(this.user.department_ids[0]);
            if (!department) {
                return
            }
            this.userDepartment = department;
        } else if (this.type === 'department') {
            const department = getDepartmentById(this.id);
            if (!department) {
                return
            }
            this.userDepartment = department;
        }
    },
})
