import { TaskDataType } from "@/shared/api";
import { STORAGE_KEY } from "@/utils/const";
import { LocalStorageService } from "@/utils/storage";
import { hasOneWeekPassed } from "@/utils/time";
import { StorageTaskList } from "@/shared/api/types/storage";

export function getIdsFromTasks(tasks: TaskDataType[]) {
    if (tasks.length === 0) return [];

    return tasks.map(task => task.id)
}

export function updateTaskStorage(tasks: TaskDataType[], allTasks: TaskDataType[], tasks_tab: string) {
    const taskIds = getIdsFromTasks(tasks)
    const timestamp = new Date().getTime()

    return {
        timestamp,
        tasks: allTasks,
        [tasks_tab === 'my' ? 'tasks_id' : 'all_tasks_id']: taskIds,
    };
}


export function updateTaskById(task: TaskDataType) {
    const allTasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList) as StorageTaskList | undefined
    if (!allTasks || !allTasks.tasks || allTasks.tasks.length === 0) return

    allTasks.tasks.map(taskItem => {
        if (taskItem.id === task.id) {
            taskItem.comment = task.comment
        }

        return taskItem
    })

    LocalStorageService.getInstance().saveData(STORAGE_KEY.taskList, allTasks)

}

export function validateByTimestampAndLength(timestamp: number, tasks: number[]): string | number[] {
    // Проверка не устарела ли информация о задачах
    if (!timestamp || !tasks) return "WEEK_PASSED"

    const isValidTimestamp = hasOneWeekPassed(timestamp)

    if (isValidTimestamp) {
        return "WEEK_PASSED"
    }

    if (tasks.length === 0) return "EMPTY_IDS"

    return tasks
}

// Получает на вход массив с задачами и id задач
// Возвращает задачи, у которых id совпали
export function getTasksById(tasks: TaskDataType[], task_ids: number[]): TaskDataType[] | [] {
    return tasks.filter(obj => task_ids.includes(obj.id));
}


export function mergeTwoTaskList(tasks: TaskDataType[], currentTasks: TaskDataType[]) {
    const uniqueObjects = new Map();

    // Обработка первого массива
    tasks.forEach(obj => {
        uniqueObjects.set(obj.id, obj);
    });

    // Обработка второго массива
    currentTasks.forEach(obj => {
        // Проверяем, есть ли уже объект с таким id в массиве tasks
        if (!uniqueObjects.has(obj.id)) {
            // Если объекта с таким id нет, добавляем его в Map
            uniqueObjects.set(obj.id, obj);
        }
    });

    // Преобразование Map обратно в массив объектов
    return [...uniqueObjects.values()];
}


export function checkWhenSwitchTaskTab(timestamp: number, tasks_id: number[]) {
    // Получение задач из кэша
    const validateTask = validateByTimestampAndLength(timestamp, tasks_id)

    if (validateTask === 'WEEK_PASSED') {
        LocalStorageService.getInstance().removeArray(STORAGE_KEY.taskList)
    }

    if (validateTask !== 'EMPTY_IDS' && validateTask !== "WEEK_PASSED") {
        return validateTask
    }

    return false
}