
import {defineComponent} from "vue";
import {getDepartmentById} from "@/store/interaction/userInteraction";
import {Department, User} from "@/shared/api";
import {editUser} from "@/axios_requests/edit_user";
import {editUserAvatar} from "@/axios_requests/edit_user_avatar";
import Preloader from "@/shared/ui/preloader";
import {eventBus} from "@/main";


export default defineComponent({
    name: "Profile",
    components: {Preloader},
    data() {
        return {
            path: process.env.VUE_APP_TASK_RADAR,
            showTaskPart: 'Настройки профиля',
            userDepartment: [] as string[],
            userData: this.$store.getters.getUserData,
            oldName: '',
            oldSurname: '',
            oldPatronymic: '',
            oldPhone: '',
            permissionChangeName: false,
            loading: true,
            variableWithMask: ''
        }
    },
    async mounted() {
        const header = document.querySelector('.burger-header')
        if (header) {
            header.textContent = this.showTaskPart
        }

        this.showTaskPart = 'Настройки профиля'

        this.getAllUserDepartments()

        if (this.userData && this.userData.permissions && this.userData.permissions.edit_user_info) {
            this.permissionChangeName = this.userData.permissions.edit_user_info
        }

        if (this.userData.info.work_phone && this.userData.info.work_phone[0] === '8') {
            this.userData.info.work_phone = this.userData.info.work_phone.substring(1)
        }

        if (this.userData.info.work_phone && this.userData.info.work_phone[0] === '7') {
            this.userData.info.work_phone = '+' + this.userData.info.work_phone
        }

        this.oldName = this.userData.info.name
        this.oldSurname = this.userData.info.surname
        this.oldPatronymic = this.userData.info.patronymic || '-'
        this.oldPhone = this.userData.info.work_phone


        this.loading = false

    },
    destroyed() {
        const header = document.querySelector('.burger-header')
        if (header) {
            header.textContent = 'Список задач'
        }
    },
    methods: {
        // Получение отделов пользователя
        getAllUserDepartments() {
            this.userData.department_ids.forEach((department_id: number) => {
                const department = getDepartmentById(department_id) as Department
                this.userDepartment.push(department.name)
            })
        },
        async saveUserDataChanges() {
            // Проверить изменилось ли состояние в инпуте и если нет то ничего не делать
            if (this.oldName === this.userData.info.name &&
                this.oldSurname === this.userData.info.surname &&
                this.oldPatronymic === this.userData.info.patronymic &&
                this.oldPhone === this.userData.info.work_phone) {
                return
            }

            const data = {
                id: this.userData.id,
                name: this.userData.info.name,
                surname: this.userData.info.surname,
                patronymic: this.userData.info.patronymic ?? '-',
                work_phone: this.userData.info.work_phone,
            }

            const res = await editUser(data, this.userData.id)

            if (res) {
                this.oldName = this.userData.info.name
                this.oldSurname = this.userData.info.surname
                this.oldPatronymic = this.userData.info.patronymic || '-'
                this.oldPhone = this.userData.info.work_phone
            }
        },
        async changeAvatar(event: Event) {
            const target= event.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];

            const formData = new FormData()
            formData.append('file', file)

            const avatar = await editUserAvatar(formData, this.userData.id) as User

            if (avatar && avatar.avatar_url) {
                this.userData.avatar_url = avatar.avatar_url
            }
        }
    }
})
