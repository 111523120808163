import {attachmentResponse} from "@/shared/api";
import {ENABLE_EDIT_TIME} from "@/utils/const";

export const getAttachmentsFromMessage = (attachments: attachmentResponse[], messageAttachments: number[]) => {
    return attachments.filter(el => {
        if (messageAttachments.includes(el.id)) {
            return el
        }
    })
}


export const getFileName = (filePath: string): string => {
    if (filePath) {
        return filePath.split('/')[filePath.split('/').length - 1].split('_').length > 1
            ? decodeURI(
                filePath.split('/')[filePath.split('/').length - 1].split('_').slice(0, -1) +
                '.' +
                filePath.split('.')[filePath.split('.').length - 1]
            )
            : decodeURI(filePath.split('/')[filePath.split('/').length - 1])
    }

    return 'Без имени'
}


export const canEditMessageTime = (timestamp: number | undefined): boolean => {
    if (timestamp) {
        const currentTimestamp = new Date().getTime()

        const timeDifference = Math.abs(currentTimestamp - timestamp * 1000);

        return timeDifference <= ENABLE_EDIT_TIME
    }

    return false
}


export function getTaskIdFromUrl(url: string) {
    // Split the URL by '/' to extract segments
    const segments = url.split('/');

    // Find the index of the segment that contains 'task'
    const taskIndex = segments.findIndex(segment => segment.includes('task'));

    // If 'task' segment is found
    if (taskIndex !== -1 && taskIndex < segments.length - 1) {
        // Get the next segment which should be the task ID
        return segments[taskIndex + 1];
    } else {
        return null; // Return null if task ID is not found in the URL
    }
}

export const removeSpanFromComment = (comment: string): string => {
    const withoutSpan = comment.replace(/<\/?span[^>]*>/g, '').replace(/<\/?div[^>]*>/g, ' ');
    const withLineBreaks = withoutSpan.replace(/<br\s*\/?>/g, '\n');
    const withSpaces = withLineBreaks.replace(/&nbsp;/g, ' ');
    return withSpaces;
};
