import {TaskApi} from "../shared/api";

export const TaskListSelectMixin = {

	data() {
		return {
			selectedIdArr: [],
			selectTask: false,
		}
	},
	methods: {
		setSelectActive() {
			this.selectTask = true
		},
		addIdInArrForRead(id) {
			if (this.selectedIdArr.includes(id)) {
				this.selectedIdArr = this.selectedIdArr.filter(item => item !== id)
			} else {
				this.selectedIdArr.push(id)
			}
		},
		cancelSelect() {
			this.selectedIdArr = [];
			this.selectTask = false
		},
		selectAll() {
			const idsArr = this.tasksObject.map(item => {
				return item.id
			})

			this.selectedIdArr = [];
			this.selectedIdArr = idsArr
		},
		async readTasks() {
			const res = await TaskApi.setReadTask(this.selectedIdArr)

			if (res && res.resultCode === 0) {

				for (let taskKey in this.dataFormated) {
					if (this.dataFormated.hasOwnProperty(taskKey)) {
						// Получите текущий объект
						let currentTask = this.dataFormated[taskKey];

						if (currentTask && currentTask.hasOwnProperty('tasks')) {
							const currentTaskTask = currentTask.tasks

							for (let i = 0; i < currentTaskTask.length; i++) {

								if (this.selectedIdArr.includes(currentTaskTask[i].id)) {
									currentTaskTask[i].unread_comments = false
								}
							}
						}
					}
				}
			}

			this.selectedIdArr = []
			this.selectTask = false
		},
	}
}
