import {compress} from "image-conversion";

export const compressImage = async (file: File) => {
    return new Promise((resolve, reject) => {
        compress(file, 0.5)
            .then(res => {
                console.log('Compression (orig, compr): ', file.size, res.size);
                resolve(blobToFile(res, file.name));
            })
            .catch(error => {
                console.error('Ошибка при сжатии изображения:', error);
                reject(error);
            });
    });
}

export const blobToFile = async (blob: Blob, fileName: string) => {
    return new File([blob], fileName);
}

