import {unit, CatalogItemType, FieldRowType, FieldsTypeTask, TaskTableTracker} from "../../interface";
import {getTableColumnByImei, getUserWhoAcceptObject} from "@/components/tracker/model/common";
import {APIFitter} from "@/components/tracker/model/api/fitter";
import {APIRulse} from "@/components/tracker/model/api/rulse";
import * as trackerMethods from "@/components/tracker/model/methods";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import {findFieldTask} from "@/components/tracker/model/utils";


export function getObjectCountInTable(api: any, tables: FieldRowType[]) {
    let count = 0;
    for (let i = 0; i < tables.length; i++) {
        if (tables[i].row_id !== null) {
            count++
        }
    }

    api.unitsData.allUnitsInTableCount = count
}



export async function getUserObjects(api: any) {
    console.log('widget: get user objects')

    !api.isFitter && (api.loading.statusLoading = false)

    api.abort()

    try {
        const response = await APIFitter.getApiUnits(api, api.userData)

        if (!response) return

        if (response.body.status === 'fail') {
            return api.createCabinetBeGenerate.errorMessage = "Что-то пошло не так"
        }

        // Если у клиента нет объектов
        if (response.body.status === 'not found' || response.body.data.units.length === 0) {
            console.log('widget: 0 objects in cabinet')

            const imeiOrSerialInTable = [] as string[]
            api.unitsData.inTableOnlyIMEI.length = 0

            if (api.formSR.includes(api.task.form_name)) {

                // Получаю imei всех объектов что пришли с бэка
                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "S/N new" || item.name === 'S/N') {
                                if (item.value) {
                                    imeiOrSerialInTable.push(item.value.toString().trim())
                                } else {
                                    return el.cells.find(block => {
                                        if (block.name === 'S/N old' || item.name === 'S/N') {
                                            if (block.value) {
                                                imeiOrSerialInTable.push(block.value.toString().trim())
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    })
                }

                // Доставка и выдача
            } else if (api.formDelivery.includes(api.task.form_name)) {

                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "Номер блока" || item.name === 'S/N') {
                                if (item.value) {
                                    imeiOrSerialInTable.push(item.value.toString().trim())
                                }
                            }
                        })
                    })
                }

            } else {
                // Получаю imei всех объектов что пришли с бэка
                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "IMEI") {
                                if (item.value) {
                                    imeiOrSerialInTable.push(item.value.toString().trim())
                                }
                            } else {
                                return el.cells.find(block => {
                                    if (block.name === 'Номер блока' || item.name === 'S/N') {
                                        if (block.value) {
                                            imeiOrSerialInTable.push(block.value.toString().trim())
                                        }
                                    }
                                })
                            }
                        })
                    })
                }
            }

            api.unitsData.inTableOnlyIMEI = imeiOrSerialInTable;
            const res = api.unitsData.allUnitsInTable.map((item: FieldRowType) => {
                return item.cells
            });

            (api.unitsData.inTableOnly as unknown as FieldRowType[][]).push(res as unknown as FieldRowType[])

            api.isLoadingUnit = false
            return
        }

        // Если поле user_id пустое
        if (!response) {
            return api.createCabinetBeGenerate.errorMessage = "Не удалось определить пользователя"

        } else {

            // imei и серийники объектов, которые пришли с бэка
            const unit_imei_arr = response.body.data.units.map((el: unit) => {
                return el.tracker.tracker_imei.toString()
            })

            const unit_serial_arr = response.body.data.units.map((el: unit) => {
                return el.tracker.tracker_serial.toString()
            })

            // Берет из таблицы все imei и сравнивает какие из них совпали
            // В один массив записывает те что совпали
            // В другой те что есть в таблице но нет в гс
            // В третий те что нашел в гс но не нашел в таблице

            const imeiOrSerialInTable = [] as string[]

            api.unitsData.inGsOnly.length = 0
            api.unitsData.inTableOnly.length = 0
            api.unitsData.inTableOnlyIMEI.length = 0
            api.unitsData.inGsAndInTable.length = 0


            if (api.formSR.includes(api.task.form_name)) {
                console.log('CP Form')

                // Получаю imei всех объектов что пришли с бэка
                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "S/N new" || item.name === 'S/N') {
                                if (item.value) {
                                    imeiOrSerialInTable.push(item.value.toString().trim())
                                } else {
                                    return el.cells.find(block => {
                                        if (block.name === 'S/N old' || item.name === 'S/N') {
                                            if (block.value) {
                                                imeiOrSerialInTable.push(block.value.toString().trim())
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    })
                }

                // Доставка и выдача
            } else if (api.formDelivery.includes(api.task.form_name)) {

                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "Номер блока" || item.name === 'S/N') {
                                if (item.value) {
                                    imeiOrSerialInTable.push(item.value.toString().trim())
                                }
                            }
                        })
                    })
                }

            } else {
                // Получаю imei всех объектов что пришли с бэка
                if (api.unitsData.allUnitsInTable && api.unitsData.allUnitsInTable.length > 0) {
                    api.unitsData.allUnitsInTable.forEach((el: FieldRowType) => {
                        return el.cells.filter(item => {
                            if (item.name === "IMEI" || item.name === 'Номер блока') {
                                if (item.value) {
                                    if (!imeiOrSerialInTable.includes(item.value.toString().trim())) {
                                        imeiOrSerialInTable.push(item.value.toString().trim())
                                    }
                                }
                            }
                        })
                    })
                }
            }


            // Объекты которые есть и в таблице и в гс
            for (let i = 0; i < imeiOrSerialInTable.length; i++) {
                for (let j = 0; j < unit_imei_arr.length; j++) {
                    if (imeiOrSerialInTable[i] === unit_imei_arr[j]) {
                        api.unitsData.inGsAndInTable.push(imeiOrSerialInTable[i].trim())
                    }
                }

                for (let j = 0; j < unit_serial_arr.length; j++) {
                    if (imeiOrSerialInTable[i] === unit_serial_arr[j]) {
                        api.unitsData.inGsAndInTable.push(imeiOrSerialInTable[i].trim())
                    }
                }
            }

            // Объекты которые есть только в таблице
            for (let i = 0; i < imeiOrSerialInTable.length; i++) {
                for (let j = 0; j < unit_imei_arr.length; j++) {
                    if (imeiOrSerialInTable[i] !== unit_imei_arr[j]) {
                        if (!api.unitsData.inTableOnly.includes(imeiOrSerialInTable[i]) && !unit_imei_arr.includes(imeiOrSerialInTable[i].toString().trim())) {
                            api.unitsData.inTableOnly.push(imeiOrSerialInTable[i].trim())
                        }
                    }
                }
            }


            // Объекты которые есть только в гс
            unit_imei_arr.filter((x: string) => {
                if (!imeiOrSerialInTable.includes(x)) {
                    api.unitsData.inGsOnly.push(x)
                }
            });


            // Получить отсортированные объекты по imei
            (api.unitsData.inGsAndInTable as unknown as FieldsTypeTask) = response.body.data.units.filter((item: unit) => {
                if (api.unitsData.inGsAndInTable.includes(item.tracker.tracker_imei)) {
                    return item
                } else if (api.unitsData.inGsAndInTable.includes(item.tracker.tracker_serial)) {
                    return item
                }
            });


            // Получили из таблицы imei или серийник
            // Смотрим есть ли этот серийник в inGsAndInTable
            // B если нет то добавляем

            // Объекты которые есть только в таблице
            for (let i = 0; i < imeiOrSerialInTable.length; i++) {
                let status = false
                for (let j = 0; j < api.unitsData.inGsAndInTable.length; j++) {
                    const itemInGs = api.unitsData.inGsAndInTable[j] as unknown as unit

                    // Если такой объект уже есть то не добавляем его
                    if (itemInGs.tracker.tracker_imei === imeiOrSerialInTable[i] || itemInGs.tracker.tracker_serial === imeiOrSerialInTable[i]) {
                        status = true
                    }
                }

                if (!status) {
                    api.unitsData.inTableOnly.push(imeiOrSerialInTable[i])
                    status = false
                }
            }

            // Получаю информацию об объектах которые есть только в таблице
            const tempInTable = []

            api.unitsData.inTableOnlyIMEI = JSON.parse(JSON.stringify(api.unitsData.inTableOnly))

            for (let i = 0; i < api.unitsData.allUnitsInTable.length; i++) {
                for (let j = 0; j < api.unitsData.inTableOnly.length; j++) {
                    for (let k = 0; k < (api.unitsData.allUnitsInTable[i] as FieldRowType).cells.length; k++) {
                        const current = (api.unitsData.allUnitsInTable[i] as FieldRowType).cells[k]
                        const currentInTable = api.unitsData.inTableOnly[j]
                        if (current.value) {
                            if (
                                current.name === 'IMEI' &&
                                current.value.toString().trim() === currentInTable ||
                                current.name === 'Номер блока'
                                && current.value.toString().trim() === currentInTable ||
                                current.name === 'S/N new'
                                && current.value.toString().trim() === currentInTable ||
                                current.name === 'S/N old'
                                && current.value.toString().trim() === currentInTable ||
                                current.name === 'S/N'
                                && current.value.toString().trim() === currentInTable
                            ) {
                                tempInTable.push((api.unitsData.allUnitsInTable[i] as FieldRowType).cells)
                            }
                        }
                    }
                }
            }

            api.unitsData.inTableOnly.length = 0;

            (api.unitsData.inTableOnly as unknown as FieldRowType[]).push([...[tempInTable][0]] as unknown as FieldRowType);

            // Получаю информацию об объектах которые есть только в кабинете
            (api.unitsData.inGsOnly as unknown as FieldsTypeTask) = response.body.data.units.filter((item: unit) => {
                if (api.unitsData.inGsOnly.includes(item.tracker.tracker_imei)) {
                    return item
                } else if (api.unitsData.inGsOnly.includes(item.tracker.tracker_serial)) {
                    return item
                }
            });

            // Если форма ЕО
            if (api.formWithoutUser.includes(api.task.form_name)) {

                // Получить все машины TSPB и взять только ту, что в поле Авто в задаче
                const autoField = findFieldTask(api.task, 'name', 'Авто', false);
                let autoName = ''

                if (autoField && autoField.length > 0 && autoField[0].value) {
                    autoName = autoField[0].value.toString()
                }

                await APIRulse.getAllCatalogApi(api)

                const tspbCarId = trackerMethods.CatalogInteraction.getCatalogIdByName(api, "Авто TSPB")

                if (tspbCarId) {
                    const res = await APIRulse.getCatalogValues(api, Number(tspbCarId))
                    if (!res) return

                    const cars = res.body.items.map((item: CatalogItemType) => item.value[0])

                    // Оставляю только те объекты, названия которых содержатся в каталоге Авто TSPB
                    const result = api.unitsData.inGsOnly.filter((item: any) => {
                        if (item.unit.name) {
                            for (let i = 0; i < cars.length; i++) {
                                if (
                                    item.unit.name.toLowerCase().includes(cars[i].toLowerCase()) &&
                                    cars[i].toLowerCase() === autoName.toLowerCase()
                                ) {
                                    return item
                                }
                            }

                        }
                    });

                    (api.unitsData.inGsAndInTable as unknown as string) = JSON.parse(JSON.stringify(result))
                }
            }

            // Если форма Проверка Трекера
            if (api.formWithoutTable.includes(api.task.form_name)) {
                (api.unitsData.inGsAndInTable as unknown as string) = JSON.parse(JSON.stringify(api.unitsData.inGsOnly as unknown as string))
            }


            // Получаю unit_id объектов которые есть и в таблице и в кабинете
            api.unitsData.unit_id = api.unitsData.inGsAndInTable.map((item: any) => {
                return item.unit.unit_id
            })


            // Добавляю состояние для каждого объекта
            for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
                (api.unitsData.inGsAndInTable[i] as unknown as unit).state = JSON.parse(JSON.stringify(api.widgetStatusMenuObject))
            }

            // Получает доп данные об объекте
            await api.callFuncToGetManyStatus(api.unitsData.unit_id)
            api.getCalibrationInfo()

            // Смотрит на состояние объекта (отправлен на проверку или нет)
            if (api.taskStatusProps && api.taskStatusProps.table_tracker) {
                const size = Object.keys(api.taskStatusProps.table_tracker as any).length;

                if (size) {
                    for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
                        const current = api.unitsData.inGsAndInTable[i] as unknown as unit;

                        if (current.tracker.tracker_imei) {
                            const row_id = getTableColumnByImei(api, current)

                            if (row_id !== null && row_id !== undefined) {
                                const objectStatus = (api.taskStatusProps.table_tracker as any)[row_id] as TaskTableTracker

                                if (objectStatus && current.state && current.state.buttonStatus) {

                                    if (objectStatus.fitter_accept) {
                                        current.state.buttonStatus.statusUnderReviewFitter = true
                                        current.state.buttonStatus.disabledFitter = true
                                    }

                                    if (objectStatus.to_accept) {
                                        current.state.buttonStatus.statusUnderReviewTO = true
                                        current.state.buttonStatus.disabledTO = true
                                    }

                                    getUserWhoAcceptObject(objectStatus, current, api.allUsers)
                                }
                            }
                        }

                    }
                }
            }

            api.isLoadingUnit = false
            api.multipleCabinets.allCabinets.length = 0
            api.widgetStatusActive = api.widgetStatus.getCabinetObject
        }
    } catch (error: any) {
        if (error.name === 'AbortError') {
            console.log('Fetch request aborted');
        } else {
            console.error('Fetch request failed:', error);
        }
    } finally {
        api.isRequestShouldCancelled = false

        CatalogInteraction.getCarInfoFromTable(api)
    }
}