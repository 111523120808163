import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import TaskList from '../views/TasksList.vue'
import NotFound from '../views/NotFound.vue'
import Login from '../views/Login.vue'
import Task from '../views/Task.vue'
import TaskListFound from "@/views/TasksListFound.vue";
import Profile from "@/views/Profile.vue";
import Guide from '@/views/Guide.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: TaskList,
        props: true
    },
    {
        path: '/found',
        name: 'FoundTasks',
        component: TaskListFound,
        props: true
    },
    {
        path: '/task/:id',
        name: 'TaskFull',
        component: Task,
        props: (route) => ({ id: Number(route.params.id) }),
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/profile',
        name: "Profile",
        component: Profile
    },
    {
        path: '/guide',
        name: "Guide",
        component: Guide,
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes
})

export default router