import {ChangeFieldValueType} from "@/shared/api";
let axios = require('axios')


export const updateTask = (taskId: number, values: ChangeFieldValueType[]) => {
    let responseObj = {} as {error: string}
    let headers = {} as {Authorization: string}

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`

    return axios({
        method: 'PUT',
        url: `${process.env.VUE_APP_TASK_RADAR}/task/${taskId}`,
        data: {values: values},
        headers,
        withCredentials: true
    })
        .then((res: any) => {
            try {
                if (res.status === 200) {
                    return res.body
                } else {
                    console.error('Не удалось получить задачу ', taskId)
                }
            } catch (e) {
                return "Ошибка при обновлении формы"
            }
        })
        .catch((err: any) => {
            responseObj.error = err.response
            return responseObj
        })
}