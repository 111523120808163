
import {defineComponent, PropType} from "vue";
import {attachmentResponse} from "@/shared/api";
import {getFileName} from "@/utils/message";

export default defineComponent({
    name: "FilePreview",
    props: {
        attachment: {
            type: Object as PropType<attachmentResponse>,
            required: true
        }
    },
    emits: ['deleteAttachment'],
    computed: {
        fileName(): string {
            return getFileName(this.attachment.file_path)
        }
    },
    methods: {
        deleteAttachment(filePath: string) {
            this.$emit('deleteAttachment', filePath)
        }
    }
})
