<template>
	<div @click="close" class="use-pyrus-popup__bg">
		<div @click.stop class="use-pyrus-popup">
			<button @click.stop="close" class="use-pyrus-popup__close-btn">
				<span>x</span>
			</button>
			<h2>Сейчас этот тип задач недоступен</h2>
			<p class="use-pyrus-popup__subinfo">
				Скоро мы решим эту проблему и пользоваться Fitter можно будет как раньше
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TempUsePyrusPopup',
		methods: {
			close() {
				this.$emit('close')
			}
		}
	}
</script>

<style scoped lang="scss">
	.use-pyrus-popup {
		width: 20rem;
        padding: 20px 16px;
		max-width: 96vw;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-sizing: border-box;
		background-color: white;
		display: flex;
		gap: 12px;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		text-align: center;
		border-radius: 4px;

		h2 {
			font-size: 1.25rem;
			font-weight: 600;
			color: #333333;
            line-height: 115%;
            text-align: left;
		}

		p {
			font-weight: 500;
		}

		&__bg {
			position: fixed;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, 0.3);
			box-sizing: border-box;
			top: 0;
			left: 0;
		}

		&__subinfo {
			font-size: 0.875rem;
			color: #9e9e9e;
			text-align: left;
			font-weight: 400;
            line-height: 130%;
		}

		&__close-btn {
			position: absolute;
			top: 0.25rem;
			right: 0.25rem;
			border: none;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.125rem 0.5rem;
		}
	}
</style>
