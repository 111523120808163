import axios from "axios";
import {TaskStatus} from "@/shared/api";
import globalStore from "@/store";


export const updateTaskStatus = (task_id: number, data: TaskStatus) => {

    const headers = {
        'Authorization': `Token ${globalStore.getters.getCurrentUser}`
    }

    return axios({
        method: "PATCH",
        url: `${process.env.VUE_APP_DOCKER_HREF}/fitter/task/status/${task_id}`,
        withCredentials: true,
        headers,
        data: data
    }).then(response => {
        if (response && response.status === 200) {
            if (response.data.status === 'success') {
                return response.data.data as TaskStatus
            }
        }
    }).catch((error: { response: { status: number; }; }) => {
        console.log(error)
    })

}