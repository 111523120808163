export function urlForDownload(url: string | null, avatar_size = 0) {
    if (url === "" || url === undefined) return "";
    if (url === null) {
        return " ";
    }
    let path = url;
    if (path[0] !== undefined && path[0] !== "/") {
        path = `/${path}`;
    }
    if (avatar_size === 0) {
        return `${process.env.VUE_APP_TASK_RADAR}${path}`;
    }

    return `${process.env.VUE_APP_TASK_RADAR}${path}?is_image=1&avatar_size=${avatar_size}`;
}


export const removeUnnecessaryStringAt = (string: string) => {
    return string
        .replaceAll(/&lt;\/?br&gt;/g, '')
        .replaceAll(/<\/?br>/g, '')
        .replaceAll(`&nbsp;`,'').trim()
}

export const removeSpanFromComment = (comment: string): string => {
    return comment.replace( /<\/?span[^>]*>/g, '').replace(/<\/?div[^>]*>/g, ' ')
}

export const truncateText = (text: string, wordCount: number) => {
    const words = text.split(/[\s\t\n]+/);

    if (words.length > wordCount) {
        return {
            text: words.slice(0, wordCount).filter(Boolean).join(' ').replace(/\n/g, ' ') + '...',
            truncate: true,
            smallText: false
        }
    }

    return {
        text,
        truncate: false,
        smallText: true
    }
}