import {CommonUser, User} from "@/shared/api";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";


export function saveUsersInLocalStorage(users: CommonUser[]) {
    LocalStorageService.getInstance().saveArray(STORAGE_KEY.users, users)
}

export function getUsersFromLocalStorage() {
    return LocalStorageService.getInstance().getItem(STORAGE_KEY.users)
}

export function saveUserDataInLocalStorage(user: User) {
    LocalStorageService.getInstance().saveItem(STORAGE_KEY.current_user_data, user)
}

export function getUserDataFromLocalStorage() {
    return LocalStorageService.getInstance().getItem(STORAGE_KEY.current_user_data)
}

export function getUserInfoFromLocalStorage() {
    const userId = LocalStorageService.getInstance().getItem(STORAGE_KEY.current_user_id)
    const token = localStorage.getItem("current_user")

    return {userId, token}
}

export function compareUserTokenFromCacheAndServer(serverUser: User) {
    const user_id = LocalStorageService.getInstance().getItem(STORAGE_KEY.current_user_id)
    const user_data = localStorage.getItem("current_user")

    if (!serverUser || !serverUser.id) {
        return false
    }

    const server_id = serverUser.id;

    if (!server_id || !user_id || !user_data) {
        return false
    }

    if (server_id.toString() === user_id.toString() && user_data) {
        return true
    }

    return false

}