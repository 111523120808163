
import {defineComponent, PropType} from "vue";
import {getDepartmentById, getUserOrBotById} from "@/store/interaction/userInteraction";
import {User} from "@/shared/api";

type UserType = {
    info: {
        name: string,
        surname: string
    },
    department_ids: number[]
}

export default defineComponent({
    name: "MessagePopoverUser",
    props: {
        user: {
            type: Object as PropType<User>,
            required: true
        }
    },
    setup() {
        return {
            getDepartmentById
        }
    },
    data() {
        return {
            userInfo: null as unknown as UserType,
            loading: true
        }
    },
    mounted() {
        const res = getUserOrBotById(this.user.id)

        const name = res.type === 'bot' ? res.info.bot_name : res.info.name
        const surname = res.type === 'bot' ? '' : res.info.surname

        this.userInfo = {
            info: {
                name: name,
                surname: surname,
            },
            department_ids: res.department_ids
        } as UserType

        this.loading = false
    }
})
