<template>
    <div>
        <div class="task" @click="goToFullTask(id)" :id="id"
             :style="taskMin.name.filter(Boolean).length === 0 ? 'min-height: 60px;' : 'min-height: 80px;'">
            <div class="status-div">
                <h1 class="task-header">

                   <span class="task-header-item" v-if="showYear && taskMin.date !== null">
                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33331 1.33334V3.33334" stroke="#999999" stroke-width="1.5"
                                  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.6667 1.33334V3.33334" stroke="#999999" stroke-width="1.5"
                                  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.33331 6.05997H13.6666" stroke="#999999" stroke-width="1.5"
                                  stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M14 5.66668V11.3333C14 13.3333 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3333 2 11.3333V5.66668C2 3.66668 3 2.33334 5.33333 2.33334H10.6667C13 2.33334 14 3.66668 14 5.66668Z"
                                stroke="#999999" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path d="M10.4631 9.13333H10.4691" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.4631 11.1333H10.4691" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99701 9.13333H8.00301" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99701 11.1333H8.00301" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.52954 9.13333H5.53553" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.52954 11.1333H5.53553" stroke="#999999" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                           {{ getFullData(taskMin.date) }}
                   </span>

                    <span class="task-header-line" v-if="showYear && taskMin.date !== null">|</span>

                    <span class="task-header-item" v-if="taskMin.date !== null">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                             style="margin-right: 2px;">
                        <path
                            d="M14.6666 8.50004C14.6666 12.18 11.6799 15.1667 7.99992 15.1667C4.31992 15.1667 1.33325 12.18 1.33325 8.50004C1.33325 4.82004 4.31992 1.83337 7.99992 1.83337C11.6799 1.83337 14.6666 4.82004 14.6666 8.50004Z"
                            stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.4733 10.62L8.40663 9.38671C8.04663 9.17338 7.7533 8.66005 7.7533 8.24005V5.50671"
                              stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{ getFullTime(taskMin.date) }}
                   </span>

                    <span class="task-header-line" v-if="taskMin.date !== null">|</span>

                    <span class="task-header-item">
                     {{ taskMin.form_name }}
                   </span>

                    <img v-if="isTaskSelected" src="@/assets/img/Task/checked.svg"/>
                </h1>
                <div class="task-header__status">
                    <div v-if="taskMin.unread_comments" class="status-body notification-number">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.25 10H4C2 10 1 9.5 1 7V4.5C1 2.5 2 1.5 4 1.5H8C10 1.5 11 2.5 11 4.5V7C11 9 10 10 8 10H7.75C7.595 10 7.445 10.075 7.35 10.2L6.6 11.2C6.27 11.64 5.73 11.64 5.4 11.2L4.65 10.2C4.57 10.09 4.385 10 4.25 10Z"
                                stroke="#EC5555" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path d="M3.5 4.5H8.5" stroke="#EC5555" stroke-width="1.2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M3.5 7H6.5" stroke="#EC5555" stroke-width="1.2" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="fullWidth u-margin-top-s" v-if="taskMin.name.filter(Boolean).length !== 0">

                <div class="checkbox" v-if="selectTask">
                    <input type="checkbox" :checked="selected" class="custom-checkbox" :name="`checkbox-id-${id}`"
                           :id="`checkbox-id-${id}`" :value="selected" @change="toggleCheckbox(id)">
                    <label :for="`checkbox-id-${id}`"></label>
                </div>

                <div
                    :class="['h3-primary', { searchColor: showYear, greenColor: styleColorGreen && !showYear, redColor: styleColorRed && !showYear, completeColor: isTaskComplete && !showYear }]">
                    <slot v-for="(item, index) in taskMin.name.filter(Boolean)">
                        <slot v-if="item !== ''">
                            <span class="task_details-divider" v-if="index !== 0">|</span>
                            <span>{{ item }}</span>
                        </slot>
                    </slot>
                </div>
            </div>
            <div class="task-footer" v-if="comment">
                <div class="task-footer-message" :style="taskMin.unread_comments ? 'width: 90%;' : 'width: 100%;'">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.75 13.8225H9.75L6.41249 16.0425C5.91749 16.3725 5.25 16.02 5.25 15.42V13.8225C3 13.8225 1.5 12.3225 1.5 10.0725V5.57251C1.5 3.32251 3 1.82251 5.25 1.82251H12.75C15 1.82251 16.5 3.32251 16.5 5.57251V10.0725C16.5 12.3225 15 13.8225 12.75 13.8225Z"
                            stroke="#999999" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M5.25 6H12.75" stroke="#999999" stroke-width="1.2" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.25 9.75H9.75" stroke="#999999" stroke-width="1.2" stroke-miterlimit="10"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div class="task-footer-text">{{ comment }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {removeSpanFromComment, removeUnnecessaryStringAt} from "@/utils/misc";
import {getFullTime, getFullData} from "@/utils/time";
import {SelectItemHoldMixin} from "@/mixin/SelectItemHoldMixin";

export default {
    name: 'TaskItem',
    props: {
        taskMin: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        selectTask: {
            type: Boolean,
            required: true
        },
        selectedIdArr: {
            type: Array,
            required: true
        },
        showYear: {
            type: Boolean
        }
    },
    setup() {
        return {
            getFullTime,
            getFullData
        }
    },
    data() {
        return {
            dateToday: Date.now(),
            styleColorGreen: false,
            styleColorRed: false,
            isTaskComplete: false,
            isTaskSelected: false,
            message_number: 0,
            prettyName: [],
        }
    },
    emits: ['setSelectActive', 'addIdInArrForRead'],
    mixins: [SelectItemHoldMixin],
    async created() {
        this.setColorForTask(this.taskMin.closed)
    },
    watch: {
        taskMin: {
            handler(newValue) {
                this.setColorForTask(newValue.closed)
            },
            deep: true
        },
    },
    computed: {
        comment() {
            if (this.taskMin.comment) {
                const textArr = this.taskMin.comment.split('/blockquote>')
                if (textArr.length === 1) {
                    return removeUnnecessaryStringAt(removeSpanFromComment(textArr[0]))
                } else {
                    return removeUnnecessaryStringAt(removeSpanFromComment(textArr[textArr.length - 1]))
                }
            } else {
                return null
            }
        },
    },
    methods: {

        toggleCheckbox(id) {
            this.$emit('addIdInArrForRead', id)
        },

        pressThresholdReached() {
            this.$emit('setSelectActive')
        },

        setColorForTask(taskClosed) {
            let dateNow = new Date(this.dateToday),
                yearNow = dateNow.getFullYear(),
                monthNow = ('0' + (dateNow.getMonth() + 1)).slice(-2),
                dayNow = ('0' + dateNow.getDate()).slice(-2),
                hourNow = dateNow.getHours(),
                minutNow = ('0' + dateNow.getMinutes()).slice(-2)

            let dateTask = new Date(this.taskMin.date * 1000),
                yearTask = dateTask.getFullYear(),
                monthTask = ('0' + (dateTask.getMonth() + 1)).slice(-2),
                dayTask = ('0' + dateTask.getDate()).slice(-2),
                hourTask = dateTask.getHours(),
                minutTask = ('0' + dateTask.getMinutes()).slice(-2)

            // Определяем стиль задачи:
            // 1. Задача просрочена = цвет текста красный
            // 2. Предстоящая сегодня задача = цвет текста зеленый
            // 3. Прошлые или будущие задачи = цвет текста стандартный
            this.isTaskComplete = taskClosed

            if (!this.isTaskComplete) {
                // Определяем, что задача сегодня
                if (dayNow === dayTask && monthNow === monthTask && yearTask === yearNow) {
                    // Здесь определяем, что задача еще не началась либо уже начачалась
                    if (hourNow < hourTask || (hourNow === hourTask && minutNow < minutTask)) {
                        this.styleColorGreen = true
                    } else {
                        this.styleColorRed = true
                    }
                } else if (Number(dateNow) >= Number(dateTask) && yearNow >= yearTask) {
                    this.styleColorRed = true
                } else {
                    this.styleColorGreen = true
                }
            }
        },

        goToFullTask(id) {
            if (this.selectTask) {
                this.selected = !this.selected
                this.$emit('addIdInArrForRead', id)
                return
            }

            this.$emit('go-to-full-task', this.taskMin.id)

            this.$store.dispatch('addTaskSelected', this.taskMin.id)
        }
    }
}
</script>

<style scoped>
.task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 16px 20px;
    min-height: 80px;
    line-height: 1.4em;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
}

.status-notification {
    position: absolute;
    right: 10px;
    top: 6px;
    display: flex;
    flex-direction: row;
}

.status-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-header__status {
    display: flex;
}

.status-notification {
    top: unset;
    bottom: 0;
    right: 0;
}

.status-body {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 9px 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.75em;
    line-height: 0.8em;
}

.status-body:not(:last-child) {
    margin-right: 4px;
}

.accepted-status {
    color: #00bb34;
    background: #edf8f0;
}

.uppdated-status {
    color: #e8a117;
    background: #fff7e7;
}

.new-status {
    color: #ec5555;
    background: #ffe7e8;
}

.notification-number {
    color: var(--red);
    background: var(--red-light);

    border-radius: 40px;
}

.task-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    gap: 0 8px;
    padding: 4px 8px;

    font-size: 14px;
    color: #999999;
    font-weight: 600;

    word-break: break-word;

    border-radius: 8px;
    background: #F6F6F6;
}

.task-header .task-header-item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.task p {
    font-size: 15px;
    line-height: 140%;
}

.fullWidth {
    width: 100%;
    display: flex;
    align-items: center;
}

.greenColor {
    color: #00bb34 !important;
    font-weight: 600;
}

.redColor {
    color: rgb(236, 85, 85) !important;
    font-weight: 600;
}

.completeColor {
    color: #999 !important;
    font-weight: 600;
    text-decoration: line-through;
}

.searchColor {
    color: #333 !important;
    font-weight: 600;
}

.task_details-divider {
    color: var(--divider-color);

    margin: 0 4px;
}

.task-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    margin-top: 12px;
    padding-top: 12px;

    border-top: 1px solid var(--divider-color);
    border-radius: 2px;
}

.task-footer-message {
    display: flex;
    align-items: center;
}

.task-footer-text {
    width: 100%;

    margin-left: 6px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: var(--gray, #9F9F9F);
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.task-footer-count {
    margin-left: 12px;
    padding: 2px 8px;

    border-radius: 50px;
    background: var(--red-light, #FFE7E8);

    color: var(--red, #EC5555);
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.checkbox {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active + label::before {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}


/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked) + label::before {
    border-color: #80bdff;
}

.custom-checkbox:focus + label::before {
    box-shadow: none;
}


/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked + label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
</style>
