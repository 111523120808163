import {ChangeFieldValueType, FieldsType, FieldsTypeTask} from "@/shared/api";
import globalStore from "@/store";
import {getBotById, getDepartmentById, getUserById} from "@/store/interaction/userInteraction";
import {UserFieldValue} from "@/shared/api";
import {getAttachmentById} from "@/utils/filter_messages";

export function getFormFieldById(id: number) {
    const fields = globalStore.getters.getFormField as FieldsTypeTask[]
    const surfaceSearch = fields.find((field: FieldsTypeTask) => field.id === id)

    let returnValue: FieldsType | null = null

    const findElWithRecursion = (el: FieldsType) => {
        if ('fields' in el) {
            el.fields?.map(item => {
                if (item.id === id) {
                    returnValue = item
                } else {
                    findElWithRecursion(item)
                }
            })
        } else {
            return
        }
    }

    if (surfaceSearch) {
        return surfaceSearch
    } else {
        // Поиск вложенного  элемента
        fields.forEach((el: FieldsType) => {
            // Если у родителя есть поле fields
            findElWithRecursion(el)
        })

        return returnValue
    }
}

const getUserFromRouteField = (value: UserFieldValue) => {
    const userValue = value as UserFieldValue;
    const values = [];
    for(const user_id of userValue.users){
        const user = getUserById(user_id);
        if (!user){
            const bot = getBotById(user_id);
            values.push(bot?.info.bot_name)
            continue
        }
        values.push(`${user?.info.name} ${user?.info.surname}`)
    }
    for(const dep_id of userValue.departments){
        const dep = getDepartmentById(dep_id);
        values.push(dep?.name)
    }
    return values.join(', ')
}


export const findFieldConvertValue = (
    fieldValue: ChangeFieldValueType,
    isOld = false
) => {
    let res = null;

    const field = getFormFieldById(fieldValue.field_id);
    const value = !isOld ? fieldValue.value : fieldValue.old_value;
    if (!field || !value) {
        return null;
    }
    if (field.type === "due_date_time") {
        res = `${timestampToDate(value as number)} ${timestampToTime(
            value as number
        )}`;
    } else if (["due_date", "date"].includes(field.type)) {
        res = timestampToDate(value as number);
    } else if (field.type === "time") {
        res = timestampToTime(Math.abs(value as number));
    } else if (field.type === "checkmark") {
        res = value === true ? "Да" : "Нет";
    } else if (field.type === "file") {
        const new_value = [];
        for (const val of value as number[]) {
            const attach = getAttachmentById(val);
            if (!attach) {
                new_value.push("Неизвестное вложение");
                continue;
            }
            new_value.push(
                attach.file_path.slice(attach.file_path.lastIndexOf("/") + 1)
            );
        }
        res = new_value;
    } else if (field.type === 'routing') {
        if (Array.isArray(value)){
            const arrVal = value as unknown as UserFieldValue[];
            for(const userVal of arrVal){
                res = getUserFromRouteField(userVal)
            }
        } else{
            res = getUserFromRouteField(value as unknown as UserFieldValue)
        }
    }
    else {
        res = value ? value.toString() : null;
    }
    return res;
};


export const timestampToTime = (timestamp: number) => {
    const dateField = new Date(timestamp * 1000);
    return dateField.toLocaleTimeString().split(":").slice(0, -1).join(":");
};

export const timestampToDate = (timestamp: number) => {
    const res = new Date(timestamp * 1000).toLocaleDateString("ru-RU");
    return res === "Invalid Date" ? "Некорректная дата" : res;
};