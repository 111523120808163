<template>
    <NotFoundError
        title="Страница не найдена"
        description="Скорее всего это случилось по одной из следующих причин: страница
      переехала, страницы больше нет"
    />
</template>
<script>
import NotFoundError from "@/shared/ui/error/NotFoundError";

export default {
    name: "NotFound",
    components: {NotFoundError},
    mounted() {
        const header = document.querySelector('.burger-header')
        if (header) {
            header.textContent = "Не найдено"
        }
    },
};
</script>

<style scoped>

</style>
