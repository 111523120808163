import {calibrationValues, unit} from "@/components/tracker/model/interface";
import {
    handleCalibrationEdit,
    handleCalibrationSubmit,
    hasIncompleteLastRow, hasInsufficientRows,
    hasInvalidFilterLength, hasInvalidRows, hasNegativeNumbers,
    hasNonZeroValues, hasNullValues, isValidInput, validateAllCalibration, validateOptionalField
} from "@/components/tracker/model/calibration/index";
import * as calibrationService from "@/components/tracker/model/calibration/index";
import {createObject} from "@/components/tracker/model/helpers";
import {quickSortSensorUnit} from "@/components/tracker/model/calibration/quickSort";


export async function saveDoubleCalibration(api: any, unit: unit, index: number) {
    const {calibrationArr} = unit
    unit.calibrationConfirmFilePopoverError = ""
    unit.calibrationLoadingFilePopover = false

    // calibrationVolumeTank - обязательное к заполнению поле при создании тарировки
    if (!calibrationArr[0].calibrationInfo.calibrationInput.calibrationVolumeTank || !calibrationArr[1].calibrationInfo.calibrationInput.calibrationVolumeTank) {
        unit.calibrationConfirmFilePopoverError = 'Необходимо заполнить объем бака';
        api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))

        return
    }

    api.CAN_UPDATE_TASK = false


    function exitWithError() {
        allSave = false
        api.CAN_UPDATE_TASK = true

        return
    }


    let allSave = true;
    // Запрещаю обновлять объекты и закывать модалку, пока не выполнились тарировки

    if (calibrationArr.length === 0) {
        exitWithError()
    }

    unit.calibrationConfirmFilePopover = false
    api.calibrationStartSaveNotification = true

    setTimeout(() => {
        api.calibrationStartSaveNotification = false
    }, 2000)

    for (const [indexUnit, calibrationData] of calibrationArr.entries()) {
        const {calibrationInfo, calibration: calibrationValue} = calibrationData;
        const { calibrationInput} = calibrationInfo

        if (unit.calibrationConfirmFilePopoverError) {
            exitWithError()
        }

        // Проверка заполнена ли таблица тарировки
        // if (!isValidInput(unit)) {
        //     return;
        // }

        const {
            calibrationFilter,
            calibrationSerialNumber,
            calibrationVolumeTank,
        } = calibrationInput;


        unit.calibrationLoadingFilePopover = true

        const filtration = calibrationFilter;
        const dut_serial_number = Number(calibrationSerialNumber) > 0 ? Number(calibrationSerialNumber) : null;
        const size = Number(calibrationVolumeTank) > 0 ? Number(calibrationVolumeTank) : null;
        const host = api.userData.host;
        const task_id = api.task.id;
        const unit_id = unit.unit.unit_id;

        unit.activeCalibrationIndex = indexUnit

        let sensor_name = getSensorName(unit)

        const data = createObject({
            calibration_table: calibrationValue,
            dut_serial_number,
            filtration,
            sensor_name,
            host,
            size,
            task_id,
            unit_id,
        })

        calibrationValue?.pop()
        const isSave = await handleCalibrationSubmit(api, unit, index, data, indexUnit);

        if (isSave) {
            api.calibrationNotificationSaveCount = `Сохранено тарировок ${indexUnit + 1}/${calibrationArr.length}`
        }
    }


    if (allSave) {
        api.calibrationNotificationSaveCount = ''
        api.CAN_UPDATE_TASK = true

        await api.updateTaskOnly(false)
    }


}


export async function saveCalibrationValue(api: any, unit: unit, index: number, calibrationIndex: number) {
    const {calibrationArr, activeCalibrationIndex} = unit;

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex];
    const {calibrationSubmitLoading, calibrationInput, calibrationValue} = calibrationInfo

    if (calibrationSubmitLoading) return;

    // Проверка заполнена ли таблица тарировки
    if (!isValidInput(unit)) {
        return;
    }

    calibrationInfo.calibrationConfirmPopoverError = '';

    const {
        calibrationFilter,
        calibrationSerialNumber,
        calibrationVolumeTank,
    } = calibrationInput;


    // calibrationVolumeTank - обязательное к заполнению поле при создании тарировки
    if (!calibrationVolumeTank && !calibrationInfo.calibrationEditOnlyText) {
        calibrationInfo.calibrationConfirmPopoverError = 'Необходимо заполнить объем бака';

        return
    }

    const filtration = calibrationFilter;
    const dut_serial_number = Number(calibrationSerialNumber) > 0 ? Number(calibrationSerialNumber) : null;
    const size = Number(calibrationVolumeTank) > 0 ? Number(calibrationVolumeTank) : null;
    const host = api.userData.host;
    const task_id = api.task.id;
    const unit_id = unit.unit.unit_id;

    let sensor_name = getSensorName(unit)

    const data = createObject({
        calibration_table: calibrationValue,
        dut_serial_number,
        filtration,
        sensor_name,
        host,
        size,
        task_id,
        unit_id,
    })

    // Если только редактирование, то собираю другой json и делаю другой запрос
    if (calibrationInfo.calibrationEditOnlyText) {
        delete data.calibration_table
        delete data.size
        delete data.sensor_name

        // Проверка заполнены ли поля серийный номер или степень фильтрации
        if (!validateOptionalField(filtration, dut_serial_number)) {
            calibrationInfo.calibrationConfirmPopoverError = 'Необходимо заполнить хотя бы одно поле';
            return
        }

        await handleCalibrationEdit(api, unit, index, data, calibrationIndex);

        return
    }


    if (calibrationValue && calibrationValue.length > 0) {

        if (hasInvalidFilterLength(calibrationFilter, unit)) {
            return;
        }

        if (hasIncompleteLastRow(calibrationValue, unit)) {
            return;
        }

        if (!hasNonZeroValues(calibrationValue)) {
            return (calibrationInfo.calibrationConfirmPopoverError = 'Все поля не могут содержать 0');
        }

        if (hasInsufficientRows(calibrationValue, unit)) {
            return;
        }

        if (hasNullValues(calibrationValue)) {
            return (calibrationInfo.calibrationConfirmPopoverError = 'Обе ячейки должны быть заполнены');
        }

        calibrationValue.pop();


        if (hasInvalidRows(calibrationValue, unit)) {
            return;
        }

        if (hasNegativeNumbers(calibrationValue)) {
            return (calibrationInfo.calibrationConfirmPopoverError = 'Значение должны быть больше 0');
        }

        if (!host || !task_id || !unit_id) {
            return (calibrationInfo.calibrationConfirmPopoverError = 'Ошибка при формировании запроса');
        }

        calibrationInfo.calibrationConfirmPopover = false
        api.calibrationStartSaveNotification = true

        setTimeout(() => {
            api.calibrationStartSaveNotification = false
        }, 2000)

        await handleCalibrationSubmit(api, unit, index, data, calibrationIndex);


        await api.updateTaskOnly(false)
    }
}

export function changeCalibrationFieldValue(api: any, unit: unit, fieldId: number, cellId: number, value: number) {
    if (api.debounceTimer) {
        clearTimeout(api.debounceTimer);
    }

    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    api.debounceTimer = setTimeout(() => {
        const calibration = calibrationInfo.calibrationValue as calibrationValues[]

        if (calibration && calibration.length > 0) {
            calibration[fieldId][cellId] = Number(value)


            // Смотрит если есть пустая строка и она не последняя то удаляет ее
            const calibrationNew = calibration.filter(subArray => !subArray.every(element => element === 0));

            // Проверка что бы не удалить единственную строку
            if (calibrationNew.length >= 1) {
                calibration.splice(0, calibration.length, ...calibrationNew);
            }

            // Проверяет если последняя строка полностью заполнена то добавляет новую
            if (calibration[calibration.length - 1].some(element => element !== null && element !== undefined)) {
                calibration.push([])
                validateAllCalibration(calibration)
            }


            if (calibration.length >= 1 && calibration[0].length >= 2) {
                try {
                    calibrationService.validateFieldInColumn(calibration, fieldId, cellId)

                    calibrationService.renderLineChart(calibrationArr[activeCalibrationIndex])
                    calibrationInfo.calibrationChartShow = true

                    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
                } catch (err) {
                    calibrationInfo.calibrationChartShow = true
                    console.log('График уже существует')
                }
            }
        }

        calibrationService.saveCalibrationValueInLs(unit, calibration, api.task)

        calibrationService.updateChart(api, unit)

        api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))


    }, 300);
}


export function swapColumnsValues(unit: unit) {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return

    const {calibrationInfo} = calibrationArr[activeCalibrationIndex]

    const calibrationValue = [...calibrationInfo.calibrationValue as calibrationValues[]]

    // Меняю столбцы местами
    calibrationValue.map(item => item.reverse())
}


export function getSensorName(unit: unit): string | null {
    const {calibrationArr, activeCalibrationIndex} = unit

    if (calibrationArr.length === 0 || activeCalibrationIndex === null) return null

    if (calibrationArr.length === 1) {
        return null
    }


    // Меньший бак - первый бак
    const sensorWithCalibration = unit.sensors.filter(el => el.name.toLowerCase().includes('бак'))
    const sortedSenor = quickSortSensorUnit(sensorWithCalibration);

    let value = null;

    for (let i = 0; i < sortedSenor.length; i++) {
        if (i === activeCalibrationIndex) {
            value = sortedSenor[i].name
        }
    }

    return value
}