import {Commit} from "vuex";
import {attachmentResponse, ImagesPreview, TaskComment} from "@/shared/api";
import {saveCommentsInLocalStorage} from "@/utils/storage/storage-comment-method";
import {addUnsentCommentInLocalStorage} from "@/utils/storage/unsent-comment-method";

type State = {
    messages: TaskComment[],
    messagesPart: TaskComment[],
    notSendMessages: TaskComment[],
    attachments: attachmentResponse[],
    messageSend: boolean,
    limit: number,
    from_id: number,
    lastLoadMessageId: number,
    lastReadComment: number,
    getNewMessageFromId: number,
    currentBodyHeight: number,
    showSystemMessage: boolean,
    newMessageCount: number,
    isMessageVisible: boolean,
    firstUnreadComment: number | boolean,
    isScrollEnd: boolean,
    lastSetReadTimestamp: number | null,
    selectedMessage: number | null,
    selectedMessageNotSend: number | null
}

type EditMessageData = {
    text: string,
    attachments: number[],
    updated_at: number,
    id: number,
}

function updateMessage<T extends EditMessageData>(messageLists: TaskComment[], payload: T) {
    messageLists.filter(message => {
        if (message.id.toString() === payload.id.toString()) {
            message.attachments = payload.attachments
            message.text = payload.text
            message.updated_at = payload.updated_at
        }
    })

    messageLists.filter(message => {
        if (message.id.toString() === payload.id.toString()) {
            message.attachments = payload.attachments
            message.text = payload.text
            message.updated_at = payload.updated_at
        }
    })
}

export default {
    state: {
        messages: [],
        messagesPart: [],
        notSendMessages: [],
        attachments: [],
        messageSend: false,
        limit: 40,
        lastReadComment: 0,
        lastLoadMessageId: 0,
        from_id: 0,
        currentBodyHeight: 0,
        showSystemMessage: false,
        newMessageCount: 0,
        firstUnreadComment: null,
        isMessageVisible: true,
        getNewMessageFromId: 0,
        lastSetReadTimestamp: null,
        selectedMessage: null,
        selectedMessageNotSend: null,
    },
    mutations: {
        addNewPartMessages(state: State, payload: TaskComment[]) {
            state.messagesPart = payload
        },
        addNewMessagesPart(state: State, payload: TaskComment[]) {
            state.messagesPart.unshift(...payload)
        },
        addMessagePartEnd(state: State, payload: TaskComment[]) {
            state.messagesPart.push(...payload);
        },
        addNotSendMessages(state: State, payload: TaskComment[]) {
            state.notSendMessages.push(...payload)
        },
        addNewMessagesToStart(state: State, payload: TaskComment) {
            state.messagesPart.push(payload)
        },
        addMessages(state: State, payload: TaskComment[]) {
            state.messages = payload
        },
        deleteMessageFromState(state: State) {
            state.messages.pop()
        },
        deleteNotSendMessageFromPart(state: State) {
            const notSendId = state.notSendMessages.map(item => item.id);

            state.messagesPart = state.messagesPart.filter(item => !notSendId.includes(item.id))
        },
        changeStatusMessageOnNotSend(state: State, payload: { data: TaskComment, id: number }) {
            // В payload message id
            let message = state.messagesPart.find(message => message.id === payload.data.id && message.isSending);
            if (!message) {
                // Если сообщение не найдено по id то ищу его по тексту
                message = state.messagesPart.find(message => message.text === payload.data.text && message.isSending);
            }

            if (!message) return

            if (message) {
                const messageUpdated = message
                messageUpdated.type = 'not_send';
                messageUpdated.attachments = payload.data.attachments;
                messageUpdated.isSending = false

                addUnsentCommentInLocalStorage(payload.id, messageUpdated);

                state.notSendMessages.push(messageUpdated)
            }
        },
        changeUnsentMessageStatusToSend(state: State, payload: number) {
            state.messagesPart = state.messagesPart.map(item => {
                if (item.id === payload) {
                    item.isSending = true
                    item.type = 'comment'
                }

                return item
            })
        },
        validateMessagePart(state: State, payload: TaskComment) {
            const isMessageExist = state.messagesPart.find(message => message.id === payload.id)

            if (isMessageExist) return

            state.messagesPart.push(payload)
        },
        deleteMessageFromStateById(state: State, payload: number) {
            state.messagesPart = state.messagesPart.filter(message => message.id !== payload)
        },
        deleteNotSendMessageById(state: State, payload: number) {
            state.messagesPart = state.messagesPart.filter(message => !(message.id === payload))
            state.notSendMessages = state.notSendMessages.filter(message => !(message.id === payload))
        },
        changeMessageToSend(state: State, payload: number) {
            state.messagesPart = state.messagesPart.map(message => {
                if (message.id === payload) {
                    message.isSending = false
                }

                return message;
            })
        },
        clearAllMessage(state: State) {
            state.messages.length = 0
            state.messagesPart.length = 0
            state.attachments.length = 0
            state.newMessageCount = 0
        },
        changeMessageSendStatus(state: State, payload: boolean) {
            state.messageSend = payload
        },
        changeFormId(state: State, payload: number) {
            state.from_id = payload
        },
        changeLastLoadMessageId(state: State, payload: number) {
            state.lastLoadMessageId = payload
        },
        changeCurrentBodyHeight(state: State, payload: number) {
            state.currentBodyHeight = payload
        },
        changeFirstUnreadComment(state: State, payload: number) {
            state.firstUnreadComment = payload
        },
        changeIsShowMessageSystem(state: State, payload: boolean) {
            state.showSystemMessage = payload
        },
        changeNewMessageCount(state: State, payload: number) {
            state.newMessageCount = payload
        },
        changeMessageVisibility(state: State, payload: boolean) {
            state.isMessageVisible = payload
        },
        deleteNotSendMessage(state: State) {
            state.notSendMessages.length = 0
        },
        changeMessageAttachments(state: State, payload: attachmentResponse[]) {
            state.attachments.push(...payload)
        },
        changeMessageAttachmentsFull(state: State, payload: attachmentResponse[]) {
            state.attachments.length = 0
            state.attachments.push(...payload)
        },
        changeLastSetReadTimestamp(state: State, payload: number | null) {
            state.lastSetReadTimestamp = payload
        },
        changeSelectedMessage(state: State, payload: number | null) {
            state.selectedMessage = payload
        },
        changeSelectedMessageDeleted(state: State, payload: number | null) {
            state.selectedMessageNotSend = payload;
        },
        editMessage(state: State, payload: EditMessageData) {
            updateMessage(state.messages, payload)
            updateMessage(state.messagesPart, payload)
        },
        cancelEditMessage(state: State, payload: EditMessageData) {
            updateMessage(state.messagesPart, payload)
            updateMessage(state.messages, payload)
        }
    },
    actions: {
        addMessages({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addMessages', payload)
        },
        addNewMessagesPart({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addNewMessagesPart', payload)
        },
        addMessagePartEnd({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addMessagePartEnd', payload)
        },
        addNotSendMessages({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addNotSendMessages', payload)
        },
        addNewPartMessages({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addNewPartMessages', payload)
        },
        addNewMessagesToStart({commit}: { commit: Commit }, payload: TaskComment[]) {
            commit('addNewMessagesToStart', payload)
        },
        changeMessageToSend({commit}: {commit: Commit}, payload: number) {
            commit('changeMessageToSend', payload)
        },
        validateMessagePart({commit}: { commit: Commit }, payload: TaskComment) {
            commit('validateMessagePart', payload)
        },
        editMessage({commit}: { commit: Commit }, payload: EditMessageData) {
            commit('editMessage', payload)
        },
        cancelEditMessage({commit}: { commit: Commit }, payload: EditMessageData) {
            commit('cancelEditMessage', payload)
        },
        changeUnsentMessageStatusToSend({commit}: { commit: Commit }, payload: number) {
            commit('changeUnsentMessageStatusToSend')
        },
        deleteMessageFromState({commit}: { commit: Commit }) {
            commit('deleteMessageFromState')
        },
        deleteNotSendMessageFromPart({commit}: { commit: Commit }) {
            commit('deleteNotSendMessageFromPart')
        },
        deleteNotSendMessageById({commit}: { commit: Commit }, payload: number) {
            commit('deleteNotSendMessageById', payload)
        },
        changeSelectedMessageDeleted({commit}: { commit: Commit }, payload: number) {
            commit('changeSelectedMessageDeleted', payload)
        },
        changeStatusMessageOnNotSend({commit}: { commit: Commit }, payload: { text: string, id: number }) {
            commit('changeStatusMessageOnNotSend', payload)
        },
        deleteMessageFromStateById({commit}: { commit: Commit }, payload: number) {
            commit('deleteMessageFromStateById', payload)
        },
        clearAllMessage({commit}: { commit: Commit }) {
            commit('clearAllMessage')
        },
        changeMessageSendStatus({commit}: { commit: Commit }, payload: boolean) {
            commit('changeMessageSendStatus', payload)
        },
        changeFirstUnreadComment({commit}: { commit: Commit }, payload: number) {
            commit('changeFirstUnreadComment', payload)
        },
        changeFormId({commit}: { commit: Commit }, payload: number) {
            commit('changeFormId', payload)
        },
        changeLastLoadMessageId({commit}: { commit: Commit }, payload: number) {
            commit('changeLastLoadMessageId', payload)
        },
        changeIsShowMessageSystem({commit}: { commit: Commit }, payload: boolean) {
            commit('changeIsShowMessageSystem', payload)
        },
        changeCurrentBodyHeight({commit}: { commit: Commit }, payload: number) {
            commit('changeCurrentBodyHeight', payload)
        },
        changeNewMessageCount({commit}: { commit: Commit }, payload: number) {
            commit('changeNewMessageCount', payload)
        },
        changeMessageVisibility({commit}: { commit: Commit }, payload: number) {
            commit('changeMessageVisibility', payload)
        },
        changeMessageAttachments({commit}: { commit: Commit }, payload: attachmentResponse[]) {
            commit('changeMessageAttachments', payload)
        },
        changeMessageAttachmentsFull({commit}: { commit: Commit }, payload: attachmentResponse[]) {
            commit('changeMessageAttachmentsFull', payload)
        },
        changeLastSetReadTimestamp({commit}: { commit: Commit }, payload: number | null) {
            commit('changeLastSetReadTimestamp', payload)
        },
        changeSelectedMessage({commit}: { commit: Commit }, payload: number | null) {
            commit('changeSelectedMessage', payload)
        },
        deleteNotSendMessage({commit}: { commit: Commit }) {
            commit('deleteNotSendMessage')
        },
    },
    getters: {
        getAllMessages(state: State) {
            return state.messages
        },
        getPartMessages(state: State) {
            return state.messagesPart
        },
        getIsMessageSend(state: State) {
            return state.messageSend
        },
        getCurrentBodyHeight(state: State) {
            return state.currentBodyHeight
        },
        getIsShowSystemMessage(state: State) {
            return state.showSystemMessage
        },
        getNewMessageCount(state: State) {
            return state.newMessageCount
        },
        getMessageVisibility(state: State) {
            return state.isMessageVisible
        },
        getAllAttachments(state: State) {
            return state.attachments
        },
        getFirstUnreadComment(state: State) {
            return state.firstUnreadComment
        },
        getLastLoadMessageId(state: State) {
            return state.lastLoadMessageId
        },
        getLimit(state: State) {
            return state.limit
        },
        getLastSetReadTimestamp(state: State) {
            return state.lastSetReadTimestamp
        },
        getSelectedMessage(state: State) {
            return state.selectedMessage
        },
        getSelectedMessageDeleted(state: State) {
            return state.selectedMessageNotSend;
        },
        getAllErrorSendMessage(state: State) {
            return state.notSendMessages;
        }
    }
}
