import {ChangeTrackerData, CreateUnitsType, unit} from "@/components/tracker/model/interface";
import {APIFitter} from "@/components/tracker/model/api/fitter";
import * as fitterMethod from '@/components/tracker/model/fitter'
import {areObjectsEqual} from "@/components/tracker/model/helpers";
import {getSensorResult} from "@/components/tracker/model/methods/addSensorToObject";


export async function changeUnitByHand(api: any, unit: unit) {
    console.log('widget: change object')

    if (!unit.state || !unit.state.changeObject) return

    unit.state.changeObject.changeObjectLoading = true

    if (!unit.state.changeObject.serialNumber) {
        unit.state.changeObject.changeObjectLoading = false
        unit.state.changeObject.changeObjectErrorString = 'Ошибка при выполнении'
        unit.state.changeObject.changeObjectError = true

        return
    }

    unit.state.changeObject.changeObjectErrorString = ''
    unit.state.changeObject.changeObjectError = false

    const data: ChangeTrackerData = {
        "host": api.userData.host,
        "task_id": api.task.id,
        "tracker_number": unit.state.changeObject.serialNumber,
        "unit_id": unit.unit.unit_id
    }

   try {
       const result = await APIFitter.changeUnitSerial(api, data)
       if (result.status === 200) {
           if (result.body.result === 'ok') {
               await api.updateTask()

               fitterMethod.changeDOMElement.closeEditSensorAndChangeTrackerPopover(api, unit)
           } else {
               unit.state.changeObject.changeObjectError = true
           }
       }

   } catch (err) {
       console.log(err)
       unit.state.changeObject.changeObjectError = true
   } finally {
       unit.state.changeObject.changeObjectLoading = false
   }
}


export async function changeUnitSensorByHand(api: any, unit: unit) {
    const sensor = getSensorResult(api)

    if (!unit.state || !unit.state.changeObject) return

    unit.state.changeObject.changeObjectError = false
    unit.state.changeObject.changeObjectLoading = true

    const isChange = areObjectsEqual(unit.state.changeObject.sensorBefore, api.createByHandObject)

    if (isChange) {
        unit.state.changeObject.changeObjectError = true
        unit.state.changeObject.changeObjectLoading = false
        unit.state.changeObject.changeObjectErrorString = 'Выбраны такие же датчики'

        return
    }

    const unitName = unit.unit.name
    const number = unit.tracker.tracker_imei
    const unitId = unit.unit.unit_id

    const object: CreateUnitsType & {unit_id: string} = {
        host: api.userData.host,
        sensor,
        unit_id: unitId,
        task_id: api.task.id,
        tracker: {
            number: number,
            unit_name: unitName
        }
    }


    try {
        await APIFitter.updateUnitsTrackerAPI(api, object)
        fitterMethod.changeDOMElement.closeEditSensorAndChangeTrackerPopover(api, unit)

        unit.state.changeObject.changeObjectError = false
    } catch (err) {
        unit.state.changeObject.changeObjectError = true
        unit.state.changeObject.changeObjectErrorString = 'Что-то пошло не так'
    } finally {
        unit.state.changeObject.changeObjectLoading = false
    }
}