import {FieldRowType} from "@/components/tracker/model/interface";
import {addObjectFromTable, getObjectCountInTable} from "@/components/tracker/model/core/objects";
import {UtilsWidget} from "@/components/tracker/model/utils";
import {APIRulse} from "@/components/tracker/model/api/rulse";
import {CatalogInteraction} from "@/components/tracker/model/methods";

export async function getUserData(api: any, tableChange = false) {
    // Вызываю методы получения данных при изменении таблицы
    api.createUnitDataImeiOnly.length = 0

    const res = await CatalogInteraction.getDataForGenerateLogin(api)

    const hostFromTask = await CatalogInteraction.getHostValueFromTask(api.task);

    const loginFromTask = await CatalogInteraction.getLoginValueFromTask(api);

    const oldLogin = api.userData.login as unknown as string
    const newLogin = await CatalogInteraction.getLoginValueFromTask(api)

    if (res.region) {
        api.userData.region = res.region
    }

    if (loginFromTask) {
        api.userData.login = loginFromTask.toString().trim()
    }

    if (res.clientClass) {
        api.userData.client_class = res.clientClass
    }

    if (res.contact) {
        api.userData.phone = res.contact.toString()
    }

    if (res.login_method) {
        api.userData.login_method = res.login_method
    }

    if (res.login_method_value) {
        api.userData.contact = res.login_method_value.toString()
    }

    const host = UtilsWidget.getHostByValue(hostFromTask)

    if (hostFromTask) {
        api.userData.host = host ?? ''
        api.userData.host_fullName = hostFromTask
    }

    if (api.userData.user_id) {
        const res = await CatalogInteraction.getDataFromTable(api)
        if (res) {
            getObjectCountInTable(api, res);
            (api.unitsData.allUnitsInTable as FieldRowType[]) = res
        }

        if (!api.formWithoutUser.includes(api.task.form_name) && oldLogin === newLogin) {
            // Если изменилась таблица, то обновляю виджет
            if (tableChange) {
                await api.getUserObjects()
            }
        }
    }

    if (!api.isFitter) {
        api.createUnitDataArr = [];
        api.createUnitDataImeiOnly = []
    }

    await addObjectFromTable(api)

    api.select.selectTableActive = 'Пусто'
}