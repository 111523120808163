import { render, staticRenderFns } from "./MessageTime.vue?vue&type=template&id=706d085d&scoped=true"
import script from "./MessageTime.vue?vue&type=script&lang=ts"
export * from "./MessageTime.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706d085d",
  null
  
)

export default component.exports