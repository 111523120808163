<template>
    <main class="message-container-wrapper message-container-system">
        <!-- Добавление нового наблюдателя (user) -->
        <slot v-if="msg.info.action === 'change_spectators' && msg.info.kind === 'add' && msg.info.type === 'user'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение наблюдателя"
                                      :text="`Добавлен новый наблюдатель - ${user}`" iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Добавление нового наблюдателя (отдел) -->
        <slot v-if="msg.info.action === 'change_spectators' && msg.info.kind === 'add' && msg.info.type === 'department'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение наблюдателя"
                                      :text="`Новый отдел добавлен в наблюдатели - ${department}`" iconPath="icon_element-change.svg"
                                      type="change" />
        </slot>

        <!-- Удаление наблюдателя (отдел) -->
        <slot
            v-if="msg.info.action === 'change_spectators' && msg.info.kind === 'remove' && msg.info.type === 'department'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение наблюдателя"
                                      :text="`Отдел удален из наблюдателей - ${department}`" iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Удаление наблюдателя (user) -->
        <slot v-if="msg.info.action === 'change_spectators' && msg.info.kind === 'remove' && msg.info.type === 'user'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение наблюдателя"
                                      :text="`Пользователь удален из наблюдателей - ${user}`" iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Изменение нового согласующего (user) -->
        <slot v-if="msg.info.action === 'change_approvers' && msg.info.kind === 'add' && msg.info.type === 'user'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение согласующего"
                                      :text="`Добавлен новый согласующий - ${user} на этапе ${stepNameApprovers}`"
                                      iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Добавление нового наблюдателя (отдел) -->
        <slot v-if="msg.info.action === 'change_approvers' && msg.info.kind === 'add' && msg.info.type === 'department'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение согласующего"
                                      :text="`Новый отдел добавлен в согласующих - ${department} на этапе ${stepNameApprovers}`"
                                      iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Удаление наблюдателя (отдел) -->
        <slot v-if="msg.info.action === 'change_approvers' && msg.info.kind === 'remove' && msg.info.type === 'department'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение согласующего"
                                      :text="`Отдел удален из согласующих - ${department} на этапе ${stepNameApprovers}`"
                                      iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Удаление наблюдателя (user) -->
        <slot v-if="msg.info.action === 'change_approvers' && msg.info.kind === 'remove' && msg.info.type === 'user'">
            <MessageSystemPlaceholder :msg="msg" title="Изменение согласующего"
                                      :text="`Пользователь удален из согласующих - ${user} на этапе ${stepNameApprovers}`"
                                      iconPath="icon_element-change.svg" type="change" />
        </slot>

        <!-- Задача закрыта -->
        <slot v-if="msg.info.action === 'task_closed'">
            <MessageSystemPlaceholder :msg="msg" text="Задача закрыта"
                                      iconPath="icon_element-finished.svg" type="close" />
        </slot>

        <!-- Задача открыта -->
        <slot v-if="msg.info.action === 'task_opened'">
            <MessageSystemPlaceholder :msg="msg" text="Задача открыта"
                                      iconPath="icon_element-finished.svg" type="close" />
        </slot>

        <!-- Переход на другой этап -->
        <slot v-if="msg.info.action === 'routing'">
            <MessageSystemPlaceholder :msg="msg" title="Перенос этапа"
                                      :text="`Переход на этап ${newStepName} с этапа ${oldStepName}`" iconPath="icon_element-swap.svg"
                                      type="swap" />
        </slot>

        <!-- Пересогласование -->
        <slot v-if="msg.info.action === 'task_revoke_approves'">
            <MessageSystemRevoke :msg="msg" :allSteps="step" title="Пересогласование задачи" iconPath="icon_task-reply.svg"
                           type="change" />
        </slot>


        <!-- Отредактированное сообщение -->
        <slot v-if="msg.info.action === 'change_values' && msg.info.values.length > 0 && msg.info.tables.length === 0">
            <MessageSystemChangeValues :msg="msg" :newValue="msg.info.values" :user="user" />
        </slot>

        <!-- Отредактированное сообщение -->
        <slot v-if="msg.info.action === 'change_values' && msg.info.tables.length > 0 && msg.info.values.length === 0">
            <MessageSystemChangeRows :msg="msg" :newValue="msg.info.tables" :user="user" />
        </slot>

        <slot v-if="msg.info.action === 'change_values' && msg.info.values.length > 0 && msg.info.tables.length > 0">
            <MessageSystemChangeValues :msg="msg" :newValue="msg.info.values" :user="user" />
            <MessageSystemChangeRows :msg="msg" :isTitle="false" :newValue="msg.info.tables" :user="user" />
        </slot>

        <!--  Создание задачи   -->
        <slot v-if="msg.info.action === 'created'">
            <MessageSystemCreated :msg="msg" :allSteps="step" title="Задача создана" iconPath="icon_element-change.svg"
                            type="created" />
        </slot>

    </main>
</template>

<script>
import MessageSystemPlaceholder from "@/components/chat/message_system/MessageSystemPlaceholder";
import MessageSystemRevoke from "@/components/chat/message_system/MessageSystemRevoke";
import MessageSystemChangeValues from "@/components/chat/message_system/MessageSystemChangeValues";
import MessageSystemChangeRows from "@/components/chat/message_system/MessageSystemChangeRows";
import {getBotById, getDepartmentById, getUserById} from "@/store/interaction/userInteraction";
import MessageSystemCreated from "@/components/chat/message_system/MessageSystemCreated";

export default {
    name: "MessageSystem",
    components: {
        MessageSystemCreated,
        MessageSystemRevoke, MessageSystemPlaceholder, MessageSystemChangeValues, MessageSystemChangeRows},
    props: {
        msg: {
            type: Object
        },
        step: {
            type: Array
        },
    },
    data() {
        return {
            newStepName: null,
            oldStepName: null,
            user: null,
            department: null,
            stepNameApprovers: null,
        }
    },
    mounted() {
        this.getStepNames()
        this.getUserName()
        this.getDepartmentName()
    },
    methods: {
        getStepNames() {
            if (this.msg.info.action === 'routing') {
                const resNew = this.step.filter(item => {
                    if (item.id === this.msg.info.new_step_id) {
                        return item.name
                    }
                })

                const resOld = this.step.filter(item => {
                    if (item.id === this.msg.info.old_step_id) {
                        return item.name
                    }
                })

                this.newStepName = resNew[0].name
                this.oldStepName = resOld[0].name
            }

            if (this.msg.info.action === 'change_approvers') {
                const res = this.step.filter(item => {
                    if (item.id === this.msg.info.step_id) {
                        return item.name
                    }
                })

                this.stepNameApprovers = res[0].name
            }
        },
        getUserName() {
            if (this.msg.info.type === 'user') {

                const user = getUserById(this.msg.info.id)
                if (user) {
                    this.user = user.info.name + ' ' + user.info.surname;
                } else {
                    const bot = getBotById(this.msg.info.id)
                    if (!bot) { return }
                    this.user = bot.info.bot_name
                }
            }


            if (this.msg.info.action === 'change_values') {
                const res = getUserById(this.msg.author_id)
                if (res) {
                    this.user = res.info.name
                } else {
                    const bot = getBotById(this.msg.info.id)
                    if (!bot) { return }
                    this.user = bot.info.bot_name
                }

            }
        },
        getDepartmentName() {
            if (this.msg.info.type === 'department') {
                const res = getDepartmentById(this.msg.info.id)
                this.department = res.name
            }
        },

    },

}
</script>

<style scoped>

</style>