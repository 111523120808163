import {TaskDataType} from "@/shared/api";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";
import {StorageTask} from "@/shared/api/types/storage";
import {deleteValueFromMapByTimestamp} from "@/utils/storage/common-method";


export function saveTaskInLocalStorage(task: TaskDataType) {
    const taskMap: Map<number, StorageTask> = new Map()

    taskMap.set(task.id, {
        task,
        timestamp: new Date().getTime(),
    })

    const mapToObject = LocalStorageService.mapToLocalStorage(taskMap)

    LocalStorageService.getInstance().saveData(STORAGE_KEY.task, mapToObject)
}

export function deleteTaskFromLocalStorage(taskId: number) {

    const tasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.task)
    const taskMap = LocalStorageService.localStorageToMap(tasks)

    taskMap.delete(taskId.toString())

    const mapToObject = LocalStorageService.mapToLocalStorage(taskMap)

    LocalStorageService.getInstance().rewriteData(STORAGE_KEY.task, mapToObject)
}

// Пробегает по всем задачам и смотрит если она не актуально больше недели, то удаляет из ls
export function deleteTasksReturnMap(tasks: TaskDataType) {
    const taskMap = LocalStorageService.localStorageToMap(tasks)

    return deleteValueFromMapByTimestamp(taskMap)
}

export function deleteTasksOlderThanOneWeek(taskMap: TaskDataType) {
    const task = deleteTasksReturnMap(taskMap)
    const mapToObject = LocalStorageService.mapToLocalStorage(task)

    LocalStorageService.getInstance().rewriteData(STORAGE_KEY.task, mapToObject)
}


export function getTaskFromMap(id: number) {
    const tasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.task)
    const taskMap = LocalStorageService.localStorageToMap(tasks)

    const task = taskMap.get(id.toString()) as StorageTask | undefined

    if (task && task.task) {
        return task.task
    }

    return null
}