import {ObjectsNameType} from "@/components/tracker/model/const";
import {IParam} from "@/components/tracker/model/interface";

export function createObject(data: Partial<any>): any {
    const filteredData: any = {} as any;

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)  &&
            data[key as any] !== undefined &&
            data[key as any] !== null &&
            data[key as any] !== ""
        ) {
            filteredData[key as any] = data[key as any] as any;
        }
    }

    return filteredData;
}

export function isTwoDimensionalArrayEmpty<T>(arr: T) {
    // Проверяем, является ли массив массивом и имеет ли он хотя бы один элемент
    if (Array.isArray(arr) && arr.length > 0) {
        // Проверяем, есть ли хотя бы один элемент в подмассивах
        return arr.every(subArray => Array.isArray(subArray) && subArray.length === 0);
    }

    // Если массив пуст или не является массивом
    return true;
}


export function extractNumbers(input: string) {
    // Используем регулярное выражение для извлечения чисел
    const match = input.match(/(\d+)(?:\D+(\d+))?/);

    // Если есть совпадение, возвращаем массив чисел
    if (match) {
        const [, firstNumber, secondNumber] = match;

        const firstValue = firstNumber ? parseInt(firstNumber, 10) : 0;
        const secondValue = secondNumber ? parseInt(secondNumber, 10) : 0;

        return [firstValue, secondValue];
    }

    // Если совпадения не найдены, возвращаем пустой массив или другое значение по умолчанию
    return [];
}

export function haveCommonElements(arr1: string[], arr2: string[]) {
    return arr1.some(element => arr2.includes(element));
}

export class TimestampMethods {
    static timestampToDate(timestamp: number): string | number {
        if (timestamp) {
            const res = new Date(timestamp * 1000).toLocaleDateString('ru-RU');
            return res === 'Invalid Date' ? 'Некорректная дата' : res;
        }

        return 0;
    }

    static timestampToTime(timestamp: number): string | number {
        if (timestamp) {
            const res = new Date(timestamp * 1000).toLocaleTimeString('ru-RU');
            return res === 'Invalid Date' ? 'Некорректная дата' : res;
        }

        return 0
    }
}


export function findKeyByValue(obj: Object, targetValue: string) {
    for (const [key, value] of Object.entries(obj)) {
        if ((value as string[]).includes(targetValue)) {
            return key
        }
    }
    return null; // Return null if no match is found
}


export function addSensor (api: any, name: string, type: string, param: IParam) {
    if (api.createByHandObject[type] && haveCommonElements(api.createByHandObject.objectInfo.availableObject, (ObjectsNameType as any)[name])) {
        api.createByHandObject.objectInfo.sensorId.push((ObjectsNameType as any)[name][0]);

        return {
            name,
            param,
        }
    }

    return null
}


export function areObjectsEqual(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (key === 'objectInfo') {
            continue; // Ignore the 'objectInfo' field
        }

        const value1 = obj1[key];
        const value2 = obj2[key];

        if (typeof value1 === 'object' && typeof value2 === 'object') {
            if (!areObjectsEqual(value1, value2)) {
                return false;
            }
        } else if (value1 !== value2) {
            return false;
        }
    }

    return true;
}

export enum returnTypeCheckArray {
    EMPTY = 'empty',
    DOUBLE_ARR = 'array_of_array',
    SIMPLE_ARR = 'array_of_element',
    NOT_ARR = 'not_array'
}

export function checkArrayType(arr: any): returnTypeCheckArray {
    if (Array.isArray(arr)) {
        if (arr.length === 0) {
            return returnTypeCheckArray.EMPTY;
        } else if (Array.isArray(arr[0])) {
            return returnTypeCheckArray.DOUBLE_ARR;
        } else {
            return returnTypeCheckArray.SIMPLE_ARR;
        }
    } else {
        return returnTypeCheckArray.NOT_ARR;
    }
}
