interface State {
    images: Record<string, string>,
}

export default {
    state: {
        images: {},
    },
    mutations: {
        setImage(state: State, payload: {url: string, base64String: string}){
            if(!state.images[payload.url]){
                state.images[payload.url] = payload.base64String
            }
        },
        clearImages(state: State){
            state.images = {};
        },
    },
    actions: {},
    getters: {
        getImages(state: State){
            return state.images
        },
    },
}