import {plural} from "@/helpers/plural";
import {WEEK} from "@/utils/const";

import {CONSTANT_Arr_Months} from "@/constants/constants";

export const getDate = (time: number) => {
	let dateTime = new Date(time * 1000),
		yearTime = dateTime.getFullYear(),
		monthTime = dateTime.getMonth() + 1,
		dayTime = dateTime.getDate(),
		hourTime = dateTime.getHours(),
		minutTime = ('0' + dateTime.getMinutes()).slice(-2),
		secondTime = ('0' + dateTime.getSeconds()).slice(-2)
	let dateNow = new Date(),
		yearNow = dateNow.getFullYear(),
		monthNow = dateNow.getMonth() + 1,
		dayNow = dateNow.getDate(),
		hourNow = dateNow.getHours(),
		minutNow = ('0' + dateNow.getMinutes()).slice(-2)
    return {
        dateTime: {
            yearTime: yearTime,
            monthTime: monthTime,
            dayTime: dayTime,
            hourTime: hourTime,
            minutTime: minutTime,
            secondTime: secondTime
        },
        dateNow: {
            yearNow: yearNow,
            monthNow: monthNow,
            dayNow: dayNow,
            hourNow: hourNow,
            minutNow: minutNow
        }
    }
}


export function hasOneWeekPassed(timestamp: number) {
    const currentTimestamp = new Date().getTime()

    const timeDifference = Math.abs(currentTimestamp - timestamp);

    return timeDifference >= WEEK
}


export function howLongAgo(timestamp: number): string | null {
    if (!timestamp) return null

    const currentTime = Date.now();

    const differentTime = currentTime - timestamp

    const days = Math.floor(differentTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((differentTime % (1000 * 60 * 60)) / (1000 * 60));

    if (days) {
        return `${days} ${plural(['день', "дня", "дней"], days)} назад`
    }

    if (hours) {
        return `${hours} ${plural(['час', "часа", "часов"], hours)} назад`
    }

    if (minutes) {
        return `${minutes} ${plural(['минуту', "минуты", "минут"], minutes)} назад`
    }

    return null
}

export function changeDateToMonth(dateNumber: string | number) {
    let uniqDateStringMonth = dateNumber.toString().split('.'),
        taskMonth = Number(uniqDateStringMonth[1])

    return `${uniqDateStringMonth[0]} ${CONSTANT_Arr_Months[taskMonth - 1]}`
}

export function getFullTime(work_date: number) {
    // Create Date task
    let d = new Date(work_date * 1000),
        hour = d.getHours(),
        minut = ('0' + d.getMinutes()).slice(-2)


    return `${hour}:${minut}`
}

export function getFullData(work_date: number) {
    let d = new Date(work_date * 1000),
        year = d.getFullYear(),
        month = (d.getMonth() + 1).toString(),
        day = d.getDate().toString()

    if (Number(day) <= 9) {
        day = `0${day}`
    }

    if (Number(month) <= 9) {
        month = `0${month}`
    }

    return `${day}.${month}.${year}`
}


export function getStartAndEndDay(day: string) {
    const currentDate = new Date();
    const current = new Date(currentDate);

    switch (day) {
        case 'Вчера':
            current.setDate(currentDate.getDate() - 1);
            break;
        case 'Завтра':
            current.setDate(currentDate.getDate() + 1);
            break;
        default:
            break
    }

    const dayStart = current.setHours(0, 0, 0, 0);
    const dayEnd = current.setHours(23, 59, 59, 999);

    return {
        dayStart,
        dayEnd
    }
}