import {APIRulse} from "@/components/tracker/model/api/rulse";
import {
    addSensorFromTableToObject,
    getSelectedObject,
    shouldSetNumberAndUnitName,
} from "@/components/tracker/model/core/methods/createFromTable";


// Выбор объекта в селекте при добавлении
export async function addObjectFromTable(api: any) {
    console.log('widget: add object from table')

    if (!api.unitsData.inTableOnly[0]) {
        return;
    }

    const selectedObj = getSelectedObject(api.unitsData.inTableOnly[0], api.select.selectTableActive)

    if (selectedObj) {
        const selectObjType = selectedObj
        let objectInfo = []

        api.sensor.length = 0

        // Получаю объекты из каталога
        const res = await APIRulse.getCatalogValues(api, 112)
        if (!res) return

        if (res.body.items) {
            objectInfo = res.body.items
        }


        for (let i = 0; i < selectObjType.length; i++) {
            // Установка номера и имени устройства, если поле соответствует условиям
            const trackerInfo = shouldSetNumberAndUnitName(selectObjType[i].name, selectObjType[i].value);

            if (trackerInfo && trackerInfo.unit_name && trackerInfo.number) {
                api.createUnitData.tracker.number = trackerInfo.number;
                api.createUnitData.tracker.unit_name = trackerInfo.unit_name
            }

            if (selectObjType[i].name === 'Оборудование' && selectObjType[i].value) {
                const arrayWithSensor = selectObjType[i].value as string[];

                const sensor = addSensorFromTableToObject(arrayWithSensor, objectInfo, api.unitsName)

                if (sensor) {
                    api.sensor.push({
                        name: sensor.name,
                        param: sensor.param,
                    });
                }

            }

        }


        api.sensor.push({
            name: "ignition",
            param: {
                type: "12v"
            }
        })

        api.createUnitData.host = api.userData.host
        api.createUnitData.task_id = api.task.id

        api.createUnitData.sensor = api.sensor

        api.createUnitDataArr.push(JSON.parse(JSON.stringify(api.createUnitData)))

        api.createUnitData.sensor.length = 0
        api.createUnitData.tracker.number = ""
        api.createUnitData.tracker.unit_name = ""
    }
}
