enum TextValueVar {
    SN = 'SN ДУТа',
    Filter = 'Фильтрация',
    Fuel = 'Объем бака',
}

interface ITextValue {
    [TextValueVar.SN]?: string,
    [TextValueVar.Filter]?: string,
    [TextValueVar.Fuel]?: string
}

interface IReturnType {
    calibrationFile: number[][],
    calibrationInfoFile: ITextValue
}

export const parseGoogleFile = (file: string): IReturnType => {
    const rows = file.split('\n');
    const extractedValues: string[][] = [];

    const serialNumber = TextValueVar.SN
    const filter = TextValueVar.Filter
    const fuel = TextValueVar.Fuel

    const textValue: ITextValue = {};


    rows.forEach((row) => {
        const values = row.split(',').map((value) => value.trim()); // Разбиваем строку на значения и удаляем пробелы в начале и конце каждого значения
        const validValues = values.filter((value) => !isNaN(parseFloat(value))); // Оставляем только числовые значения
        extractedValues.push(validValues.slice(0, 2)); // Берем первые три числовых значения из каждой строки
    });


    // Удаляю все пустые и не заполненые массивы, осталяю только данные для тарировки
    const calibrationValue = extractedValues
        .filter(subArray => subArray.length === 2) // Фильтруем массивы с длиной 2
        .map(subArray => subArray.map(Number)); // Преобразуем каждый элемент в числа


    // Получаю дополнительные данные для тарировки
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];

        const parts = row.split(',');
        const indexSerial = parts.indexOf(serialNumber)
        const indexFilter = parts.indexOf(filter)
        const indexFuel = parts.indexOf(fuel)


        if (indexSerial !== -1 && indexSerial + 1 < parts.length) {
            textValue[serialNumber] = parts[indexSerial + 1].trim()
        }

        if (indexFilter !== -1 && indexFilter + 1 < parts.length) {
            textValue[filter] = parts[indexFilter + 1].trim()
        }

        if (indexFuel !== -1 && indexFuel + 1 < parts.length) {
            if (parts[indexFuel + 1]) {
                textValue[fuel] = parts[indexFuel + 1].replace(/\D/g, "")
            }
        }
    }

    return {
        calibrationFile: calibrationValue,
        calibrationInfoFile: textValue
    }
}