
import {defineComponent, PropType} from "vue";
import {UserCabinetStatus} from "../../model/const";

export default defineComponent({
    name: "cabinetStatus",
    props: {
        blockStatus: {
            type: String as PropType<UserCabinetStatus>,
            required: true
        }
    },
    computed: {
        status(): string {
            switch (this.blockStatus) {
                case "active":
                    return "Активен"
                case "block":
                    return 'Заблокирован'
                case "delete":
                    return "Удален"
            }
        },
        className(): string {
            switch (this.blockStatus) {
                case "active":
                    return "widget__button--info-green"
                case "block":
                    return 'widget__button--info-orange'
                case "delete":
                    return "widget__button--info-red"
            }
        }
    }
})
