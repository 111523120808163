<template>
    <div style="padding-bottom: 60px">
        <Preloader v-if="isLoading"/>
        <slot>
            <div>
                <div class="message__background" v-if="selectedImage" @click="closeImageFullSize">
                    <svg @click="closeImageFullSize" width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L1 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M1 1L13 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>

                    <img @click.stop class="message__background--image" :src="selectedImage" alt="">
                </div>
            </div>

            <h2 class="h2-primary header-date" v-if="timeElement">
                {{
                    timeElement.value
                        ? getTime(timeElement.value)
                        : 'Дата не установлена'
                }}
            </h2>
            <div class="task-full-wrapper" v-for="(element, id) in parentElement">
                <div class="primary-div" v-if="element.fields.length > 0" :key="id">
                    <div class="task-name" @click="element.isOpen = !element.isOpen">
                        <h2 class="h2-primary">{{ element.name }}</h2>
                        <button class="button-primary">
                            <img v-if="element.isOpen" src="@/assets/img/Arrow/hide-arrow.svg"/>
                            <img v-else src="@/assets/img/Arrow/show-arrow.svg"/>
                        </button>
                    </div>

                    <div v-if="element.isOpen" class="primary-div__desc">
                        <slot v-for="(field, id) in element.fields">
                            <slot
                                v-if="field.value || field.type === checkmark || field.type === file || field.isEditable">
                                <FieldItem
                                    @openImageFullSize="openImageFullSize"
                                    :key="id"
                                    :isTaskClosed="task.closed"
                                    :id="field.id"
                                    :isEditable="field.isEditable"
                                    :editPermission="editPermission"
                                    :taskId="task.id"
                                    :title="field.name"
                                    :catalogs_id="field.catalogs_id"
                                    :inputType="field.inputType"
                                    :constName="field.constName"
                                    :type="field.type"
                                    :element="field.value"
                                    :isVisible="field.isVisible"
                                    :rerender="rerender"
                                />
                            </slot>
                        </slot>
                    </div>
                </div>
            </div>

            <div class="task-full-wrapper" v-if="!isTaskClosed && taskStatus">
                <div class="primary-div status-div gap">

                    <div>
                        <div class="button-div">
                            <div>
                                <p class="title-task-description" style="margin-bottom: 2px;">Статус задачи</p>
                                <h3 class="h3-primary">Принять задачу</h3>
                            </div>

                            <div
                                class="switch-btn"
                                :class="{ 'switch-on': taskStatus.accepted, 'switch-btn-disabled': checkboxDisabled }"
                                @click="switchStatus"
                            ></div>
                        </div>
                        <p v-if="errorStatus" class="title-task-description" style="color: rgba(255,0,0,0.52)">Ошибка
                            при отправке статуса</p>
                    </div>

                    <div class="button-div" v-if="isTaskAccepted">
                        <div class="user_profile">

                            <slot v-if="!acceptedUserProfile.avatar_url">
                                <img class="user_profile-avatar mention_avatar" src="../assets/img/Avatar/anon.png" alt="">
                            </slot>

                            <slot v-else>
                                <img class="user_profile-avatar"
                                     :src="url + acceptedUserProfile.avatar_url + '?is_preview=1&avatar_size=64'" alt="">
                            </slot>

                            <div class="user_profile-name h3-primary">
                                {{ acceptedUserProfile.info.name + ' ' + acceptedUserProfile.info.surname }}
                            </div>
                        </div>
                    </div>

                    <div class="button-div" v-if="acceptByOtherFitter && isTaskAccepted">
                        <p class="h3-primary">
                            Задача была принята другим пользователем, но вы можете принять её на себя
                        </p>
                    </div>

                    <div class="button-div" v-if="acceptByOtherFitter && isTaskAccepted">
                        <button @click="acceptTask" class="form-button">Принять на себя</button>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import FieldItem from '@/components/field/FieldItem'
import Preloader from '@/shared/ui/preloader'
import {COMMENT_TYPE, CONSTANT_Arr_Months} from '@/constants/constants'

export default {
    name: 'TaskForm',
    components: {
        FieldItem,
        Preloader
    },
    props: {
        task: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Array
        },
        parentElement: {
            type: Array
        },
        timeElement: {
            type: Object
        },
        isLoading: {
            type: Boolean
        },
        isPzForm: {
            type: Boolean
        },
        login: {
            type: Object,
        },
        isTaskClosed: {
            type: Boolean
        },
        rerender: {
            type: Boolean
        },
        editPermission: {
            type: Boolean
        },
        taskStatusAccept: {
            type: Object
        },
        taskStatusAcceptError: {
            type: Boolean
        }
    },
    data() {
        return {
            errorStatus: false,
            fields: [],
            isStatusActive: false,
            checkmark: COMMENT_TYPE.checkmark,
            file: COMMENT_TYPE.file,
            selectedImage: '',
            isRender: false,
            url: `${process.env.VUE_APP_TASK_RADAR}`,
            prevAcceptedUser: null,
        }
    },
    created() {
        this.scrollToTop()
    },
    mounted() {
        this.$store.dispatch('changeActiveTab', 'task')
        this.$emit('changeHeader', 'Задача')
    },
    computed: {
        taskStatus() {
            return this.taskStatusAccept
        },
        myProfile() {
            return this.$store.getters.getUserData
        },
        acceptedUserProfile() {
            const allUsers = this.$store.getters.getAllUsers
            return allUsers.find(user => user.id.toString() === this.taskStatus.accepted_user.toString())
        },
        'acceptByOtherFitter'() {
            return this.taskStatus.accepted_user !== this.myProfile.id
        },
        checkboxDisabled() {
            return this.acceptByOtherFitter && this.taskStatus.accepted
        },
        isTaskAccepted() {
            return this.taskStatus.accepted
        }
    },
    watch: {
        taskStatusAcceptError(newValue) {
            if (newValue && this.prevAcceptedUser && this.prevAcceptedUser.accepted_user) {
                this.taskStatus.accepted = this.prevAcceptedUser.accepted
                this.taskStatus.accepted_user = this.prevAcceptedUser.accepted_user
                this.errorStatus = true

                setTimeout(() => {
                    this.errorStatus = false
                }, 4000)
            }
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        openImageFullSize(url) {
            this.selectedImage = url
            document.documentElement.style.overflow = 'hidden'
        },
        closeImageFullSize() {
            this.selectedImage = ""
            document.documentElement.style.overflow = 'scroll'
        },
        getTime(time) {
            let d = new Date(time * 1000),
                month = d.getMonth(),
                day = d.getDate(),
                hour = d.getHours(),
                minutes = ('0' + d.getMinutes()).slice(-2)

            return `${hour}:${minutes} ${day} ${CONSTANT_Arr_Months[month]}`
        },
        updateTaskStatus(accepted) {
            if (this.taskStatus && this.taskStatus.hasOwnProperty('accepted')) {
                const data = {
                    accepted: accepted,
                    accepted_user: Number(this.$store.getters.getCurrentUserId),
                }

                this.prevAcceptedUser = {
                    accepted: this.taskStatus.accepted,
                    accepted_user: this.taskStatus.accepted_user
                }

                this.$emit('updateTaskStatusHandler', data)

                this.taskStatus.accepted = accepted
                this.taskStatus.accepted_user = this.myProfile.id
            }
        },
        async switchStatus() {
            if (this.checkboxDisabled) return

            const newAcceptedStatus = !this.taskStatus.accepted;
            this.updateTaskStatus(newAcceptedStatus);
        },

        acceptTask() {
            this.updateTaskStatus(this.taskStatus.accepted);
        }
    }
}
</script>

<style scoped>
.header-date {
    padding: 28px 0 16px 20px;

    font-size: 20px;
}

.gap {
    display: flex;
    flex-direction: column;

    gap: 12px;
}

.switch-btn {
    display: inline-block;
    width: 40px;
    height: 24px;
    border-radius: 19px;
    background: #e2e2e2;
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    transition-duration: 300ms;
}

.switch-btn::after {
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 17px;
    background: #fff;
    top: 4px;
    left: 5px;
    transition-duration: 300ms;
    position: absolute;
    z-index: 1;
}

.switch-on {
    background: #00bb34;
}

.switch-btn-disabled {
    opacity: 40%;
}

.switch-on::after {
    left: 21px;
}

.status-div {
    display: flex;
}

.status-text {
    color: #999999;
    font-size: 15px;
    line-height: 18px;
}

.primary-div__desc-pz:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--divider-color);
}

.button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333;
}

.mb-15 {
    margin-bottom: 15px;
}

.not-found-wrapper {
    padding-top: 30px;
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}

.not-found-wrapper p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #333;
    text-align: center;
}

.not-found-wrapper button {
    background: #009bf1;
    border-radius: 4px;
    width: 280px;
    padding: 12px 50px;
    border: none;
    color: white;
}

.not-found__router {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.message__background {
    width: 100%;
    height: 100%;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px;

    background-color: rgba(0, 0, 0, 0.75);

    z-index: 1000;
}

.message__background img {
    width: 100%;
    height: auto;
}

.message__background svg {
    position: absolute;
    top: 60px;
    right: 20px;

    width: 20px;
    height: 20px;
}


.user_profile {
    width: 100%;

    display: flex;
    align-items: center;

    gap: 12px;
    padding: 8px 12px;

    border-radius: 6px;
    background-color: var(--gray-substrate);
}

.user_profile-avatar {
    width: 32px;
    height: 32px;

    border-radius: 50%;
}

.user_profile-name {

}
</style>
