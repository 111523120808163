<template>
    <div @click.prevent="openPDF" ref="pdfViewer"></div>
</template>

<script>
import { getDocument } from 'pdfjs-dist';

export default {
    name: 'PdfPreview',
    props: {
        pdfUrl: String,
    },
    mounted() {
        this.renderPdf();
    },
    methods: {
        async renderPdf() {
            const pdfUrl = process.env.VUE_APP_TASK_RADAR + this.pdfUrl;
            const pdfContainer = this.$refs.pdfViewer;

            // Загрузка PDF
            const loadingTask = getDocument(pdfUrl);
            const pdfDocument = await loadingTask.promise;

            // Получение первой страницы PDF
            const page = await pdfDocument.getPage(1);

            // Определение масштаба для отображения PDF
            const scale = 1.0;
            const viewport = page.getViewport({ scale });

            // Создание холста для отображения PDF
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            pdfContainer.appendChild(canvas);

            // Отрисовка PDF на холсте
            await page.render({ canvasContext: context, viewport });
        },
        openPDF() {
            const pdfUrl = process.env.VUE_APP_TASK_RADAR + this.pdfUrl;
            window.open(pdfUrl, '_blank');
        }
    }
}
</script>
