import {unit} from "@/components/tracker/model/interface";
import {qrBarManager} from "@/components/tracker/model/fitter/qrBarManager";

export class changeDOMElement {
    static openOrCloseObjectInfo(api: any, tracker_imei: string) {
        api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
            if (item.tracker.tracker_imei === tracker_imei) {
                const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                if (parent.state) {
                    parent.state.isObjectWidgetShow = !parent.state.isObjectWidgetShow
                }

                return api.unitsData.inGsAndInTable[i]
            } else {
                return api.unitsData.inGsAndInTable[i]
            }
        })
    }


    static changeTabInObjectInfo(api: any, tracker_imei: string, check: string) {
        api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
            if (item.tracker.tracker_imei === tracker_imei) {
                const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                if (parent.state) {
                    parent.state.activeObjectWidget = check
                }

                return api.unitsData.inGsAndInTable[i]
            } else {
                return api.unitsData.inGsAndInTable[i]
            }
        })
    }

    static showMap(api: any, tracker_imei: string) {
        api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
            if (item.tracker.tracker_imei === tracker_imei) {
                const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                if (parent.state) {
                    parent.state.isMap = !parent.state.isMap
                }

                return api.unitsData.inGsAndInTable[i]
            } else {
                return api.unitsData.inGsAndInTable[i]
            }
        })
    }


    static closeInputActive(api: any, status: string, tracker_imei: string) {

        if (status === 'name') {

            api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {
                        parent.state.inputStatus.isNameUnitActive = false;
                    }

                    return api.unitsData.inGsAndInTable[i]
                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })
        }

        if (status === 'mileage') {

            api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {
                        parent.state.inputStatus.isMileageUnitActive = false;
                    }

                    return api.unitsData.inGsAndInTable[i]
                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })
        }
    }


    static selectChangePopover(api: any, unit: unit, status: boolean) {
        if (unit && unit.state) {
            unit.state.popoverSelectChangeType = status

            api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
        }
    }


    static selectChangeTrackerPopover(api: any, unit: unit, status: boolean) {
        if (unit && unit.state) {
            unit.state.popoverChangeTracker = status

            api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
        }
    }


    static setEditSensorPopoverActive(api: any, unit: unit, status: boolean) {
        if (unit && unit.state) {
            unit.state.popoverEditSensor = status
            unit.state.popoverChangeTracker = false

            qrBarManager.closeQrConnection(unit)
            qrBarManager.closeBatConnection(unit)

            unit.state.changeObject.changeObjectError = false

            api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
        }
    }


    static setChangeTrackerPopoverActive(api: any, unit: unit, status: boolean) {
        if (unit && unit.state) {
            unit.state.popoverEditSensor = false
            unit.state.popoverChangeTracker = status

            qrBarManager.closeQrConnection(unit)
            qrBarManager.closeBatConnection(unit)

            unit.state.changeObject.changeObjectError = false

            api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
        }
    }

    static closeEditSensorAndChangeTrackerPopover(api: any, unit: unit) {
        if (unit && unit.state) {
            unit.state.popoverChangeTracker = false
            unit.state.popoverEditSensor = false

            unit.state.popoverSelectChangeType = false

            qrBarManager.closeQrConnection(unit)
            qrBarManager.closeBatConnection(unit)

            unit.state.changeObject.changeObjectError = false

            api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
        }
    }

}


export class InputInteraction {

    // При нажатии на input делает его активным
    static setInputActive(api: any, status: string, tracker_imei: string) {

        if (status === 'name') {

            api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {
                        parent.state.inputStatus.isNameUnitActive = true;
                        parent.state.inputStatus.nameUnitActiveName = parent.unit.name;
                    }

                    return api.unitsData.inGsAndInTable[i]
                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })
        }

        if (status === 'mileage') {

            api.unitsData.inGsAndInTable = (api.unitsData.inGsAndInTable as unknown as unit[]).map((item, i) => {
                if (item.tracker.tracker_imei === tracker_imei) {
                    const parent = (api.unitsData.inGsAndInTable[i] as unknown as unit)

                    if (parent.state) {
                        parent.state.inputStatus.mileageUniActiveName = parent.unit.mileage
                        parent.state.inputStatus.isMileageUnitActive = true;
                    }

                    return api.unitsData.inGsAndInTable[i]
                } else {
                    return api.unitsData.inGsAndInTable[i]
                }
            })

        }
    }
}