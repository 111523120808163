export function replaceMessage(message: string, userMessage: boolean) {
    const className = userMessage ? 'message-link my-link' : 'message-link'

    // Заменить все URL внутри текста на ссылки
    message = message.replace(
        /(https?:\/\/[^\s<]+)/gi,
        `<a class='${className}' href="$1">$1</a>`
    );

    // Заменить &nbsp; на пробел
    message = message.replace(/&nbsp;/g, " ");

    return message;
}