
import {defineComponent, PropType} from "vue";
import {LayerData} from "@/components/tracker/model/interface";

export default defineComponent({
    name: "V-Map",
    props: {
        layersData: Array as PropType<LayerData[]>,
        selectedLayers: String
    },
    methods: {
        getMap(): HTMLDivElement | null {
            return this.$refs.map as HTMLDivElement | null;
        }
    }
})
