<template>
   <div>
       <div v-for="(value, id) in copyArr" :key="id" class="message-container-text" style="white-space: pre-line;">
           <slot v-if="fields && fields.length > 0 && fields[id] && fields[id].type === 'file'">
               {{ user }}
               <slot v-if="(value.value && value.old_value) || (!value.value && !value.old_value)">изменил(а)
               </slot>
               <slot v-else-if="value.value && !value.old_value">добавил(а)</slot>
               <slot v-else-if="!value.value && value.old_value">очистил(а)</slot>
               файл в поле {{ fields[id].name }} на
               <slot v-if="value && value.value && value.value.length > 0">{{value.value.join(', ')}}</slot>

           </slot>
           <slot v-else>
               {{ user }}
               <slot v-if="(value.value && value.old_value) || (!value.value && !value.old_value)">изменил(а)
               </slot>
               <slot v-else-if="value.value && !value.old_value">добавил(а)</slot>
               <slot v-else-if="!value.value && value.old_value">очистил(а)</slot>
               поле {{ fields[id]?.name }}
               <slot v-if="value.value">на {{ value.value }}</slot>
               <slot v-if="value.old_value"> было {{ value.old_value }}</slot>
           </slot>
       </div>
   </div>
</template>

<script>
import {findFieldConvertValue, getFormFieldById} from "@/utils/field";

export default {
    name: "MessageSystemChangeValues",
    props: {
        msg: Object,
        newValue: Array,
        user: String,
    },
    data() {
        return {
            copyArr: [],
            field: "",
            fields: [],
            catalogValue: "",
            catalogOldValue: "",
            dateArr: ['date', 'due_date', 'due_date_time']
        }
    },
    mounted() {
        this.getField()
    },
    methods: {
        async getField() {
            this.copyArr = JSON.parse(JSON.stringify(this.newValue))

            for (let i = 0; i < this.newValue.length; i++) {
                const res = getFormFieldById(this.newValue[i].field_id)
                this.copyArr[i].name = res.name
                this.copyArr[i].value = await findFieldConvertValue(this.newValue[i]);
                this.copyArr[i].old_value = await findFieldConvertValue(this.newValue[i], true);

                if (res) {
                    this.fields.push(res)
                }
            }
        }
    },
}
</script>

<style scoped>
.message-container-text {
    word-break: break-word;
}
</style>