import globalStore from "@/store";
import {Bot, Department, User} from "@/shared/api";

export function getUserById(id: number) {
    const users = globalStore.getters.getAllUsers;
    return users.find((user: User) => user.id === id) || null
}

export function getBotById(id: number) {
    const bots = globalStore.getters.getBots;
    return bots.find((bot: Bot) => bot.id === id) || null
}

export function getDepartmentById(id: number) {
    const departments = globalStore.getters.getDepartments;
    return departments.find((department: Department) => department.id === id) || null
}

export function getUserOrBotById(id: number) {
    const users = globalStore.getters.getAllUsers;
    const bots = globalStore.getters.getBots;

    let findUser = users.find((user: User) => user.id === id)
    if (!findUser) {
        findUser = bots.find((bot: Bot) => bot.id === id)
    }

    return findUser
}