import {ISensor} from "@/components/tracker/model/interface";
import {addSensor} from "@/components/tracker/model/helpers";

export function getSensorResult(api: any) {

    const sensor = [] as (null | ISensor)[]

    sensor.push(addSensor(api, 'ibutton', 'ibutton', {}))
    sensor.push(addSensor(api, 'can', 'can', {}))
    sensor.push(addSensor(api, 'gerkon', 'gerkon', {}))
    sensor.push(addSensor(api, 'block', 'block', {}))
    sensor.push(addSensor(api, 'temperature', 'temperature', {}))
    sensor.push(addSensor(api, 'temperaturebluetooth', 'temperaturebluetooth', {}))
    sensor.push(addSensor(api, 'fuel', 'fuel', {}))
    sensor.push(addSensor(api, 'fuel_multiply', 'fuel_multiply', {count: 2}))
    sensor.push(addSensor(api, 'fuel_ble', 'fuel_ble', {}))
    sensor.push(addSensor(api, 'fuel_standart', 'fuel_standart', {}))
    sensor.push(addSensor(api, 'fuel_standart2', 'fuel_standart2', {}))
    sensor.push(addSensor(api, 'prikur', 'prikur', {}))

    sensor.push({
        name: 'ignition',
        param: {},
    });

    return sensor.filter(Boolean) as ISensor[]
}


export function getSensorByHand(api: any) {
    const sensor = [] as ISensor[]

    if (api.createByHandObject.ibutton) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.ibutton[0])
        sensor.push({
            name: 'ibutton',
            param: {}
        })
    }

    if (api.createByHandObject.can) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.can[0])
        sensor.push({
            name: 'can',
            param: {}
        })
    }

    if (api.createByHandObject.block) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.block[0])
        sensor.push({
            name: 'block',
            param: {}
        })
    }

    if (api.createByHandObject.gerkon) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.gerkon[0])
        sensor.push({
            name: 'gerkon',
            param: {}
        })
    }

    if (api.createByHandObject.prikur) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.prikur[0])
        sensor.push({
            name: 'prikur',
            param: {}
        })
    }

    if (api.createByHandObject.temperature) {
        if (api.createByHandObject.temperature === 'temperature') {
            api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.temperature[0])
            sensor.push({
                name: 'temperature',
                param: {}
            })
        }
    }

    if (api.createByHandObject.temperaturebluetooth) {
        if (api.createByHandObject.temperaturebluetooth === 'temperaturebluetooth') {
            api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.temperaturebluetooth[0])
            sensor.push({
                name: 'temperaturebluetooth',
                param: {}
            })
        }
    }


    if (api.createByHandObject.fuel) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.fuel[0])
        sensor.push({
            name: 'fuel',
            param: {}
        })
    }

    if (api.createByHandObject.fuel_multiply) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.fuel_multiply[0])
        sensor.push({
            name: 'fuel_multiply',
            param: {
                count: 2
            }
        })
    }

    if (api.createByHandObject.fuel_ble) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.fuel_ble[0])
        sensor.push({
            name: 'fuel_ble',
            param: {}
        })
    }

    if (api.createByHandObject.fuel_standart) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.fuel_standart[0])
        sensor.push({
            name: 'fuel_standart',
            param: {}
        })
    }

    if (api.createByHandObject.fuel_standart2) {
        api.createByHandObject.objectInfo.sensorId.push(api.ObjectsNameType.fuel_standart2[0])
        sensor.push({
            name: 'fuel_standart2',
            param: {}
        })
    }

    // Ignition sensor is always added
    sensor.push({
        name: 'ignition',
        param: {},
    });

    return sensor
}