<template>
    <div>
        <div class="text_substrate" :class="userMessage ? 'text_substrate_my' : 'text_substrate_other'">
            <div>
                <!-- Если файл существует, отобразите его содержимое -->
                <pre @click="toggleText(isTruncated)" v-if="fileContent" style="white-space: pre-line">{{ fileContent }}</pre>
                <!-- Если файла нет, отобразите сообщение об отсутствии файла или загрузке -->
                <p v-else-if="loading">Загрузка содержимого...</p>
                <p v-else>No file content available</p>
            </div>
            <button v-if="!loading && !isTextSmall" class="button" @click="toggleText(isTruncated)">
                {{ isTruncated ? 'Показать полностью' : 'Свернуть' }}
            </button>
        </div>
    </div>
</template>

<script>
import {truncateText} from "@/utils/misc";

export default {
    name: "TextPreview",
    props: {
        fileUrl: {
            type: String,
            required: true
        },
        userMessage: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        return {
            truncateText
        }
    },
    data() {
        return {
            fileContent: null,
            loading: false,
            isTruncated: false,
            fullText: '',
            truncatedText: '',
            isTextSmall: false,
        };
    },
    mounted() {
        const url = process.env.VUE_APP_TASK_RADAR + this.fileUrl;

        this.loading = true

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to load file");
                }
                return response.text();
            })
            .then(text => {
                // Устанавливаем содержимое файла в состояние компонента
                const info = truncateText(text, 40)

                this.fullText = text // Полный текст файла
                this.truncatedText = info.text // Либо полный либо урезанный текст
                this.isTextSmall = info.smallText // Можно ли раскрыть этот текст

                this.fileContent = this.truncatedText // Как текст выглядит в зарытом состоянии

                this.isTruncated = info.truncate // Устанавливает состояние закрыт он или открыт
            })
            .catch(error => {
                console.error("Error loading file:", error);
            })
            .finally(() => {
                // Завершаем загрузку
                this.loading = false;
            });
    },
    methods: {
        toggleText(isTruncated) {
            if (this.isTextSmall) return

            if (isTruncated) {
                this.isTruncated = false
                this.fileContent = this.fullText
            } else {
                this.isTruncated = true
                this.fileContent = this.truncatedText
            }

        }
    }
}
</script>

<style scoped>
pre {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    text-align: left;

    color: #333333;
}

.button {
    padding: 0;
    margin-top: 4px;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;

    color: var(--primary-color);
    border: none;
    background-color: inherit;
}
</style>