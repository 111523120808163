<template>
    <div ref="map" class="map"></div>
</template>

<script>
export default {
    name: "GoogleMap",
    props: {
        geo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            map: null,
            mapCenter: {lat: 0, lng: 0}
        };
    },
    mounted() {
        if (window.google === undefined) {
            let mapScript = document.createElement("script");
            mapScript.async = true;
            mapScript.type = "text/javascript";
            mapScript.defer = true;
            mapScript.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyDjrRSIjU9ui_jAYCAjcL08bshVBSREanU";
            let vm = this;
            console.log(vm.geo);
            mapScript.onload = function () {
                vm.mapCenter.lng = vm.geo.geo_x;
                vm.mapCenter.lat = vm.geo.geo_y;

                vm.map = new window.google.maps.Map(vm.$refs.map, {
                    center: vm.mapCenter,
                    scrollwheel: false,
                    zoom: 16,
                    zoomControl: false,
                    disableDefaultUI: true,
                    streetViewControl: false
                });
                vm.initMap();
            };
            document.head.appendChild(mapScript);
        } else {
            this.mapCenter.lng = this.geo.geo_x;
            this.mapCenter.lat = this.geo.geo_y;

            this.map = new window.google.maps.Map(this.$refs.map, {
                center: this.mapCenter,
                scrollwheel: false,
                zoom: 16,
                zoomControl: false,
                disableDefaultUI: true,
                streetViewControl: false
            });
            this.initMap();
        }

        const map = this.$refs.map
        if (map) {
            map.addEventListener('click', this.copyText)
        }
    },
    methods: {
        initMap() {
            let map = new window.google.maps.Map(this.$refs["map"], {
                center: this.mapCenter,
                zoom: 16,
                zoomControl: false,
                disableDefaultUI: true,
                streetViewControl: false
            });
            this.map = map;
            var geocoder = new window.google.maps.Geocoder();
            var infowindow = new window.google.maps.InfoWindow();
            const mapMarker = {
                url: require("@/assets/img/Order/Tracker/marker.png"),
                scaledSize: new window.google.maps.Size(30, 40)
            };
            let marker = new window.google.maps.Marker({
                position: this.mapCenter,
                map: this.map,
                icon: mapMarker
            });
            this.marker = marker;
            window.google.maps.event.addListener(marker, "click", function (evt) {
                var mark = this;
                geocoder.geocode(
                    {
                        location: evt.latLng
                    },
                    function (results, status) {
                        if (status == "OK") {
                            infowindow.setContent(
                                results[0].formatted_address +
                                "<br>" +
                                results[0].geometry.location.toUrlValue(6)
                            );
                            infowindow.open(map, mark);
                        }
                    }
                );
            });
        },
        copyText(e) {
            if (e.target.closest('.gm-style-iw-c')) {
                if (e.target.tagName === 'DIV' && e.target.classList.length === 0) {
                    this.copyTextHandler(e.target.outerHTML)
                } else {
                    const lastChild = e.target.querySelector('div:last-child')
                    if (lastChild) {
                        this.copyTextHandler(lastChild.outerHTML)
                    }
                }

            }
        },
        copyTextHandler(domElement) {
            const copyText = this.splitStringByBr(domElement)
            if (!copyText) return

            this.copyTextToClipboard(copyText)
        },
        splitStringByBr(string) {
            if (!string) return

            if (string.includes('<br>')) {
                const split = string.split('<br>')
                return split[split.length - 1].replace(/(<([^>]+)>)/ig, '')
            }

            return string.replace(/(<([^>]+)>)/ig, '')
        },
        copyTextToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log('Текст скопирован в буфер обмена');
                    this.$store.dispatch('changeCopyStatus', true)
                    setTimeout(() => {
                        this.$store.dispatch('changeCopyStatus', false)
                    }, 1300)
                })
                .catch(err => {
                    console.error('Не удалось скопировать текст в буфер обмена:', err);
                });
        }

    }
};
</script>
<style scoped>
.map {
    position: absolute;
    overflow: auto;
    /*width: 200px;*/
    /*height: 300px;*/
}
</style>
