import {unit, widgetStatusMenuObject} from "@/components/tracker/model/interface";
import {CatalogInteraction} from "@/components/tracker/model/methods/catalog/catalog";
import {findKeyByValue} from "@/components/tracker/model/helpers";
import {ObjectsNameType} from "@/components/tracker/model/const";

export function getSensorForChangeTracker(api: any, unit: unit) {
    const state = unit.state as widgetStatusMenuObject
    if (!state || !state.changeObject) return;

    const sensors = CatalogInteraction.getSensorThisUnit(api, unit)

    const sensor_key = []

    for (let i = 0; i < sensors.length; i++) {
        const currentSensor = sensors[i]

        const key = findKeyByValue(ObjectsNameType, currentSensor)
        sensor_key.push(key)
    }

    for (const [key, value] of Object.entries(api.createByHandObject)) {
        if (sensor_key.includes(key)) {
            api.createByHandObject[key] = key
        }
    }

    if (unit.state && unit.state.changeObject) {
        unit.state.changeObject.sensorBefore = JSON.parse(JSON.stringify(api.createByHandObject))
    }
}