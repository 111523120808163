
import {defineComponent, PropType} from "vue";
import {TaskComment} from "@/shared/api";
import {getFullTime} from "@/utils/time";
import MessageTime from "@/components/chat/ui/MessageTime.vue";
import {replaceMessage} from "@/components/chat/model/lib/messageMethod";

export default defineComponent({
    name: "MessageCommon",
    methods: {replaceMessage},
    components: {MessageTime},
    props: {
        getApprovalChoice: {
            type: Function
        },
        userMessage: {
            type: Boolean
        },
        message: {
            type: Object as PropType<TaskComment>,
            required: true
        },
    },
    setup() {
        return {
            getFullTime,
        }
    },
    data() {
        return {
            linkWords: ['http']
        }
    },
	mounted() {
		console.log('common')
	}
})
