import {unit} from "@/components/tracker/model/interface";
import {ObjectMethod} from "@/components/tracker/model/core/objects/utils";
import {APIFitter} from "@/components/tracker/model/api/fitter";

export class Command {

    static async executeCommand(api: any, command_name: string, unit_id: string, unit_name: string, index: number) {
        // Проверка перед выполнением команды
        const current_item = api.unitsData.inGsAndInTable[index] as unknown as unit

        const inTouch = ObjectMethod.checkIsObjectInTouch(current_item)
        if (inTouch) {
            current_item.command_status.find(item => {
                if (item.command_name === command_name) {
                    item.status = 'not in touch'
                }
            })

            return
        }

        current_item.command_status.find(item => {
            if (item.command_name === command_name) {
                item.loading = true
            }
        })


        const res = await APIFitter.executeCommandAPI(api, command_name, unit_id, unit_name)

        if (res.body.status === 'success') {

            const command_id = res.body.data.task_id;

            if (command_id) {
                const checkCommandFunc = await APIFitter.executeExactCommandAPI(api, command_id)

                if (checkCommandFunc && checkCommandFunc.body) {
                    if (checkCommandFunc.body.status === 'fail') {
                        await commandExecuteFailure(current_item, command_name)
                    }

                    if (checkCommandFunc.status === 200 && checkCommandFunc.body.data.message === "OK") {
                        await commandExecuteSuccess(current_item, command_name)
                    }

                    if (checkCommandFunc.status === 200 && checkCommandFunc.body.data.message !== "OK") {
                        const interval = setInterval(async () => {
                            const checkCommandFunc = await APIFitter.executeExactCommandAPI(api, command_id)

                            if (checkCommandFunc.body.status === 'fail') {
                                await commandExecuteFailure(current_item, command_name)

                                clearInterval(interval)
                            }

                            if (checkCommandFunc.status == 200 && checkCommandFunc.body.data.message === "OK") {
                                await commandExecuteSuccess(current_item, command_name)

                                clearInterval(interval)
                            }
                        }, 3000)
                    }

                }
            }

        } else {
            await commandExecuteFailure(current_item, command_name)
        }
    }
}

// Команда выполнена успешно
const commandExecuteSuccess = (current_item: unit, command_name: string) => {
    current_item.command_status.find(item => {
        if (item.command_name === command_name) {
            item.status = 'success'
            item.loading = false
            item.isComplete = true
            setTimeout(() => {
                item.isComplete = false
            }, 5000)
        }
    });
}


const commandExecuteFailure = (current_item: unit, command_name: string) => {
    current_item.command_status.find(item => {
        if (item.command_name === command_name) {
            item.status = 'fail'
            item.loading = false
        }
    })
}