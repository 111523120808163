async function checkAndCreateDatabase(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
        try {
            // Открываем или создаем базу данных 'messageDB'
            const db = await openDatabase('messagesDB');

            // Проверяем наличие таблицы 'files' и создаем, если не существует
            if (!(await checkTableExists('files', db))) {
                createObjectStore('files', db);
            }

            // Проверяем наличие таблицы 'messages' и создаем, если не существует
            if (!(await checkTableExists('messages', db))) {
                createObjectStore('messages', db);
            }

            // База данных успешно проверена и создана
            resolve();
        } catch (error) {
            // Ошибка при открытии базы данных или создании таблиц
            reject(error);
        }
    });
}

// Функция для открытия или создания базы данных
function openDatabase(databaseName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(databaseName, 1);

        request.onupgradeneeded = function(event: IDBVersionChangeEvent) {
            const target = event.target as IDBOpenDBRequest
            const db = target.result;
            // Создаем объектные хранилища, если их нет
            if (!db.objectStoreNames.contains('files')) {
                createObjectStore('files', db);
            }
            if (!db.objectStoreNames.contains('messages')) {
                createObjectStore('messages', db);
            }
        };

        request.onsuccess = function(event) {
            const target = event.target as IDBOpenDBRequest
            const db = target.result;

            resolve(db);
        };

        request.onerror = function(event) {
            const target = event.target as IDBOpenDBRequest
            const error = target.error;

            reject(error);
        };
    });
}

// Функция для создания объектного хранилища
function createObjectStore(tableName: string, db: IDBDatabase): void {
    db.createObjectStore(tableName, { autoIncrement: true, keyPath: 'id' });
}

// Функция для проверки существования таблицы
async function checkTableExists(tableName: string, db: IDBDatabase): Promise<boolean> {
    return db.objectStoreNames.contains(tableName);
}

export async function indexDBInit() {
    try {
        await checkAndCreateDatabase();
        console.log('База данных успешно проверена и создана');
    } catch (error) {
        console.error('Ошибка при проверке и создании базы данных:', error);
    }
}
