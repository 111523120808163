
import ErrorMessage from '../components/messages/ErrorMessage.vue'
import {login_radar} from '@/axios_requests/login'
import {setStorageVersionCorrect} from "@/utils/storage/common-method";
import showPassword from "@/components/showPassword.vue";
import {defineComponent} from 'vue'
export default defineComponent({
    name: 'Login',
    components: {
        ErrorMessage,
        showPassword
    },
    data() {
        return {
            user: {
                username: '',
                password: ''
            },
            passwordType: 'password' as 'password' | 'text',
            isErrorMsg: false,
            errorMsg: 'Неверный логин или пароль'
        }
    },
    methods: {
        login(event: Event) {
            event.preventDefault()

            login_radar(this.user).then(async response => {
                if (response && typeof response === 'object' &&  response.data) {
                    await this.$store.dispatch('addCurrentUser', response.data)

                    this.$emit('login_radar')
                    await this.$store.dispatch('changeIsLogin', true)

                    setStorageVersionCorrect()

                    await this.$router.push('/')
                } else {
                    this.isErrorMsg = true
                }
            })
        },
        togglePasswordType(type: 'password' | 'text'){
            this.passwordType = type;
        }
    }
})
