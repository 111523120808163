<template>
    <div class="layerItemFlex">
        <input
            type="checkbox"
            :id="layer.id"
            :checked="isSelected"
            @change="toggleLayer"
        />
        <label class="layerLabel" :for="layer.id">{{ layer.name }}</label>
    </div>
</template>

<script>
export default {
    name: "Layer",
    props: {
        layer: {
            type: Object,
            required: true
        },
        selectedLayer: {
            type: String,
            required: true
        }
    },
    computed: {
        isSelected() {
            return this.selectedLayer === this.layer.id;
        }
    },
    methods: {
        toggleLayer() {
            this.$emit('layer-toggle', this.layer.id);
        }
    }
};
</script>

<style scoped>
.layerLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);

    margin-left: 2px;
}

.layerItemFlex {
    display: flex;
    align-items: center;
}

.layerItemFlex input {
    background-color: var(--primary-color);
}
</style>
