<template>
  <div class="flex">
      <div>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="14" :fill="userMessage ? '#F9F9F9' : 'white'"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.7999 6.53333H14.6999L19.5999 11.4333V19.1333C19.5999 19.5046 19.4524 19.8607 19.1899 20.1233C18.9273 20.3858 18.5712 20.5333 18.1999 20.5333H9.7999C9.4286 20.5333 9.0725 20.3858 8.80995 20.1233C8.5474 19.8607 8.3999 19.5046 8.3999 19.1333V7.93333C8.3999 7.56202 8.5474 7.20593 8.80995 6.94338C9.0725 6.68082 9.4286 6.53333 9.7999 6.53333ZM14.1957 8.78632C14.1298 8.81019 14.1298 8.91075 14.1297 9.11189L14.1284 12.4194C14.1284 12.509 14.1284 12.5538 14.1591 12.5816C14.1897 12.6095 14.2391 12.6095 14.338 12.6095H17.8127C18.0217 12.6095 18.1262 12.6095 18.1534 12.5519C18.1806 12.4943 18.1086 12.4257 17.9644 12.2885L14.491 8.98096C14.338 8.83529 14.2615 8.76245 14.1957 8.78632Z"
                    fill="#009BF1"/>
          </svg>

      </div>

      <p class="message-text message-text-file" :style="color ? `color: ${color}` : ''">
          {{ fileName }}
      </p>
  </div>
</template>

<script>
export default {
    name: "CommonFile",
    props: {
        userMessage: {
            type: Boolean,
            required: true
        },
        fileName: {
            type: String,
        },
        color: {
            type: String
        }
    }
}
</script>

<style scoped>
.flex {
    display: flex;
    align-items: center;
}
</style>