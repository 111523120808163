<template>
    <div class="barcode-wrapper">

        <select class="task-input" v-model="selectedCamera" @change="changeCamera($event)">
            <option value=''>Выберите камеру</option>
            <option v-for="(device, index) in videoDevices" :key="index" :value="device.deviceId">
                Камера: {{ index }} {{ device.label }}
            </option>
        </select>

        <div class="rounded-video">
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoadedHandler"/>
        </div>

        <button
            @click="closeConnection"
            class="qr-close-connection"
        >
            Закрыть
        </button>
    </div>
</template>
<script>
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
    components: {
        StreamBarcodeReader
    },
    data() {
        return {
            videoDevices: [],
            isLoaded: false,
            selectedCamera: ''
        };
    },
    props: {
        close: Boolean
    },
    watch: {
        close: function (newValue) {
            if (newValue) {
                this.selectedCamera = ''
                this.isLoaded = false;
                this.videoDevices = []
            }
        },
    },
    // mounted() {
    //   let html5QrcodeScanner = new Html5QrcodeScanner(
    //     "reader",
    //     { fps: 10, qrbox: {width: 100, height: 100} }, false);
    //   html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    // },
    // methods: {
    //   onScanSuccess(decodedText, decodedResult) {
    //     console.log(`Code matched = ${decodedText}`, decodedResult);
    //   },
    //   onScanFailure(error) {
    //     console.warn(`Code scan error = ${error}`);
    //   }
    // }
    async mounted() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(device => device.kind === 'videoinput');
    },
    methods: {
        onDecode(result) {
            if (result.length !== 15) {
                this.$emit("decode", result);
            }
        },
        onLoadedHandler() {
            this.isLoaded ? null : (localStorage.getItem('lastSelectedCamera') ? this.uploadPastCamera() : null);
        },

        uploadPastCamera() {
            this.isLoaded = true;

            for (let i = 0; i < this.videoDevices.length; i++) {
                if (this.videoDevices[i].label === localStorage.getItem('lastSelectedCamera')) {

                    this.selectedCamera = this.videoDevices[i].deviceId;

                    const constraints = {audio: false, video: {deviceId: this.selectedCamera}}

                    let camera = document.getElementsByTagName('video')
                    const stream = camera[0].srcObject;
                    const tracks = stream.getTracks();


                    tracks.forEach(function (track) {
                        track.stop();
                    })

                    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                        camera[0].srcObject = stream;
                    }).catch(function (error) {
                        console.log('Ошибка при выборе камеры', error)
                    })

                }
            }

        },
        changeCamera(e) {
            for (let i = 0; i < this.videoDevices.length; i++) {
                if (this.videoDevices[i].deviceId === e.target.value) {
                    localStorage.setItem('lastSelectedCamera', this.videoDevices[i].label);
                }
            }

            const constraints = {audio: false, video: {deviceId: e.target.value}}
            let camera = document.getElementsByTagName('video')
            const stream = camera[0].srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            })

            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                camera[0].srcObject = stream;
            }).catch(function (error) {
                console.log('Ошибка при выборе камеры', error)
            })
        },

        closeConnection() {
            this.$emit('closeBarCodeConnection')
        },
    }
};
</script>
<style scoped>
.barcode-wrapper {
    display: flex;
    flex-direction: column;
}

.task-input {
    width: calc(100vw - 32px);
    margin-bottom: 8px;

    background-color: white !important;
}

.rounded-video {
    border-radius: 10px;
    overflow: hidden;
}


.qr-close-connection {
    margin-left: auto;
    margin-top: 8px;
    padding: 8px 12px;

    background: var(--red-light);
    border-radius: 4px;
    color: var(--red);
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    border: none;
}

</style>
