import {Catalog, FieldRowType, FieldsTypeTask, TaskDataType, unit} from "@/components/tracker/model/interface";
import * as trackerMethods from "@/components/tracker/model/methods";
import {APIRulse} from "@/components/tracker/model/api/rulse";
import {findFieldInMrTable, findFieldTask} from "@/components/tracker/model/utils";
import {getTableColumnByImei} from "@/components/tracker/model/common";

export class CatalogInteraction {

    static getIdCatalogValue(api: any, catalogName: string) {
        return api.allCatalogs.filter((item: Catalog) => {
            if (item.name === catalogName) {
                return item.id
            }
        })
    }


    static getCatalogIdByName(api: any, catalogName: string) {
        const catalogIdResp = trackerMethods.CatalogInteraction.getIdCatalogValue(api, catalogName)

        let result = null

        if (catalogIdResp.length > 0) {
            result = catalogIdResp[0].id
        }

        return result
    }

    static async getLoginValueFromTask(api: any) {
        let res = findFieldTask(api.task, 'name', 'Логин в системе')

        if (res.length !== 0) {
            if (res[0].value !== null && res[0].value !== "") {
                return (res[0].value as unknown as string).trim()
            }
        }

        res = findFieldTask(api.task, 'name', 'Логин фактический');

        if (res.length !== 0) {
            if (res[0].value !== null && res[0].value !== "") {
                return (res[0].value as unknown as string).trim()
            }
        }

        res = findFieldTask(api.task, 'name', 'Логин', false)

        if (res.length !== 0) {
            if (res[0].value !== null && res[0].value !== "") {
                return (res[0].value as unknown as string).trim()
            }
        }

        if (res.length > 0 && res[0].value) {
            return (res[0].value as unknown as string).trim()
        } else {
            return ''
        }
    }

    static async getDataFromTable(api: any) {
        const res = CatalogInteraction.findTable(api)

        if (res && res.length) {
            return res[0].values as FieldRowType[]
        }
    }

    static findTable(api: any) {
        let res = findFieldTask(api.task, 'name', 'Результат работ');
        if (res.length === 0) {
            res = findFieldTask(api.task, 'name', 'Решение');
            if (res.length === 0) {
                res = findFieldTask(api.task, 'name', 'Реестр оборудования');
                if (res.length === 0) {
                    return;
                }
            }
        }

        return res as FieldsTypeTask[]
    }


    static getEquipment(api: any) {
        let res = findFieldTask(api.task, 'name', 'Дополнительное оборудование');
        if (res.length === 0) {
            res = findFieldTask(api.task, 'name', 'Доп оборудование');
            if (res.length === 0) {
                res = findFieldTask(api.task, 'name', 'Доп. оборудование');
                if (res.length === 0) {
                    return;
                }
            }
        }

        return res as FieldsTypeTask[]
    }

    // Ищет датчики, которые указаны в таблице
    static getSensorThisUnit(api: any, unit: unit): string[] {
        const table = CatalogInteraction.findTable(api)

        if (table && table.length) {

            if (table[0].values.length > 0) {
                const row_id = getTableColumnByImei(api, unit) as number | null

                if (row_id === undefined || row_id === null) return []

                const sensors = [] as string[]

                for (let i = 0; i < table[0].values.length; i++) {
                    const current = table[0].values[i]

                    if (current.row_id === row_id) {
                        for (let j = 0; j < current.cells.length; j++) {
                            const currentCell = current.cells[j]

                            if (currentCell.name === 'Оборудование' || currentCell.name === 'Доп. оборудование') {
                                if (currentCell.value) {

                                    if (!Array.isArray(currentCell.value)) {
                                        if (typeof currentCell.value === "string") {
                                            sensors.push(currentCell.value)
                                        }
                                    } else {
                                        sensors.push(...currentCell.value as string[])
                                    }
                                }
                            }
                        }
                    }
                }

                return sensors
            }
        }

        return []
    }

    static async getCarCount(api: any) {
        let res = findFieldTask(api.task, 'name', 'Кол-во авто')
        if (res.length === 0) {
            res = findFieldTask(api.task, 'name', 'Кол-во объектов')
            if (res.length === 0) {
                return
            }
        }

        return res
    }

    static getSensorInCPTable(api: any): FieldsTypeTask | null {
        const res = findFieldTask(api.task, "name", "Производим замену")
        if (res && res.length > 0) {
            return res[0]
        }

        return null
    }

    // Собирает из задачи данные о клиенте для создания кабинета
    static async getDataForGenerateLogin(api: any) {
        const clientClass = findFieldTask(api.task, 'name', 'Класс клиента');
        const contact = findFieldTask(api.task, 'name', 'Телефон');
        const region = findFieldTask(api.task, 'name', 'Город');
        const login_method = findFieldTask(api.task, 'name', 'Как отправить данные для входа?');
        const login_method_value = findFieldTask(api.task, 'name', 'Контакт');

        const clientClassCatalog = clientClass.length > 0 && clientClass[0].value ? clientClass[0].value.toString() : null
        const login_methodCatalog = login_method.length > 0 && login_method[0].value ? login_method[0].value.toString() : null
        const regionCatalog = region.length > 0 && region[0].value ? region[0].value.toString() : null
        const loginMethodValueResult = login_method_value.length > 0 && login_method_value[0].value ? login_method_value[0].value.toString() : null
        const contactResult = contact.length > 0 && contact[0].value ? contact[0].value.toString() : null

        return {
            clientClass: clientClassCatalog,
            contact: contactResult,
            region: regionCatalog,
            login_method: login_methodCatalog,
            login_method_value: loginMethodValueResult
        }
    }


    static async getHostValueFromTask(task: TaskDataType) {
        let res = findFieldTask(task, 'name', 'Система', false);
        if (res.length === 0 || res[0].value === null) {
            return null;
        }

        let resCatalog = res[0].value

        if (resCatalog) {
            return resCatalog as string
        }

        return null;
    }

    static async getCatalogValueOnMount(api: any) {
        // Получаю все каталоги
        await APIRulse.getAllCatalogApi(api)

        // Достаю id нужных мне
        let systemId = trackerMethods.CatalogInteraction.getCatalogIdByName(api, "Система")
        let regionId = trackerMethods.CatalogInteraction.getCatalogIdByName(api, "Город")
        let clientClassId = trackerMethods.CatalogInteraction.getCatalogIdByName(api, "Класс клиента")
        let loginMethodId = trackerMethods.CatalogInteraction.getCatalogIdByName(api, "Как отправить данные для входа?")

        // Делаю запрос и получаю элементы каталога по id
        // Записываю полученные данные в массив, который будет отображаться при генерации логина
        api.createCabinetWithoutLogin.systemCatalog.systemCatalogType = await CatalogInteraction.getValueCatalogById(api, Number(systemId))
        api.createCabinetWithoutLogin.clientClassCatalog.clientClassCatalogType = await CatalogInteraction.getValueCatalogById(api, Number(clientClassId))
        api.createCabinetWithoutLogin.loginMethodCatalog.loginMethodCatalogType = await CatalogInteraction.getValueCatalogById(api, Number(loginMethodId))

        const regionFullSize = await CatalogInteraction.getValueCatalogById(api, Number(regionId), 1)


        // Обрезаю регион по первому элементу
        api.createCabinetWithoutLogin.regionCatalog.regionCatalogType = regionFullSize.map((item: string) => {
            return item.split(',')[0]
        })
    }

    static getCarInfoFromTable(api: any) {
        const table = findFieldInMrTable(api, 'Ход работ', 'Результат работ')
        if (!table) return

        const cells = table.values.map((el: FieldRowType) => {
            return el.cells
        })

        if (!cells || cells.length === 0) return;

        let indexFieldImei = null
        let indexFieldBlock = null
        let indexFieldModel = null

        for (let i = 0; i < cells[0].length; i++) {
            if (cells[0][i].name === "IMEI") {
                indexFieldImei = i
            }

            if (cells[0][i].name === "Номер блока" || cells[0][i].name === 'S/N') {
                indexFieldBlock = i
            }

            if (cells[0][i].name === "Марка, модель авто") {
                indexFieldModel = i
            }
        }

        if (indexFieldImei === null && indexFieldBlock === null || indexFieldModel === null) return;


        // Получил id поля IMEI и Серийный номер, здесь перебираю все объекты которые есть в виджете
        // И если их IMEI или серийник совпадает с тем, что есть в таблице, то записываю в unit данные об автомобиле
        for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
            const currentUnit = api.unitsData.inGsAndInTable[i] as unknown as unit
            for (let j = 0; j < cells.length; j++) {
                const currentCell = cells[j]

                if (!indexFieldImei) indexFieldImei = 1000
                if (!indexFieldBlock) indexFieldBlock = 1000

                if (
                    currentUnit.tracker.tracker_imei?.toString() === currentCell[indexFieldImei]?.value?.toString() ||
                    currentUnit.tracker.tracker_serial?.toString() === currentCell[indexFieldBlock]?.value?.toString() ||
                    currentUnit.tracker.tracker_imei?.toString() === currentCell[indexFieldBlock]?.value?.toString() ||
                    currentUnit.tracker.tracker_serial?.toString() === currentCell[indexFieldImei]?.value?.toString()
                ) {
                    if (currentCell[indexFieldModel] && currentCell[indexFieldModel].value) {
                        currentUnit.unit.model = currentCell[indexFieldModel].value.toString().trim()
                    }
                }
            }
        }

        api.callFuncToGetManyStatus(api.unitsData.unit_id)
    }

    static async getValueCatalogById(api: any, catalogId: number, arrIndex = 0) {
        let catalog = null

        if (catalogId) {
            catalog = await APIRulse.getCatalogValues(api, catalogId)

            if (!catalog) return

            if (catalog && catalog.status === 200) {
                return catalog.body.items.map((item: { id: number, value: string[] }) => {
                    return item.value[arrIndex]
                })
            }
        }

        return null
    }

    static getAllFields = (
        fields: FieldsTypeTask[]
    ): Array<FieldsTypeTask> => {
        const res: Array<FieldsTypeTask> = [];

        fields.forEach((field) => {
            if (field.fields) {
                res.push(...this.getAllFields(field.fields));
            } else {
                res.push(field);
            }
        });
        return res;
    };

}