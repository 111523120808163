<template>
   <div>
       <slot v-if="msg.info.approves.length > 0">
           <div class="message-container-text message-container-title">Этапы</div>
           <div class="message-container-fields message-container-main">
               <slot v-if="msg.info.approves.length > 0">
                   <div
                       v-for="(step, id) in msg.info.approves"
                       :key="id"
                       class="message-container-text message-container-gray"
                   >
                       <BaseSteps :step="step" :allSteps="allSteps" entityName="Согласующий"/>
                   </div>
               </slot>
           </div>
       </slot>
   </div>
</template>

<script>
import BaseSteps from "@/components/chat/message_system/ui/BaseSteps";

export default {
    name: "MessageSystemRevoke",
    components: {BaseSteps},
    props: {
        msg: Object,
        title: String,
        iconPath: String,
        type: String,
        allSteps: Array,
    },
}
</script>

<style scoped>

</style>