import {unit} from "@/components/tracker/model/interface";
import {APIFitter} from "@/components/tracker/model/api/fitter";

export async function callFuncToGetManyStatus(api: any, unit_id: number[]) {
    console.log(api.requestUpdateStatusBySocket ? 'получение запрещено' : 'статус разрешен')
    if (api.requestUpdateStatusBySocket) return

    const res = await APIFitter.getStatusMany(api, unit_id, api.userData)

    if (res) {
        let unitInfoMany = []

        if (res.status === 200) {
            unitInfoMany.push(res.body.data)
            // Берет unit_id из many и ищет такой же в inGsAndInTable и записывает туда в поле many
            const keyInfoMany = Object.keys(unitInfoMany[0])
            if (keyInfoMany.length > 0) {
                for (let i = 0; i < keyInfoMany.length; i++) {
                    for (let j = 0; j < api.unitsData.inGsAndInTable.length; j++) {
                        const currentInGsAndTable = api.unitsData.inGsAndInTable[j] as unknown as unit;

                        // Если виджет открыт в фиттере, то удаляем все команды кроме блокировки двигателя
                        if (api.isFitter) {
                            const enableCommand = ["! Разблокировать двигатель", "Заблокировать двигатель", "Заблокировать", "! Разблокировать"]
                            const filteredCommand = []

                            for (let k = 0; k < currentInGsAndTable.commands.length; k++) {
                                const currentCommand = currentInGsAndTable.commands[k]

                                if (enableCommand.includes(currentCommand)) {
                                    filteredCommand.push(currentCommand)
                                }
                            }

                            currentInGsAndTable.commands = filteredCommand
                        }

                        if (currentInGsAndTable.unit.unit_id.toString() === keyInfoMany[i]) {

                            currentInGsAndTable.many = unitInfoMany[0][keyInfoMany[i].toString()]

                            currentInGsAndTable.command_status = []
                            for (let k = 0; k < currentInGsAndTable.commands.length; k++) {
                                currentInGsAndTable.command_status.push({
                                    command_name: currentInGsAndTable.commands[k],
                                    status: null,
                                    loading: false,
                                    isComplete: false,
                                })
                            }

                            if (currentInGsAndTable.state) {
                                currentInGsAndTable.state.simQueryStatus = ''
                                currentInGsAndTable.state.simIsActiveClick = false
                                currentInGsAndTable.state.simIsLoading = false
                            }
                        }
                    }
                    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
                }
            }
            !api.isFitter && (api.loading.statusLoading = true)
        }
    }

    api.requestUpdateStatusBySocket = true
    setTimeout(() => {
        console.log('status access for request')
        api.requestUpdateStatusBySocket = false
    }, 1000)

    return null
}