
import {defineComponent, PropType} from 'vue'
import {getFileName} from "@/utils/message";
import { ImagesPreview } from "@/shared/api";

export default defineComponent({
    name: "FilePreviewLink",
    props: {
        attachment: {
            type: Object as PropType<ImagesPreview>,
            required: true
        }
    },
    emits: ['deleteAttachment'],
	data() {
		return {
			previewUrl: '',
			mimeType: '',
		}
	},
	beforeDestroy() {
		if (this.previewUrl) {
			URL.revokeObjectURL(this.previewUrl);
		}
	},
	watch: {
		attachment: {
			immediate: true,
			handler(newUrl) {
				this.fetchBlob(newUrl.url);
			}
		}
	},
    methods: {
        deleteAttachment(filePath: string) {
            this.$emit('deleteAttachment', filePath)
        },
		async fetchBlob(url: string) {
			try {
				const response = await fetch(url);
				const blob = await response.blob();
				this.mimeType = blob.type;
				this.previewUrl = URL.createObjectURL(blob);
			} catch (error) {
				console.error('Error fetching blob:', error);
			}
		},
    },

})
