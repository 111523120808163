import {TaskComment} from "@/shared/api";
import {StorageComment} from "@/shared/api/types/storage";
import {LocalStorageService} from "@/utils/storage";
import {STORAGE_KEY} from "@/utils/const";
import {deleteValueFromMapByTimestamp} from "@/utils/storage/common-method";


// Сохраняет сообщения в локалсторадж
export function saveCommentsInLocalStorage(taskId: number, comments: TaskComment[]) {
    const commentMap: Map<number, StorageComment> = new Map()

    commentMap.set(taskId, {
        comments,
        timestamp: new Date().getTime(),
    })

    const mapToObject = LocalStorageService.mapToLocalStorage(commentMap)

    LocalStorageService.getInstance().saveData(STORAGE_KEY.comments, mapToObject)
}

// Добавляет новое сообщение в локалсторадж
export function addCommentInLocalStorage(taskId: number, comment: TaskComment) {
    // Получаю сообщения и превращаю их в Map
    const commentFromLocalStorage = LocalStorageService.getInstance().getItem(STORAGE_KEY.comments)
    const commentsMap = LocalStorageService.localStorageToMap(commentFromLocalStorage)

    // Получаю нужные мне комменты по id задачи
    const commentsInTask = commentsMap.get(taskId.toString()) as StorageComment | undefined

    if (commentsInTask) {
        const commentsArray = commentsInTask.comments

        // Добавляю новое сообщение
        commentsArray.push(comment)

        commentsInTask.comments = commentsArray

        commentsMap.set(taskId.toString(), commentsInTask)

        // Сохраняю обновленный Map
        const mapToObject = LocalStorageService.mapToLocalStorage(commentsMap)
        LocalStorageService.getInstance().saveData(STORAGE_KEY.comments, mapToObject)
    }
}


// Получает сообщения из локалсторадж
export function getCommentsFromMap(id: number) {
    const comments = LocalStorageService.getInstance().getArray(STORAGE_KEY.comments)
    const commentMap = LocalStorageService.localStorageToMap(comments)

    const comment = commentMap.get(id.toString()) as StorageComment | undefined

    if (comment && comment.comments) {
        return comment.comments
    }

    return null
}


// Удаляет сообщения которым больше недели и возвращает Map
export function deleteCommentReturnMap(comments: TaskComment) {
    const commentMap = LocalStorageService.localStorageToMap(comments)

    return deleteValueFromMapByTimestamp(commentMap)
}


export function deleteCommentsOlderThanOneWeek(commentMap: TaskComment) {
    const comment = deleteCommentReturnMap(commentMap)
    const mapToObject = LocalStorageService.mapToLocalStorage(comment)

    LocalStorageService.getInstance().rewriteData(STORAGE_KEY.comments, mapToObject)
}