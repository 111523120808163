import {TaskStatus, TaskTableTracker, unit} from "@/components/tracker/model/interface";
import * as commonMethods from "@/components/tracker/model/common";
import {getTableColumnByImei} from "@/components/tracker/model/common";
import {ObjectMethod} from "@/components/tracker/model/core/objects/utils";
import {APIFitter} from "@/components/tracker/model/api/fitter";

export async function sendObjectWorkToReview(api: any, type: string, userType: string, trackerSerial: string, trackerImei: string) {
    for (let i = 0; i < api.unitsData.inGsAndInTable.length; i++) {
        const item = api.unitsData.inGsAndInTable[i] as unknown as unit;

        if (item.tracker.tracker_serial === trackerSerial || item.tracker.tracker_imei === trackerImei) {
            const isUserInTO = api.userData.isTO;

            const row_id = getTableColumnByImei(api, item) as number


            // if (type === 'add') {
            //     if (!item.many) {
            //         return api.select.notAllConditionsAreMet = true
            //     }
            //
            //     if (!item.many.params && !item.many.sim) {
            //         return api.select.notAllConditionsAreMet = true
            //     }
            //
            //     if (item.many.sim.status !== 'active') {
            //         return api.select.notAllConditionsAreMet = true
            //     }
            //
            //

            const inTouch = ObjectMethod.checkIsObjectInTouch(item)

            if (inTouch) {
                api.select.notAllConditionsAreMet = true
                return
            }

            if (row_id !== null && row_id !== undefined) {
                if (api.taskStatusProps && api.taskStatusProps.table_tracker) {
                    const objectStatus = (api.taskStatusProps.table_tracker as any)[row_id] as TaskTableTracker

                    if (objectStatus && item.state !== undefined && item.state.buttonStatus !== undefined) {
                        let acceptStatus = null

                        acceptStatus = isUserInTO ? Boolean(objectStatus.to_accept) : Boolean(objectStatus.fitter_accept)
                        acceptStatus = !acceptStatus

                        if (acceptStatus !== null) {

                            if (userType === 'fitter') {
                                item.state.buttonStatus.statusUnderReviewFitter = acceptStatus
                                item.state.buttonStatus.disabledFitter = acceptStatus
                                objectStatus.fitter_accept = acceptStatus
                            }

                            if (userType === 'to') {
                                item.state.buttonStatus.statusUnderReviewTO = acceptStatus
                                item.state.buttonStatus.disabledTO = acceptStatus
                                objectStatus.to_accept = acceptStatus
                            }

                            commonMethods.getUserWhoAcceptObject(objectStatus, item, api.allUsers)

                            const sendLikeTo = isUserInTO && userType === 'to'

                            const data = {
                                table_tracker: {
                                    [row_id]: {
                                        ...(sendLikeTo ? {to_accept: acceptStatus} : {fitter_accept: acceptStatus}),
                                        ...(sendLikeTo ? {to_user: api.userId} : {fitter_user: api.userId})

                                    }
                                }
                            }

                            await APIFitter.updateObjectStatus(api, api.task.id, data as TaskStatus)

                            !api.isFitter && (api.notUpdateTaskWhenChangeAcceptUser = true)
                        }
                    }
                }
            }
        }
    }

    api.unitsData.inGsAndInTable = JSON.parse(JSON.stringify(api.unitsData.inGsAndInTable))
}